import { getTheme } from "@fluentui/react";
import { IIconProps } from "common/models/Icons/icon-props";

const Stop = (props: IIconProps): JSX.Element => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 1C12.7761 1 13 1.22386 13 1.5V12.5C13 12.7761 12.7761 13 12.5 13H1.5C1.22386 13 1 12.7761 1 12.5V1.5C1 1.22386 1.22386 1 1.5 1H12.5ZM1.5 0C0.671573 0 0 0.671573 0 1.5V12.5C0 13.3284 0.671573 14 1.5 14H12.5C13.3284 14 14 13.3284 14 12.5V1.5C14 0.671573 13.3284 0 12.5 0H1.5Z" fill={props.color || getTheme().palette.black}/>
    </svg>
  )
};

export default Stop;