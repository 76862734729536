import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getClassNames } from './Cover.classNames';
import { useFormContext, useWatch } from 'react-hook-form';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from 'utils/constants/styles';
import { isFileTypeCorrect } from 'utils/helpers/helpers';
import { RootState, useAppDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { getCourseCover } from 'store/slices/courses';

export interface ILogo {
  name: string;
  type: string;
};

const Cover: React.FC = () => {
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const dispatch = useAppDispatch();
  const { lang } = useParams();
  
  const { setValue } = useFormContext();

  const coverState = useWatch({ name: 'courseOptions.cover' });

  const [isLoading, setIsLoading] = useState<boolean>(coverState.originalData === undefined);
  const [src, setSrc] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const loadingRef = useRef<HTMLDivElement>(null);
  const noFileRef = useRef<HTMLDivElement>(null);
  const selectedFileRef = useRef<HTMLDivElement>(null);
  const nodeRef = isLoading ? loadingRef : src ? selectedFileRef : noFileRef;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.files?.[0] && isFileTypeCorrect(e.dataTransfer.files[0])) {
      const chosenFile = e.dataTransfer.files?.[0];
      if (chosenFile) {
        const blob = new Blob([chosenFile]);
        const blobUrl = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          if (!chosenFile) return;
          setValue('courseOptions.cover', { ...coverState, data: chosenFile }, { shouldDirty: true });
        };
        img.src = blobUrl;
        setSrc(blobUrl);
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.[0] && isFileTypeCorrect(e.currentTarget.files[0])) {
      const chosenFile = e.currentTarget.files?.[0];
      if (chosenFile) {
        const blob = new Blob([chosenFile]);
        const blobUrl = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          if (!chosenFile) return;
          setValue('courseOptions.cover', { ...coverState, data: chosenFile }, { shouldDirty: true });
        };
        img.src = blobUrl;
        setSrc(blobUrl)
      }
    }
  };

  const clearAttachment = () => {
    if (inputRef.current && inputRef.current.files) {
      inputRef.current.files = null;
      inputRef.current.value = '';
      src && URL.revokeObjectURL(src);
      setSrc('');
    }
    setValue('courseOptions.cover', { data: null, originalData: coverState.originalData }, { shouldDirty: true });
  };

  const fetchCourseCover = useCallback(async () => {
    if (!documentLanguage) return;

    try {
      const cover = await dispatch(getCourseCover({ lang: lang || documentLanguage || ''})).unwrap();

      if (!cover) {
        console.log('no cover');
        return
      };

      const blob = new Blob([(cover as ArrayBuffer)]);
      const blobUrl = URL.createObjectURL(blob);
      const img = new Image();
        img.onload = () => {
          if (!cover) return;
          setValue('courseOptions.cover', { data: new File([cover as ArrayBuffer], 'cover'), originalData: new File([cover as ArrayBuffer], 'cover') });
        };
        img.src = blobUrl;
        setSrc(blobUrl);
    } catch (err) {
      setValue('courseOptions.cover', { data: null, originalData: null });
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [documentLanguage, lang, setValue, dispatch]);

  useEffect(() => {
    if (coverState.originalData === undefined) fetchCourseCover();
  }, [fetchCourseCover, coverState]);

  const classes = getClassNames();

  console.log('coverState', coverState);

  return (
    <div className={classes.logoSettings}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>Cover</span>
      </div>
      <div
        className={classes.logoContainer}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        {coverState.data && src &&
          <Icon
            className={classes.closeIcon}
            iconName="ChromeClose"
            onClick={(e) => {
              e.stopPropagation();
              clearAttachment();
            }}
          />}
        <input
         type='file'
         id='cover'
         style={{ display: 'none' }}
         onChange={handleFileChange}
         accept="image/*"
         ref={inputRef}
       />

        <SwitchTransition>
          <CSSTransition
            nodeRef={nodeRef}
            key={isLoading ? "spinner" : src ? src : "addLogo"}
            timeout={ANIMATION_DURATION}
            classNames={{
              enter: classes.documentEnter,
              enterActive: classes.documentEnterActive,
              exit: classes.documentExit,
              exitActive: classes.documentExitActive,
            }}
          >
            <div ref={nodeRef} className={classes.logo}>
              {isLoading && <Spinner size={SpinnerSize.large} />}
              {src && !isLoading && <img src={src} alt="" className={classes.logoImage} />}
              {!src && !isLoading && <label className={classes.label}>Drag a cover here or click to select</label>}
            </div>
          </CSSTransition>
        </SwitchTransition>
        
      </div>
    </div>
  );
}

export default Cover;