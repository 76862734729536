import React, { useRef, useState, useEffect, useCallback } from 'react';
import { IAttachment } from 'common/models/Learnings/attachment';
import { getClassNames } from './VideoPlayer.classNames';
import Controls from './components/Controls/Controls';

interface IVideoPlayerProps {
  attachment: IAttachment;
}

export default function VideoPlayer({ attachment }: IVideoPlayerProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  // const [srcState, setSrcState] = useState<string>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [muted, setMuted] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(true);

  const classes = getClassNames((videoRef?.current?.videoWidth || 1) > (videoRef?.current?.videoHeight || 1));

  const updateCurrentTime = useCallback(() => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      if (isPlaying) {
        requestAnimationFrame(updateCurrentTime);
      }
    }
  }, [isPlaying]);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
        requestAnimationFrame(updateCurrentTime);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleVolume = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(!muted);
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const onLoadedMetadata = () => {
        setDuration(video.duration);
      };
      videoRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
      return () => {
        video.removeEventListener('loadedmetadata', onLoadedMetadata);
      };
    }
  }, [attachment?.src]);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      videoRef.current.currentTime = Number(event.target.value);
      setCurrentTime(Number(event.target.value));
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  return (
      <div
        className={classes.videoPlayerContainer}
        onMouseEnter={() => {if (isPlaying) setShowControls(true)}}
        onMouseLeave={() => {if (isPlaying) setShowControls(false)}}
        onClick={togglePlayPause}
      >
        <video
          ref={videoRef}
          className={classes.videoPlayer}
          src={attachment?.src}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => {
            if (!videoRef.current) return;
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
            setCurrentTime(0);
            setShowControls(true);
          }}
        />
        {videoRef?.current && <Controls
          togglePlayPause={togglePlayPause}
          isPlaying={isPlaying}
          currentTime={currentTime}
          duration={duration}
          handleTimeChange={handleTimeChange}
          showControls={showControls}
          muted={muted}
          toggleVolume={toggleVolume}
        />}
        
      </div>
  );
};




















// import { useState, useRef, useCallback, useEffect } from "react";
// import { IAttachment } from "common/models/Learnings/attachment";
// import { getClassNames } from "./VideoPlayer.classNames";
// import Controls from "./components/Controls/Controls";

// interface IVideoPlayerProps {
//   attachment: IAttachment;
// }

// export default function VideoPlayer({ attachment }: IVideoPlayerProps) {
//   const [showControls, setShowControls] = useState<boolean>(true);
//   const [duration, setDuration] = useState<number>(0);
//   const [currentTime, setCurrentTime] = useState<number>(-1);
//   const [muted, setMuted] = useState<boolean>(false);
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const classes = getClassNames();

//   const onPlayClick = useCallback(() => {
//     if (videoRef.current) {
//       videoRef.current.play();
//     }
//   }, [videoRef]);

//   const onPauseClick = useCallback(() => {
//     if (videoRef.current) {
//       videoRef.current.pause();
//     }
//   }, [videoRef]);

//   const onVideoMouseOver = useCallback(() => {
//     if (!videoRef.current) return;
//     setShowControls(true);
//   }, [videoRef]);

//   const onVideoMouseLeave = useCallback(() => {
//     if (!videoRef.current) return;
//     if (!videoRef.current.paused) setShowControls(false);
//   }, [videoRef]);

//   const handleTimeUpdate = () => {
//     if (videoRef.current) {
//       setCurrentTime(videoRef.current.currentTime);
//     }
//   };

//   function handleVideoRef(video: HTMLVideoElement | null) {
//     if (!video) return;
//     setCurrentTime(video.currentTime);
//     setDuration(video.duration);
//   }

//   function updateVolume() {
//     if (!videoRef.current) return;
//     videoRef.current.muted = !videoRef.current.muted;
//     setMuted(videoRef.current.muted);
//   }

//   return (
//     <div
//       className={classes.videoPlayer}
//       onMouseOver={onVideoMouseOver}
//       onMouseLeave={onVideoMouseLeave}
//       onClick={videoRef.current !== null && videoRef.current.paused ? onPlayClick : onPauseClick}
//       >
//       <video
//         className={classes.video}
//         ref={handleVideoRef}
//         onPlay={onPlayClick}
//         onPause={onPauseClick}
//         onTimeUpdate={handleTimeUpdate}
//         onEnded={() => {
//           if (!videoRef.current) return;
//           videoRef.current.pause();
//           videoRef.current.currentTime = 0;
//           setShowControls(true);
//         }}
//       >
//         <source src={attachment.src} type='video/mp4' />
//       </video>
      
//       <Controls
//         videoRef={videoRef}
//         currentTime={currentTime}
//         updateCurrentTime={setCurrentTime}
//         duration={duration}
//         muted={muted}
//         updateVolume={updateVolume}
//         showControls={showControls}
//         onPlayClick={onPlayClick}
//         onPauseClick={onPauseClick}
//       />
//     </div>
//   )
// }