import { mergeStyleSets } from '@fluentui/react'

interface ICategoriesClassNames {
  categoriesContainer: string;
}

export const getClassNames = (): ICategoriesClassNames => {
  return mergeStyleSets({
    categoriesContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
  })
}