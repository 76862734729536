import { mergeStyleSets } from '@fluentui/react';

interface IDetailsListClassNames {
  detailsListContainer: string;
  navContainer: string;
}

export const getClassNames = (): IDetailsListClassNames => {
    return mergeStyleSets({
      detailsListContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '4rem',
        overflow: 'hidden',
        padding: '3.5rem 0',
      },

      navContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
      },
    });
};