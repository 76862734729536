import { getTheme } from "@fluentui/react";
import { IIconProps } from "common/models/Icons/icon-props";

const Add = ({ colored, color, disabled }: IIconProps) => {
  const theme = getTheme();
  return (
    // <svg width="1.4rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M6 3C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H14C15.6569 17 17 15.6569 17 14V6C17 4.34315 15.6569 3 14 3H6ZM4 6C4 4.89543 4.89543 4 6 4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V6ZM10.5 6.50021C10.5 6.22407 10.2761 6.00021 10 6.00021C9.72386 6.00021 9.5 6.22407 9.5 6.50021V9.50021H6.5C6.22386 9.50021 6 9.72407 6 10.0002C6 10.2764 6.22386 10.5002 6.5 10.5002H9.5V13.5002C9.5 13.7764 9.72386 14.0002 10 14.0002C10.2761 14.0002 10.5 13.7764 10.5 13.5002V10.5002H13.5C13.7761 10.5002 14 10.2764 14 10.0002C14 9.72407 13.7761 9.50021 13.5 9.50021H10.5V6.50021Z" fill={colored ? theme.palette.themePrimary : theme.palette.black} />
    // </svg>

    <svg width="1.3rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4C9.41421 4 9.75 4.33579 9.75 4.75V8.25H13.25C13.6642 8.25 14 8.58579 14 9C14 9.41421 13.6642 9.75 13.25 9.75H9.75V13.25C9.75 13.6642 9.41421 14 9 14C8.58579 14 8.25 13.6642 8.25 13.25V9.75H4.75C4.33579 9.75 4 9.41421 4 9C4 8.58579 4.33579 8.25 4.75 8.25H8.25V4.75C8.25 4.33579 8.58579 4 9 4ZM0 3.25C0 1.45507 1.45507 0 3.25 0H14.75C16.5449 0 18 1.45507 18 3.25V14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25ZM3.25 1.5C2.2835 1.5 1.5 2.2835 1.5 3.25V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5H3.25Z" fill={disabled ? theme.palette.neutralTertiary : colored ? color || theme.palette.themePrimary : theme.palette.black} />
    </svg>
  )
};

export default Add;