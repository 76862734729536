import { useState } from "react";
import { getClassNames } from "./Settings.classNames";

// import components
import MLCard from "common/ml-components/MLCard";
import ScormIcon from "assets/ml-icons/settings/Scorm/Scorm";
import CourseSettingsIcon from "assets/ml-icons/settings/Course/Course";
// import ScormSettings from "./components/ScormSettings/ScormSettings";
import CourseSettings from "./components/CourseSettings/CourseSettings";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import MLNoPermission from "common/ml-components/MLNoPermission";

const Settings = () => {
  const { roles } = useSelector((state: RootState) => state.account);
  const [selectedModal, setSelectedModal] = useState<string>('');
  const classes = getClassNames();

  return (
    <section className={classes.settingsPage}>
      {roles.includes('Admin') ? (
        <>
          <MLCard 
            Icon={<ScormIcon />}
            action={() => setSelectedModal('general')}
            buttonLabel={"General settings"}
            description={"Manage your app and general settings."}
          />

          <MLCard
            Icon={<CourseSettingsIcon />}
            action={() => setSelectedModal('course')}
            buttonLabel={"Course settings"}
            description={"Customize and manage your course categories and preferences."}
          />

          <GeneralSettings
            show={selectedModal === 'general'}
            onDismiss={() => setSelectedModal('')}
          />

          <CourseSettings
            show={selectedModal === 'course'}
            onDismiss={() => setSelectedModal('')}
          />
        </>
      ) : (
        <MLNoPermission message="You don't have permission to access this page." />
      )}
    </section>
  )
};

export default Settings;