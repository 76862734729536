import { mergeStyleSets } from "@fluentui/react"

interface IQuizClassNames {
  quizContainer: string;
}

export const getClassNames = (): IQuizClassNames => {
  return mergeStyleSets({
    quizContainer: {
      overflowY: 'auto',
      display: 'flex',
      gap: '2rem',
    },
  });
};