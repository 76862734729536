import React, { ChangeEvent, useRef, useState } from 'react';
import { getClassNames } from './AddAttachment.classNames';
import MLPrimaryButton from "common/ml-components/buttons/MLPrimaryButton";
import { ANIMATION_DURATION } from 'utils/constants/styles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import MLTextField from 'common/ml-components/inputs/MLTextField';
import MLDefaultButton from 'common/ml-components/buttons/MLDefaultButton';
import { useFormContext } from 'react-hook-form';

interface IAddAttachmentProps {
  inputFileRef: React.RefObject<HTMLInputElement>;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const AddAttachment: React.FC<IAddAttachmentProps> = ({ inputFileRef, onFileChange }) => {
  const [showEmbedForm, setShowEmbedForm] = useState<boolean>(false);
  const [embedCode, setEmbedCode] = useState<string>('');

  const buttonsRef = useRef<HTMLDivElement>(null);
  const embedContentRef = useRef<HTMLDivElement>(null);
  const nodeRef = showEmbedForm ? embedContentRef : buttonsRef;

  const { setValue } = useFormContext();

  const onChooseFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const classes = getClassNames();

  return (    
    <SwitchTransition>
      <CSSTransition
        key={showEmbedForm ? 'embedForm' : 'buttons'}
        nodeRef={nodeRef}
        timeout={ANIMATION_DURATION}
        classNames={{
          enter: classes.contentEnter,
          enterActive: classes.contentEnterActive,
          exit: classes.contentExit,
          exitActive: classes.contentExitActive,
        }}
      >
        <div className={classes.addAttachmentContainer} ref={nodeRef}>
          {showEmbedForm ? 
            (<div className={classes.embedFormContainer}>
              <MLTextField
                name='url'
                value={embedCode}
                onChange={(e: any) => setEmbedCode(e.target.value)}
                label='iFrame or URL'
                multiline
                autoAdjustHeight
                resizable={false}
                uncontrolled
                backgroundColor='white'
              />
              <div className={classes.embedFormButtonsContainer}>
                <MLDefaultButton
                  text='Cancel'
                  onClick={() => {
                    setShowEmbedForm(false);
                    setEmbedCode('');
                  }}
                />
                <MLPrimaryButton
                  text='Add'
                  type='button'
                  onClick={() => {
                    if (embedCode)
                      setValue('attachment', { src: '', data: embedCode, contentType: 'embedding' }, { shouldDirty: true });
                    setShowEmbedForm(false);
                  }}
                />
              </div>
            </div>) :
            (<>
              <input
                type="file"
                accept="image/*,video/*"
                ref={inputFileRef}
                style={{ display: 'none' }}
                onChange={onFileChange}
              />
              <MLPrimaryButton
                type='button'
                text="Upload photo/video"
                width='12rem'
                height='5rem'
                onClick={onChooseFileClick}
              />
              <MLPrimaryButton
                type='button'
                text="Add embedded content"
                width='12rem'
                height='7rem'
                onClick={() => setShowEmbedForm(true)}
              />
            </>)
          }
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default AddAttachment;
