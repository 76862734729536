import { getTheme, mergeStyleSets } from "@fluentui/react";
import { BORDER_RADIUS, ANIMATION_DURATION } from "utils/constants/styles";

export interface IFileDetailsClassNames {
    fileDetailsContainer: string;
    showDetails: string;
    chevronDownMed: string;
    inputsContainer: string;
    groupInput: string;
    textInput: string;
    titleLabel: string;
    advancedOptionsLabel: string;

    enter: string;
    enterActive: string;
    exit: string;
    exitActive: string;
    enterDone: string;
    exitDone: string;
}

export const getClassNames = (fileType: "Docx" | "Pdf" | null | undefined, showDetails: boolean): IFileDetailsClassNames => {
    const theme = getTheme();

    return mergeStyleSets({
      fileDetailsContainer:{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
      },

      showDetails: {
          width: 'fit-content',
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          cursor: !fileType ? 'auto' : 'pointer',
          userSelect: 'none',
          opacity: !fileType ? 0.2 : 1,
      },

      chevronDownMed: {
        fontSize: 12,
        marginTop: 2,
        transform: showDetails ? 'rotate(-180deg)' : 'rotate(0)',
        transition: 'all 400ms',  
      },

      inputsContainer: {
        display: 'flex',
        gap: 10,
      },

      groupInput: {
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          flex: 1,
      },

      textInput: {
          width: '100%',
          border: 'none',
          background: theme.palette.neutralLighterAlt,
          borderRadius: BORDER_RADIUS,
          fontSize: theme.fonts.large.fontSize,
          padding: '5px 10px',
          outline: 'none'
      },

      titleLabel: {
          color: theme.palette.neutralSecondary,
          fontWeight: theme.fonts.medium.fontWeight
      },

      advancedOptionsLabel: {
        fontSize: '1.3rem',
        fontWeight: 600,
      },

      enter: {
        opacity: 0,
        height: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },
  
      enterActive: {
        opacity: 1,
        height: '5rem',
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
        transition: `all ${ANIMATION_DURATION}ms`,
      },
  
      exit: {
        opacity: 1,
        height: '5rem',
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
  
      exitActive: {
        opacity: 0,
        height: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
        transition: `all ${ANIMATION_DURATION}ms`,
      },

      exitDone: {
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },

      enterDone: {
        height: '5rem',
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
    });
};