import { getTheme, mergeStyleSets } from "@fluentui/react";
import { BORDER_RADIUS } from "utils/constants/styles";
import { TEXT_FONT_SIZE } from "utils/constants/styles";

interface IFlashLessonClassNames {
  flashCardLessonContainer: string;
  flashCardContainer: string;
  question: string;
  answer: string;
  explanation: string;
}

export const getClassNames = (): IFlashLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    flashCardLessonContainer: {
      overflow: 'auto',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
    },

    flashCardContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    question: {
      fontSize: TEXT_FONT_SIZE,
    },

    answer: {
      fontSize: TEXT_FONT_SIZE,
      padding: 10,
      background: theme.palette.themeLight,
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    explanation: {
      fontSize: TEXT_FONT_SIZE,
    },

    hr: {
      borderTop: `1px solid ${theme.palette.neutralLight}`
    }
  });
}