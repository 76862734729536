import { IIconProps } from "common/models/Icons/icon-props";

const FinalQuiz = (props: IIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.45329 0 0 4.51015 0 10.25C0 11.0784 0.671573 11.75 1.5 11.75C2.32843 11.75 3 11.0784 3 10.25C3 6.10985 6.16672 3 10 3C13.8333 3 17 6.10985 17 10.25C17 12.0859 16.554 13.1009 15.9783 13.8571C15.5017 14.4832 14.9547 14.9403 14.2456 15.5328C13.9472 15.7821 13.6201 16.0553 13.2576 16.3752C12.1033 17.3938 10.8727 18.6863 9.95692 20.7513C9.05124 22.7935 8.5 25.4838 8.5 29.25V29.5C8.5 30.3284 9.17157 31 10 31C10.8284 31 11.5 30.3284 11.5 29.5V29.25C11.5 25.7662 12.0113 23.519 12.6993 21.9675C13.3773 20.4387 14.2717 19.4812 15.2424 18.6248C15.4574 18.4351 15.6955 18.2364 15.9458 18.0276C16.7443 17.3613 17.6676 16.5909 18.3654 15.6742C19.3835 14.3366 20 12.6641 20 10.25C20 4.51015 15.5467 0 10 0ZM10 37C10.9665 37 11.75 36.2165 11.75 35.25C11.75 34.2835 10.9665 33.5 10 33.5C9.0335 33.5 8.25 34.2835 8.25 35.25C8.25 36.2165 9.0335 37 10 37Z" fill="#242424"/>
    </svg>
  )
};

export default FinalQuiz;