import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CoursesApi, TenantApi, SettingsApi, LmsApi } from '../../api-client';
import { CoursesApiClient, CoursesFunctionApiClient, UsersApiClient, SettingsApiClient, LMSApiClient } from 'utils/settings/api';
import { RootState } from '../store';
import { ApiStatus } from 'common/models/store/api-status';

export interface IClients {
  coursesClientApi: CoursesApi | undefined;
  coursesFunctionClientApi: CoursesApi | undefined;
  usersClientApi: TenantApi | undefined;
  settingsClientApi: SettingsApi | undefined;
  lmsClientApi: LmsApi | undefined;
}

export interface IClientsState {
  clients: IClients;
  apiStatus: {
    api: ApiStatus;
    loadingMessage: string,
  };
}

export const setClients = createAsyncThunk<IClients, void>('/setClients', async (_, thunkAPI) => {
  try {
    const { token } = (thunkAPI.getState() as RootState).account;
    if (!token) throw new Error();

    const coursesClientApi = await CoursesApiClient(token);
    const usersClientApi = await UsersApiClient(token);
    const coursesFunctionClientApi = await CoursesFunctionApiClient(token);
    const settingsClientApi = await SettingsApiClient(token);
    const lmsClientApi = await LMSApiClient(token);

    return {
      coursesClientApi,
      coursesFunctionClientApi,
      usersClientApi,
      settingsClientApi,
      lmsClientApi
    };
  } catch (err) {
    console.error(err);
    return {
      coursesClientApi: undefined,
      coursesFunctionClientApi: undefined,
      usersClientApi: undefined,
      settingsClientApi: undefined,
      lmsClientApi: undefined
    };
  }
});

const initialState: IClientsState = {
  clients: {
    coursesClientApi: undefined,
    coursesFunctionClientApi: undefined,
    usersClientApi: undefined,
    settingsClientApi: undefined,
    lmsClientApi: undefined
  },
  apiStatus: {
    api: ApiStatus.IDLE,
    loadingMessage: '',
  }
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setClients.fulfilled, (state, action) => {
      return {
        ...state,
        clients: {
          coursesClientApi: action.payload.coursesClientApi,
          coursesFunctionClientApi: action.payload.coursesFunctionClientApi,
          usersClientApi: action.payload.usersClientApi,
          settingsClientApi: action.payload.settingsClientApi,
          lmsClientApi: action.payload.lmsClientApi,
        },
        apiStatus: {
          ...state.apiStatus,
          api: ApiStatus.SUCCESS,
          loadingMessage: '',
        }
      }
    });
    builder.addCase(setClients.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          api: ApiStatus.LOADING,
          loadingMessage: '',
        }
      }
    });
    builder.addCase(setClients.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          api: ApiStatus.ERROR,
          loadingMessage: '',
        }
      }
    });
  },
});

export default clientsSlice.reducer;