// import react
import { useEffect, useRef, useState, useCallback, ChangeEvent } from 'react';

// import hooks
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const useAudioPlayer = (lesson: any, courseId: string | undefined, language: string | null | undefined, onPlayAudioLessonId: string, setAudioLessonId: (id: string) => void) => {
  const { coursesClientApi } = useSelector((state: RootState) => state.clients.clients);
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [audioSource, setAudioSource] = useState<string>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [timeProgress, setTimeProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number | null>(null);

  const getAudioLesson = async () => {
    if (!courseId || !language || !coursesClientApi) return;
    
    try {
      setIsLoading(true);
      const audio = await coursesClientApi.getLessonAudio(courseId, language, lesson.id, {
        responseType: 'arraybuffer'
      });
      
      const blob = new Blob([(audio.data as unknown as ArrayBuffer)], { type: 'audio/wave' });
      const blobUrl = URL.createObjectURL(blob);
      setAudioSource(blobUrl);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onPauseClick = () => {
    setIsPlaying(false);
    if (audioRef && audioRef.current) audioRef.current.pause();
  };

  const onPlayClick = async () => {
    if (!audioSource) await getAudioLesson();
    else {
      setIsPlaying(true);
      if (audioRef && audioRef.current) {
        setAudioLessonId(lesson.id);
        audioRef.current.play();
        animateProgressBar();
      }
    }
  };

  const onForward = () => {
    if (audioRef && audioRef.current && progressBarRef && progressBarRef.current) {
      audioRef.current.currentTime = audioRef.current.currentTime + 5;
      setTimeProgress(audioRef.current.currentTime);
    }
  };

  const onBackward = () => {
    if (audioRef && audioRef.current && progressBarRef && progressBarRef.current) {
      audioRef.current.currentTime = audioRef.current.currentTime - 5;
      setTimeProgress(audioRef.current.currentTime);
    }
  };

  const animateProgressBar = useCallback(() => {
    if (audioRef && audioRef.current && progressBarRef && progressBarRef.current) {
      setTimeProgress(audioRef.current.currentTime);
      progressBarRef.current.value = ((audioRef.current.currentTime / audioRef.current.duration) * 100).toString();

      if (audioRef.current.currentTime === audioRef.current.duration) {
        audioRef.current.currentTime = 0;
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }

    requestAnimationFrame(animateProgressBar);
  }, []);

  const onProgressChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (audioRef && audioRef.current && progressBarRef && progressBarRef.current) {
      audioRef.current.currentTime = (+progressBarRef.current.value / 100) * audioRef.current.duration;
      setTimeProgress(audioRef.current.currentTime);
    }
  };

  const onLoadedMetadata = () => {
    if (audioRef && audioRef.current) setDuration(audioRef.current.duration);
  };

  const onVolumeClick = () => {
    if (audioRef && audioRef.current) audioRef.current.muted = !audioRef.current.muted
  };

  useEffect(() => {
    if (!audioSource) return;
    setIsPlaying(true);
    if (audioRef && audioRef.current) {
      setAudioLessonId(lesson.id);
      audioRef.current.play();
      animateProgressBar();
    }
  }, [audioSource, animateProgressBar, lesson.id, setAudioLessonId]);

  useEffect(() => {
    if (onPlayAudioLessonId !== lesson.id) {
      if (audioRef && audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [onPlayAudioLessonId, lesson.id]);

  useEffect(() => {
    setAudioSource(undefined);
    if (!audioRef?.current) return;
    audioRef.current.currentTime = 0;
    audioRef.current.pause();
    setIsPlaying(false);
    setDuration(null);
    setTimeProgress(0);
  }, [language, lesson]);

  return { audioSource, isLoading, audioRef, progressBarRef, onLoadedMetadata, isPlaying, onPlayClick, onPauseClick, timeProgress, duration, onProgressChange, onVolumeClick, onForward, onBackward };
};

export default useAudioPlayer;