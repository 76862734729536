import { mergeStyleSets } from '@fluentui/react';

interface IShimmersClassNames{
  cell: string;
}
export const getClassNames = (): IShimmersClassNames => {
  return mergeStyleSets({
    cell: {
      width: '100%',
      height: '3.5rem',

      display: 'flex',
      alignItems: 'center',
    },
  });
};