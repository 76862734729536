import { mergeStyleSets, getTheme } from '@fluentui/react';

interface ICardClassNames{
  lessonContainer: string;
  test: string;
  header: string;
  body: string;
  hr: string;
}

export const getClassNames = (dragDisabled: boolean): ICardClassNames => {
  const theme = getTheme();
    return mergeStyleSets({
      lessonContainer: {
        overflow: 'hidden',
        width: '100%',
        height: '46rem',
        background: theme.palette.white,
        borderTop: `3px solid ${theme.palette.themePrimary}`,
        borderBottom: `3px solid ${theme.palette.themePrimary}`,
        cursor: dragDisabled ? 'default' : 'move',
      },

      test: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      },

      header: {
        flex: '0 0 8rem',
        padding: '2rem',
        color: theme.palette.themePrimary,
        background: theme.palette.themeLighterAlt
      },

      body: {
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

        '*': {
          overflowWrap: 'break-word',
        }
      },

      hr: {
        margin: '0 2rem',
        borderTop: `1px solid ${theme.palette.neutralLight}`
      }
    });
};