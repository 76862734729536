import { getTheme } from "@fluentui/react";
import { IStatusInfo, StatusNames } from "common/models/Learnings/status";

// import icons
import Draft from "assets/ml-icons/Status/Draft/draft";
import Generating from "assets/ml-icons/Status/Generating/generating";
import Published from "assets/ml-icons/Status/Published/published";
import Running from "assets/ml-icons/Status/Running/running";
import Cancelled from "assets/ml-icons/Status/Cancelled/cancelled";

export const Status: { [key in StatusNames]: IStatusInfo } = {
  [StatusNames.Generating]: {
    icon: Generating,
    color: '#001d52',
    backgroundColor: getTheme().palette.blue,
  },

  [StatusNames.Draft]: {
    icon: Draft,
    color: '#825A00',
    backgroundColor: getTheme().palette.yellow,
  },

  [StatusNames.Published]: {
    icon: Published,
    color: '#237D4B',
    backgroundColor: getTheme().palette.green,
  },

  [StatusNames.Running]: {
    icon: Running,
    color: '#001d52',
    backgroundColor: getTheme().palette.magenta,
  },

  [StatusNames.Cancelled]: {
    icon: Cancelled,
    color: '#E63782',
    backgroundColor: getTheme().palette.red,
  },

  [StatusNames.Completed]: {
    icon: Published,
    color: '#237D4B',
    backgroundColor: getTheme().palette.green,
  },

  [StatusNames.GenError]: {
    icon: Cancelled,
    color: '#E63782',
    backgroundColor: getTheme().palette.red,
  },

  [StatusNames.Warning]: {
    icon: Cancelled,
    color: '#ff7700',
    backgroundColor: getTheme().palette.orange,
  },
};