import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons, loadTheme } from '@fluentui/react';
import { ApplicationSettings } from './utils/settings/application';
import whyDidYouRender from '@welldone-software/why-did-you-render';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initializeIcons();
loadTheme(ApplicationSettings.APPLICATION_DEFAULT_THEME);

whyDidYouRender(React, {
  trackAllPureComponents: false, // Track only specific components
});

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
