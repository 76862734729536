import { useContext } from 'react';

// import components
import MLModal from 'common/ml-components/MLModal';
import MLForm from 'common/ml-components/MLForm';
import CourseInfo from './components/CourseInfo/CourseInfo';
import CourseDetails from './components/CourseDetails/CourseDetails';

// import redux
import { useAppDispatch } from 'store/store';
import { createFromScratch, getCourses } from 'store/slices/courses';

// import react-router
import { useNavigate } from 'react-router-dom';

// import react-hook-form
import { SubmitHandler } from 'react-hook-form';

// import contexts
import { LoadingContext } from 'contexts/loading-context';

// import helpers
import { timeout } from 'utils/helpers/helpers';

interface ICreateFromScratchProps {
  show: boolean;
  onDismiss: () => void;
}

interface ICreateFromScratchFormData {
  title: string;
  description: string;
  categoryId: string | undefined;
  documentLanguage: string | undefined;
  hasAudio: boolean;
}

const CreateFromScratch: React.FC<ICreateFromScratchProps> = ({ show, onDismiss }) => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const defaultValues: ICreateFromScratchFormData = {
    title: '',
    description: '',
    categoryId: undefined,
    documentLanguage: undefined,
    hasAudio: true,
  }

  const onSubmit: SubmitHandler<ICreateFromScratchFormData> = async (data: ICreateFromScratchFormData) => {
    try {
      await dispatch(createFromScratch(data)).unwrap();
      onDismiss();
      startLoading('Redirecting to the learnings page...');
      await timeout(2000);
      await dispatch(getCourses()).unwrap();
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width="50rem"
    >
      <MLForm
        title="Create from scratch"
        onSubmit={onSubmit}
        submitButtonLabel="Create"
        onDismiss={onDismiss}
        defaultValues={defaultValues}
      >
        <CourseInfo />
        <CourseDetails />
      </MLForm>
    </MLModal>
  );
};

export default CreateFromScratch;