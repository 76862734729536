import { mergeStyleSets } from '@fluentui/react';

interface IGenerateLessonClassNames {
  generateLessonContainer: string;
}

export const getClassNames = (): IGenerateLessonClassNames => {
  return mergeStyleSets({
    generateLessonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4rem',
    }
  });
};