import { getTheme, mergeStyleSets } from "@fluentui/react";
import { ANIMATION_DURATION, BORDER_RADIUS, TEXT_FONT_SIZE } from "utils/constants/styles";
import { Status } from "utils/constants/status";

interface IErrorBannerClassNames {
  banner: string;
  bannerContainer: string;
  errorBanner: string;
  justGeneratedBanner: string;
  warningBanner: string;
  messageContainer: string;
  message: string;
  iconContainer: string;
  closeIcon: string;

  enter: string;
  enterActive: string;
  exit: string;
  exitActive: string;



  
  background: string;
  backgroundEnter: string;
  backgroundEnterActive: string;
  backgroundExit: string;
  backgroundExitActive: string;
}

export const getClassNames = (): IErrorBannerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    banner: {
      zIndex: 1,
      position: 'absolute',
      width: '75%',
      top: '9rem',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      justifyContent: 'center',
    },

    bannerContainer: {
      zIndex: 1,
      overflow: 'hidden',
      position: 'relative',
      padding: '1rem 2rem',
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '4rem',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.4)',
    },

    errorBanner: {
      color: Status.GenError.color,
      border: `1px solid ${theme.palette.red}`,
      backgroundColor: '#f0c5c8',
    },

    justGeneratedBanner: {
      color: Status.Draft.color,
      border: `2px solid ${theme.palette.yellow}`,
      backgroundColor: '#f5e7c1',
    },

    warningBanner: {
      color: theme.palette.orange,
      border: `2px solid ${theme.palette.orangeLighter}`,
      backgroundColor: '#f5cda9',
    },

    messageContainer: {
      overflow: 'hidden',
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      marginRight: '2rem',
    },

    message: {
      userSelect: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: TEXT_FONT_SIZE,
    },
    
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    closeIcon: {
      fontSize: 12,
      // color: theme.palette.neutralQuaternary,
      cursor: 'pointer',
    },

    enter: {
      opacity: 0,
      top: '-20%',
      // bottom: '-20%',
      // transform: 'translateX(-50%) translateY(-100%)',
    },

    enterActive: {
      opacity: 1,
      top: '9rem',
      // bottom: '5rem',
      transition: 'opacity 500ms, top 500ms',
      // transition: 'opacity 500ms, bottom 500ms',
    },

    exit: {
      opacity: 1,
      top: '9rem',
      // bottom: '5rem',
      // transform: 'translateX(-50%) translateY(0)',
    },

    exitActive: {
      opacity: 0,
      top: '-20%',
      // bottom: '-20%',
      transition: 'opacity 500ms, top 500ms',
      // transition: 'opacity 500ms, bottom 500ms',
    },






    background: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      // background: `linear-gradient(
      //   to bottom,
      //   rgba(0, 0, 0, 0.6) 0%,
      //   rgba(0, 0, 0, 0.2) 100%
      // )`,
    },

    backgroundEnter: {
      opacity: 0,
    },

    backgroundEnterActive: {
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    backgroundExit: {
      opacity: 1,
    },

    backgroundExitActive: {
      opacity: 0,
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};