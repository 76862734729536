import { mergeStyleSets } from '@fluentui/react'

interface ICompanyClassNames {
  companyContainer: string;
}

export const getClassNames = (): ICompanyClassNames => {
  return mergeStyleSets({
    companyContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
  })
}