import { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import { ApplicationSettings } from "utils/settings/application";
import saveAs from 'file-saver';
import { LoadingContext } from "contexts/loading-context";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { getClassNames } from "./Landing.classNames";

const Landing: React.FC = () => {
  const token = useSelector((state: RootState) => state.account.token);
  const [searchParams] = useSearchParams();
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const doAction = useCallback(async () => {
    console.log('landing');
    const action = searchParams.get('action');
    
    try {
      if (action === 'downloadCertificate') {
        const courseId = searchParams.get('courseId');
        const lang = searchParams.get('lang');
        if (!courseId || !lang) throw new Error('Invalid parameters');

        startLoading('Downloading the certificate...');
        const data = await axios.get(`${ApplicationSettings.IAI_APP_API_URL}/api/CourseProgresses/${courseId}/getCertificate/${lang}`, { 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'arraybuffer'
        });
        const blob = new Blob([(data?.data as unknown as ArrayBuffer)]);
        const fileName = `Certificate-${courseId}.pdf`;
        saveAs(blob, fileName);
        setSuccess(true);
      }
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      stopLoading();
    }
  }, [searchParams, startLoading, stopLoading, token]);

  useEffect(() => {
    doAction();
  }, [doAction]);

  const classes = getClassNames();

  return (
    <div className={classes.landingPageContainer}>
      {success && <p className={classes.message}>Thank you for downloading the certificate!</p>}
      {error && <p className={classes.message}>There was an error downloading the certificate, please try again...</p>}
    </div>
  );
};

export default Landing;