// import styles
import { getClassNames } from "./Recipient.classNames";

// import components
import MLPeoplePicker from "common/ml-components/pickers/MLPeoplePicker/MLPeoplePicker";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { mapUsers } from "utils/mappers/user";
import { IUser } from "common/models/LearningDetails/user";
import { IdentityTypeDto } from "api-client";

interface IRecipientProps {}

const Recipient = (props: IRecipientProps) => {
  const usersClientApi = useSelector((state: RootState) => state.clients.clients.usersClientApi);
  const onResolveSuggestions = async (filter: string): Promise<IUser[]> => {
    if (!filter || !usersClientApi) return [];

    try {
      const response = await usersClientApi.apiTenantSearchValueGet(filter);
      if (!response.data) return [];
      
      const persons = mapUsers(response.data);
      const personsWithImages = await Promise.all(
        persons.map(async (person: IUser) => {
          try {
            const response = 
              person.userType === IdentityTypeDto.User ? await usersClientApi.apiTenantUsersUpnOrIdPhotoGet(person.key as string) :
              await usersClientApi.apiTenantGroupsUpnOrIdPhotoGet(person.key as string);
            return {
              ...person,
              imageUrl: `data:image/png;base64,${response.data}`,
            };
          } catch (err) {
            console.error(err);
            return { ...person };
          }
          
        })
      );
      
      return personsWithImages;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const classes = getClassNames();

  return (
    <div className={classes.recipientContainer}>
      <MLPeoplePicker
        name="recipient"
        label="Assignee"
        formRequired
        onResolveSuggestions={onResolveSuggestions}
        itemLimit={1}
        resolveDelay={300}
      />
    </div>
  )
};

export default Recipient;