import { ApplicationSettings } from "utils/settings/application";

export class Logger {
    public static log(...data: any[]): void {
        if(!ApplicationSettings.SHOW_LOG) return;
        data.unshift("IAILog:");
        console.log(...data);
    }

    public static warn(...data: any[]): void {
        if(!ApplicationSettings.SHOW_LOG) return;
        data.unshift("IAILog: ");
        console.warn(...data);
    }

    public static error(...data: any[]): void {
        if(!ApplicationSettings.SHOW_LOG) return;
        data.unshift("IAILog: ");
        console.error(...data);
    }

    public static render(...data: any[]): void {
        if(!ApplicationSettings.SHOW_LOG) return;
        data.unshift("IAIRender: ");
        console.debug(...data);
    }
}