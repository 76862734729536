import { getTheme, mergeStyleSets } from "@fluentui/react"
import { ANIMATION_DURATION, BORDER_RADIUS } from "utils/constants/styles";

interface ILessonDataClassNames {
  lessonDataContainer: string;
  tabContentContainer: string;
  tabComponentContainer: string;

  contentEnter: string;
  contentEnterActive: string;
  contentExit: string;
  contentExitActive: string;
}

export const getClassNames = (): ILessonDataClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    lessonDataContainer: {
      overflow: 'hidden',
      flex: '0 0 50%',
      height: '100%',
      paddingRight: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },

    tabContentContainer: {
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      borderRadius: BORDER_RADIUS,
      backgroundColor: theme.palette.neutralLighterAlt,
    },

    tabComponentContainer: {
      width: '100%',
      height: '100%',
    },

    contentEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    contentEnterActive: {
      transform: 'scale(1)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    contentExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    contentExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};