// import react
import { createRef, useEffect, useMemo, useRef, useState } from "react";

// import styles
import { getClassNames } from "./LanguageOptions.classNames";

// import components
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useFormContext } from "react-hook-form";
import MLHorizontalDivider from "common/ml-components/MLHorizontalDivider";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";
import { IDropdownOption } from "@fluentui/react";
import { getLanguageFromCode, sortString } from "utils/helpers/helpers";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";

interface ILanguagesProps {
  updateHeight: (height: number) => void;
  addingNewLanguage: () => void;
}

const LanguageOptions: React.FC<ILanguagesProps> = ({ updateHeight, addingNewLanguage }) => {
  const availableLanguages = useSelector((state: RootState) => state.settings.settings?.supportedLanguages);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);

  const { getValues, setValue } = useFormContext();
  const fields = getValues('languages.collection');

  const optionLanguages = useMemo((): IDropdownOption[] => {
    if (!availableLanguages) return [];

    const filteredArray = 
      availableLanguages.filter((language) => !fields?.find((el: any) => el.id === language));

      return filteredArray.map((language: string): IDropdownOption => ({
        key: language,
        text: getLanguageFromCode(language),
      })).sort((a, b) => sortString(a.text, b.text));
  }, [availableLanguages, fields]);
  
  const [languages, setLanguages] = 
    useState<{ id: string | number; value: string; active?: boolean; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; active: boolean }) => ({
        id: field.id,
        value: field.value,
        active: field.active,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const ref = useRef<HTMLDivElement>(null);

  const onAdd = (value: string) => {
    addingNewLanguage();
    const newItem = { id: value, value: getLanguageFromCode(value), active: false };
    setLanguages([...languages, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    setValue('languages.collection', [...fields, { ...newItem }], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...languages];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setLanguages(newItems);
    setValue('languages.collection', newItems, { shouldDirty: true });
  };

  const onToggle = (id: string | number, checked: boolean) => {
    const newItems = [...languages];
    const index = newItems.findIndex((item) => item.id === id);
    newItems[index].active = checked;
    setLanguages(newItems);
    setValue('languages.collection', newItems, { shouldDirty: true });
  }

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const disableAddLanguage = supportedLanguages && (fields.length > supportedLanguages.length);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.languagesOptionsContainer}>

      <MLCollection
        items={languages}
        setItems={setLanguages}
        title='Languages'
        maxHeight
        withToggle
        disabledIndex={languages.findIndex(language => language.id === documentLanguage)}
        nonEditable
        onDelete={onDelete}
        onToggle={onToggle}
      />
      <MLHorizontalDivider />
      <MLNewItem
        items={languages}
        setItems={setLanguages}
        options={optionLanguages}
        title='New language'
        noMargin
        paddingRight
        disabled={disableAddLanguage || false}
        onAdd={onAdd}
      />

      {disableAddLanguage && (
        <div className={classes.errorContainer}>
          <p className={classes.errorLabel}>
            <span>* Please add one language at time.</span>
            <span>
              ** If the new language is toggled on and the course is already published, the course will be published in the new language automatically.
            </span>
          </p>
        </div>
      )}
    </div>
  )
};

export default LanguageOptions;