import { getTheme } from "@fluentui/react";

interface IPlayProps {
  onPlayClick: () => void;
}

const PlayRounded = (props: IPlayProps) => {
  const theme = getTheme();

  return (
    // <svg width="80" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M29.7655 18.7127L17.4042 11.3254C15.9045 10.4291 14 11.5096 14 13.2567V26.744C14 28.4912 15.9045 29.5717 17.4043 28.6754L29.7655 21.2878C30.7395 20.7057 30.7395 19.2948 29.7655 18.7127ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 2.5C29.665 2.5 37.5 10.335 37.5 20C37.5 29.665 29.665 37.5 20 37.5C10.335 37.5 2.5 29.665 2.5 20C2.5 10.335 10.335 2.5 20 2.5Z" fill={theme.palette.themePrimary}/>
    // </svg>

    <svg width="80" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onPlayClick} cursor='pointer'>
      <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM29.7655 18.7127C30.7395 19.2948 30.7395 20.7058 29.7655 21.2879L17.4043 28.6755C15.9045 29.5718 14 28.4913 14 26.7441V13.2568C14 11.5097 15.9045 10.4292 17.4042 11.3254L29.7655 18.7127Z" fill={theme.palette.themeTertiary}/>
    </svg>
  )
};

export default PlayRounded;