import { mergeStyleSets } from "@fluentui/react";
import { ANIMATION_DURATION } from "utils/constants/styles";

interface ITabFormClassNames {
  tabFormContainer: string;
  tabComponentContainer: string;

  contentEnter: string;
  contentEnterActive: string;
  contentExit: string;
  contentExitActive: string;
}

export const getClassNames = (): ITabFormClassNames => {
  return mergeStyleSets({
    tabFormContainer: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '4rem',
    },

    tabComponentContainer: {
      scrollbarGutter: 'stable',
      padding: '0 0.5rem 0 0',
      maxHeight: '34rem',
    },

    contentEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    contentEnterActive: {
      transform: 'scale(1)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    contentExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    contentExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};