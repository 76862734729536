import { mergeStyleSets } from "@fluentui/react";
import { TEXT_FONT_SIZE } from "utils/constants/styles";

interface IBulletPointsLessonClassNames {
  bulletPointsLessonContainer: string;
  bulletPointsLessonScrollContainer: string;
  pointList: string;
  point: string;
  body: string;
}

export const getClassNames = (): IBulletPointsLessonClassNames => {
  return mergeStyleSets({
    bulletPointsLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
    },

    bulletPointsLessonScrollContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
    },

    pointList: {
      marginLeft: 20,
      listStyle: 'disc',
    },

    point: {
      fontSize: TEXT_FONT_SIZE,

      ':not(:last-child)': {
        marginBottom: 10,
      }
    },

    body: {
      fontSize: TEXT_FONT_SIZE
    },
  });
}