const UploadDocument = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.6504 4.13856C30.5203 4.13881 31.2259 3.43382 31.2259 2.56392C31.2259 1.69402 30.5212 0.988624 29.6513 0.988372L2.34966 0.980469C1.47976 0.980216 0.774109 1.68521 0.774109 2.55511C0.774109 3.425 1.47885 4.1304 2.34875 4.13065L29.6504 4.13856ZM15.7863 42.9661L16 42.9805C16.7974 42.9805 17.4564 42.3879 17.5607 41.6191L17.5751 41.4054L17.573 12.6652L25.3893 20.4783C25.9485 21.0375 26.8236 21.0882 27.4401 20.6306L27.6168 20.4781C28.1759 19.9188 28.2267 19.0438 27.7691 18.4272L27.6166 18.2506L17.1209 7.75708C16.5619 7.19825 15.6875 7.14721 15.0709 7.60412L14.8942 7.75643L4.38639 18.2499C3.77086 18.8646 3.77018 19.8619 4.38487 20.4775C4.94368 21.037 5.81869 21.0885 6.43562 20.6314L6.61239 20.479L14.4228 12.6799L14.4249 41.4054C14.4249 42.2028 15.0175 42.8618 15.7863 42.9661Z" fill="white"/>
    </svg>
  )
};

export default UploadDocument;

// width="32" height="43"