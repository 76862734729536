import { memo } from 'react';
import { getClassNames } from "./title.classNames";
import { Status } from "utils/constants/status";
import { CourseStatusDto } from '../../../../../../api-client';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ApiStatus } from 'common/models/store/api-status';
import { Spinner } from '@fluentui/react';

interface ITitleProps {
  title: string;
  status: CourseStatusDto;
}

const Title = (props: ITitleProps) => {
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  const classes = getClassNames(props.status);

  return (
    <div className={classes.titleContainer}>
      <h1 className={classes.title}>{props.title}</h1>
      {props.status && <div className={classes.statusContainer}>
        {Status[props.status].icon()}
        <span className={classes.statusLabel}>{props.status === CourseStatusDto.GenError ? 'Error' : props.status}</span>
      </div>}
      {apiStatus.status === ApiStatus.SAVING && <Spinner />}
    </div>
  )
}

export default memo(Title);