import React from 'react';
import { Checkbox, ICheckboxProps, ICheckboxStyleProps, ICheckboxStyles, IStyleFunctionOrObject, getTheme } from '@fluentui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { BORDER_RADIUS } from 'utils/constants/styles';

interface CheckboxFieldProps extends ICheckboxProps {
  name: string;
  uncontrolled?: boolean;
}

const MLCheckbox: React.FC<CheckboxFieldProps> = ({
  name,
  uncontrolled,
  ...props
}) => {
  const { control } = useFormContext();
  const theme = getTheme();

  const styles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> | undefined = {
    root: {
      width: 'fit-content',
      // height: '100%',
      marginBottom: 0,

      '&:hover': {
        '.ms-Checkbox-text': {
          // color: isError ? theme.palette.red : theme.palette.neutralSecondaryAlt,
          fontSize: '1.4rem',
          fontWeight: 400,
          color: theme.palette.black,
        },
        '.ms-Checkbox-checkbox': {
          // backgroundColor: field.value ? theme.palette.themePrimary : theme.palette.white,
        }
      }
    },
    text: {
      fontSize: '1.4rem',
      fontWeight: 400,
      color: theme.palette.black,
      padding: 0,
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      // border: field.value ? '1px solid transparent' : `1px solid ${theme.palette.neutralTertiary}`,
      // backgroundColor: field.value ? theme.palette.themePrimary : theme.palette.white,
      padding: '0 1rem',
      marginLeft: props.boxSide === 'end' ? '1rem' : 0,
      marginRight: (props.boxSide === 'start' || !props.boxSide) ? '1rem' : 0,
      borderRadius: BORDER_RADIUS,
    },
  }

  if (uncontrolled) {
    return (
      <Checkbox {...props} styles={styles} />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...field}
          {...props}
          label={props.label}
          checked={field.value ?? props.defaultChecked}
          onChange={(e, checked) => field.onChange(checked)}
          styles={styles}
        />
      )}
    />
  );
};

export default MLCheckbox;