import { getTheme, mergeStyleSets } from "@fluentui/react"

interface INavigationClassNames {
  navigation: string;
  navigationLabel: string;
}

export const getClassNames = (): INavigationClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    navigation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
    },

    navigationLabel: {
      fontSize: theme.fonts.large.fontSize,
      pointerEvents: 'none',
      userSelect: 'none'
    }
  });
};