// import styles
import { getClassNames } from "./LearningHeader.classNames";

// import components
import Title from "./Title/title";
import Actions from "./Actions/Actions";

import { useSelector } from 'react-redux';
import { RootState } from "../../../../../store/store";
import { StatusNames } from "common/models/Learnings/status";
import Shimmers from "./Shimmers/Shimmers";
import { ApiStatus } from "common/models/store/api-status";
import React from "react";
import { CourseStatusDto, LanguageStatusDto } from "api-client";

interface IHeaderProps {
  status: CourseStatusDto | LanguageStatusDto | null | undefined;
  onConfigureClick: () => void;
  onScheduleClick: () => void;
  onCourseDeleteClick: () => void;
  onExportScormClick: () => void;
  onFinalQuizClick: () => void;
  onNewLessonClick: () => void;
  onGenerateLessonClick: () => void;
  onPublishOrUnpublishClick: () => void;
  onCloneCourseClick: () => void;
  onDownloadDocumentClick: () => Promise<void>;
  onGenerateFinalQuizClick: () => void;
}

const Header: React.FC<IHeaderProps> = ({
  status,
  onConfigureClick,
  onScheduleClick,
  onCourseDeleteClick,
  onExportScormClick,
  onFinalQuizClick,
  onNewLessonClick,
  onGenerateLessonClick,
  onPublishOrUnpublishClick,
  onCloneCourseClick,
  onDownloadDocumentClick,
  onGenerateFinalQuizClick
}) => {
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  const title = useSelector((state: RootState) => state.courses.course?.title);

  const classes = getClassNames();

  return (
    <div className={classes.header}>

      {apiStatus.status === ApiStatus.LOADING && <Shimmers />}

      {apiStatus.status !== ApiStatus.LOADING && (
        <div className={classes.headerFadeIn}>
          <Title title={title || ''} status={status || StatusNames.GenError} />
        </div>
      )}
        
      <Actions
        onConfigureClick={onConfigureClick}
        onScheduleClick={onScheduleClick}
        onCourseDeleteClick={onCourseDeleteClick}
        onExportScormClick={onExportScormClick}
        onFinalQuizClick={onFinalQuizClick}
        onNewLessonClick={onNewLessonClick}
        onGenerateLessonClick={onGenerateLessonClick}
        onPublishOrUnpublishClick={onPublishOrUnpublishClick}
        onCloneCourseClick={onCloneCourseClick}
        onDownloadDocumentClick={onDownloadDocumentClick}
        onGenerateFinalQuizClick={onGenerateFinalQuizClick}
      />
    </div>
  )
};

export default Header;