import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IMLVerticalDividerClassNames {
  verticalDivider: string;
}

export const getClassNames = (): IMLVerticalDividerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    verticalDivider: {
      height: '100%',
      border: 'none',
      borderLeft: `1px solid ${theme.palette.neutralLight}`,
    }
  });
};