import MLModal from "common/ml-components/MLModal";
import { getClassNames } from "./Schedule.classNames";
import Header from "./components/Header/Header";
import { useState } from "react";
import NewSchedule, { IScheduleData } from "./components/NewSchedule/NewSchedule";
import List from "./components/List/list";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton";
import { ScheduleDto, ScheduleMethodDto } from "api-client";
import { DateTime } from "luxon";

interface IScheduleProps {
  show: boolean;
  onDismiss: () => void;
}

const Schedule: React.FC<IScheduleProps> = ({ show, onDismiss }) => {
  const [showNewSchedule, setShowNewSchedule] = useState<boolean>(false);
  const [scheduleItem, setScheduleItem] = useState<IScheduleData>();
  const [searchSchedule, setSearchSchedule] = useState<string>('');

  const onOpenNewForm = () => {
    setShowNewSchedule(true);
  };

  const onCloseForm = () => {
    setShowNewSchedule(false);
    if (scheduleItem) setScheduleItem(undefined);
  };

  const onOpenEditForm = (scheduleItem: ScheduleDto) => {
    setScheduleItem({
      recipient: [{
        key: scheduleItem.recipient.id,
        text: scheduleItem.recipient.displayName || '',
        userType: scheduleItem.recipient.type,
        imageUrl: scheduleItem.recipient.profilePicture || '',
      }],
      startDate: new Date(scheduleItem.startDate),
      time: DateTime.fromISO(scheduleItem.startDate).toJSDate(),
      timeZone: scheduleItem.timeZoneId,
      method: scheduleItem.method || ScheduleMethodDto.Teams,
      frequency: scheduleItem.frequency.toString(),
    });

    setShowNewSchedule(true);
  };

  const classes = getClassNames();

  return (
    <MLModal
      show={show}
      onDismiss={() => {
        if (showNewSchedule) {
          setShowNewSchedule(false);
          setScheduleItem(undefined);
        }
        onDismiss();
      }}
      width='100rem'
      title='Schedule'
    >
      <div className={classes.scheduleContainer}>
        <Header
          onShowNewScheduleClick={onOpenNewForm}
          updateSearchSchedule={(e) => setSearchSchedule(e.target.value)}
          searchSchedule={searchSchedule}
        />
        <NewSchedule
          show={showNewSchedule}
          onDismiss={onCloseForm}
          scheduleItem={scheduleItem}
        />
        <List
          onShowNewScheduleClick={onOpenEditForm}
          searchSchedule={searchSchedule}
        />
        <div className={classes.closeButtonContainer}>
          <DefaultButton
            text="Close"
            onClick={() => {
              setShowNewSchedule(false);
              if (scheduleItem) setScheduleItem(undefined);
              onDismiss();
            }}
          />
        </div>
      </div>
    </MLModal>
  );
};

export default Schedule;