// import react
import * as React from 'react';
import { getClassNames } from './ChevronRight.classNames';

interface IChevronRightProps {
  onClick: () => void;
  disabled: boolean;
}

const ChevronRight = (props: IChevronRightProps) => {
  const classes = getClassNames(props.disabled);

  return (
    <svg
      width="0.7rem"
      height="1.2rem"
      viewBox="0 0 7 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <path d="M0.645817 0.147077C0.840731 -0.0485327 1.15731 -0.0490966 1.35292 0.145817L6.83741 5.6108C7.05312 5.82574 7.05312 6.17505 6.83741 6.39L1.35292 11.855C1.15731 12.0499 0.840731 12.0493 0.645817 11.8537C0.450903 11.6581 0.451467 11.3415 0.647077 11.1466L5.81166 6.0004L0.647077 0.854183C0.451467 0.659268 0.450903 0.342687 0.645817 0.147077Z" fill="#242424"/>
    </svg>

  );
};

export default ChevronRight;