import { useEffect, useRef } from "react";
import { getClassNames } from "./Company.classNames";
import Logo from "./components/Logo/Logo";


interface ICompanyProps {
  updateHeight: (height: number) => void;
}

const Company: React.FC<ICompanyProps> = ({ updateHeight }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.companyContainer}>
      <Logo />
    </div>
  );
};

export default Company;