import { mergeStyleSets } from '@fluentui/react';

interface IBulletPointsLessonClassNames{
  bulletPointsLessonContainer: string;
  body: string;
  bulletPointContainer: string;
  bulletPointLineContainer: string;
  audioContainer: string;
}

export const getClassNames = (): IBulletPointsLessonClassNames => {
  return mergeStyleSets({
    bulletPointsLessonContainer: {
      flex: 1,
      paddingBottom: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    body: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 2rem',
    },

    bulletPointContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },

    bulletPointLineContainer: {
      display: 'flex',
      gap: '1rem',
    },

    audioContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 2rem',
    }
  });
};