export default function Pdf() {
  return (
    <img
      src={require('./pdf-alt.png')}
      style={{
        height: '65%',
        width: 'auto',
      }}
      alt='Pdf' />
  );
}
