const CreateFromScratch = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 30 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 1.98047C3 1.15204 2.32843 0.480469 1.5 0.480469C0.671573 0.480469 0 1.15204 0 1.98047V7.98047C0 10.4657 2.01472 12.4805 4.5 12.4805H5.12967L0.752185 21.7218C-0.309706 23.9636 -0.194479 26.5854 1.05998 28.7254L11.4473 46.445C12.1865 47.7058 13.5385 48.4805 15 48.4805C16.4615 48.4805 17.8135 47.7058 18.5526 46.445L28.94 28.7254C30.1944 26.5854 30.3097 23.9636 29.2478 21.7218L24.8703 12.4805H25.5C27.9853 12.4805 30 10.4657 30 7.98047V1.98047C30 1.15204 29.3284 0.480469 28.5 0.480469C27.6716 0.480469 27 1.15204 27 1.98047V7.98047C27 8.8089 26.3284 9.48047 25.5 9.48047H4.5C3.67157 9.48047 3 8.8089 3 7.98047V1.98047ZM21.5507 12.4805L26.5366 23.0061C27.1737 24.3512 27.1045 25.9242 26.3519 27.2082L16.5 44.0144V27.0791C17.3967 26.5604 18 25.5909 18 24.4805C18 22.8236 16.6569 21.4805 15 21.4805C13.3431 21.4805 12 22.8236 12 24.4805C12 25.5909 12.6033 26.5604 13.5 27.0791V44.0144L3.64808 27.2082C2.8954 25.9242 2.82626 24.3512 3.4634 23.0061L8.44922 12.4805H21.5507Z" fill="white"/>
    </svg>
  )
};

export default CreateFromScratch;

// width="30" height="49"