import { useFormContext } from "react-hook-form";
import { createRef, useState } from "react";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";
import { v4 } from "uuid";

interface IBulletPointsLessonProps {
  lessonTypeChanged: boolean;
}

const BulletPointsLesson: React.FC<IBulletPointsLessonProps> = ({ lessonTypeChanged }) => {
  const { getValues, setValue } = useFormContext();

  const fields = getValues('lesson.bulletPoints');
  const [bulletPoints, setBulletPoints] = 
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const onDragEnd = () => {
    setValue('lesson.bulletPoints', bulletPoints.map(bp => ({ id: bp.id, value: bp.value })), { shouldDirty: true });
  };

  const onAdd = (value: string) => {
    const newItem = { id: v4(), value };
    setBulletPoints([...bulletPoints, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    setValue('lesson.bulletPoints', [...bulletPoints, { ...newItem }], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...bulletPoints];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setBulletPoints(newItems);
    setValue('lesson.bulletPoints', newItems, { shouldDirty: true });
  };

  const onChange = (id: string | number, newValue: string) => {
    const newItems = [...bulletPoints];
    const editItem = newItems.find((item) => item.id === id);
    if (!editItem) return;

    editItem.value = newValue;
    setValue('lesson.bulletPoints', newItems, { shouldDirty: true });
  };

  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.introduction"
        label="Introduction"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
      />
      <MLCollection
        items={bulletPoints}
        setItems={setBulletPoints}
        title="Bullet points"
        disabled={lessonTypeChanged}
        draggable
        onDragEnd={onDragEnd}
        onDelete={onDelete}
        onChangeValue={onChange}
      />
      <MLNewItem
        items={bulletPoints}
        setItems={setBulletPoints}
        onAdd={onAdd}
      />
    </>
  );
};

export default BulletPointsLesson;
