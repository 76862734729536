import MLMenu from 'common/ml-components/MLMenu';
import { getClassNames } from "./AppLayout.classNames";
import { FiltersProvider } from "../../../contexts/filters-context";
import { InfoProvider } from "../../../contexts/info-context";
import { PropsWithChildren } from "react";
import MLLoading from 'common/ml-components/MLLoading';
import { LoadingProvider } from "contexts/loading-context";
import { useLocation } from 'react-router-dom';

const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const classes = getClassNames();

  return (
    <InfoProvider>
      <FiltersProvider>
        <LoadingProvider>
          <div className={classes.appLayout}>
            <MLLoading />
            {location.pathname !== '/landing' && <MLMenu />}
            {children}
          </div>
        </LoadingProvider>
      </FiltersProvider>
    </InfoProvider>
  )
};

export default AppLayout;