import { useMemo } from 'react';
import { getClassNames } from './CourseDetails.classNames';
// import components
import MLDropdown, { DropdownStyles } from 'common/ml-components/inputs/MLDropdown';

// import redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

// import FluentUI
import { IDropdownOption } from '@fluentui/react';

// import helpers
import { getLanguageFromCode, sortString } from 'utils/helpers/helpers';

// import models
import { lessonTypesOptions } from 'utils/constants/lesson-types';
import { NumberOfLessons } from 'common/models/Create/number-of-lessons';


const CourseDetails: React.FC = () => {
    const categories = useSelector((state: RootState) => state.settings.categories);
    const supportedLanguages = useSelector((state: RootState) => state.settings.settings?.supportedLanguages);

    const categoriesByLanguage: IDropdownOption[] = useMemo(() => {
      const transformedData = categories.filter(item => !item.archived)  // Filter out archived items
        .map(item => {
          const itTranslation = item.categoryTranslations?.find(translation => translation.language === "it-IT");
          return { key: item.id || '', text: itTranslation?.value || '', };
      });

      return transformedData || [];

    }, [categories]);
    
    const languages = useMemo((): IDropdownOption[] => {
      if (!supportedLanguages) return [];
      
      return supportedLanguages.map((language: string): IDropdownOption => ({
        key: language,
        text: getLanguageFromCode(language),
      })).sort((a: IDropdownOption, b: IDropdownOption) => sortString(a.text, b.text));
    }, [supportedLanguages]);

    const numberOfLessons = useMemo((): IDropdownOption[] => {
      const options: IDropdownOption[] = [];
      for (let key in NumberOfLessons) {
        options.push({key, text: NumberOfLessons[key as keyof typeof NumberOfLessons]});
      }
      return options;
    }, []);

    const onRenderTypeOfLessonsTitle = (options: any) => {
      if (!options.length) return <div></div>
      if (options.length === 1) return <div>{`${options.length} type selected`}</div>
      return <div>{`${options.length} types selected`}</div>
    };

    const classes = getClassNames();

    return (
      <div className={classes.courseDetailsContainer}>
        <MLDropdown
          name='categoryId'
          label='Category'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={categoriesByLanguage}
        />
        <MLDropdown
          name='documentLanguage'
          label='Document language'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={languages}
        />
        <MLDropdown
          name='numberOfLessons'
          label='Number of lessons'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={numberOfLessons}
        />
        <MLDropdown
          name='typeOfLessons'
          label='Type of lessons'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={lessonTypesOptions}
          multiSelect
          onRenderTitle={onRenderTypeOfLessonsTitle}
        />
      </div>
    );
  };

export default CourseDetails;