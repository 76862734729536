import { mergeStyleSets } from "@fluentui/react";
import { Status } from "utils/constants/status";
import { CourseStatusDto } from "../../../../../../api-client";
import { PAGE_TITLE_FONT_SIZE } from "utils/constants/styles";

interface ITitleClassNames {
  titleContainer: string;
  title: string;
  statusContainer: string;
  statusLabel: string;
  icon: string;
}

export const getClassNames = (status: CourseStatusDto | undefined): ITitleClassNames => {


  return mergeStyleSets({
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },

    title: {
      fontSize: PAGE_TITLE_FONT_SIZE,
    },

    // statusContainer: {
    //   position: 'relative',
    //   width: 'fit-content',
    //   padding: '4px 10px',
    //   display: 'flex',
    //   alignItems: 'center',
    //   gap: 5,
    //   marginTop: 2,

    //   '::before': {
    //     content: "''",
    //     background: status && Status[status].backgroundColor,
    //     opacity: 0.2,
    //     position: "absolute",
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     borderRadius: 100, 
    //   },

    //   '::after': {
    //     content: "''",
    //     border: `1px solid ${status && Status[status].backgroundColor}`,
    //     position: "absolute",
    //     top: 0,
    //     left: 0,
    //     width: 'calc(100% - 1px)',
    //     height: 'calc(100% - 1px)',
    //     borderRadius: 100, 
    //   },

    //   '> svg': {
    //     marginTop: 1
    //   }
    // },

    // statusLabel: {
    //   color: status && Status[status].color,
    //   fontSize: '1.4rem',
    // },

    statusContainer: {
      position: 'relative',
      width: 'fit-content',
      padding: '0.6rem 1rem',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginTop: 0,
      // borderRadius: '100px 0 0 100px', 

      '::before': {
        content: "''",
        background: status && Status[status]?.backgroundColor,
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 100, 
      },

      '::after': {
        content: "''",
        border: `1px solid ${status && Status[status]?.backgroundColor}`,
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 100, 
      },

      '> svg': {
        marginTop: 1
      }
    },

    statusLabel: {
      color: status && Status[status]?.color,
      fontSize: '1.4rem',
    },

    icon: {
      marginTop: 2,
    }
  });
};