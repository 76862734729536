import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from "@azure/msal-react";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { authenticationRequest } from "utils/settings/msal";
import { RootState, useAppDispatch } from "../../store/store";
import { setAccount } from "../../store/slices/account";
import Login from "../../pages/Login/Login";
import { useSelector } from "react-redux";

export default function Web({children}: PropsWithChildren) {
  const { token } = useSelector((state: RootState) => state.account);
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const getToken = useCallback(async () => {
    if (!accounts || accounts.length === 0) return;

    try {
      const result = await instance.acquireTokenSilent({
      ...authenticationRequest,
      account: accounts[0],
      });

      const roles = (result.idTokenClaims as any).roles;
      dispatch(setAccount({ username: accounts[0].username, token: result.accessToken, roles }));

    } catch (err) {
      console.error(err);
      }
  }, [accounts, instance, dispatch]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      loadingComponent={Login}
    >
      {isAuthenticated && token ? children : null}
    </MsalAuthenticationTemplate>
  )
}