import { mergeStyleSets } from '@fluentui/react';

interface IScormAudioClassNames {
  optionContainer: string;
  optionTitle: string;
}

export const getClassNames = (): IScormAudioClassNames => {
  return mergeStyleSets({
    optionContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '2rem'
    },

    optionTitle: {
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
      fontWeight: 400,
    }
  });
};