import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IRecipientClassNames {
  recipientContainer: string;
  titleLabel: string;
}

export const getClassNames = (): IRecipientClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    recipientContainer: {
      flex: '0 0 30%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    titleLabel: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.neutralSecondaryAlt,
    },
  });
}