import { IShimmerColors, Shimmer, getTheme } from "@fluentui/react";
import { getClassNames } from "./Shimmers.classNames";

import TextLesson from "./components/TextLesson/TextLesson";
import BulletPointsLesson from "./components/BulletPointsLesson/BulletPointsLesson";
import QuizLesson from "./components/QuizLesson/QuizLesson";
import FlashCardLesson from "./components/FlashCardLesson/FlashCardLesson";
import { memo, useMemo } from "react";
import MLHorizontalDivider from "common/ml-components/MLHorizontalDivider";

const Shimmers: React.FC = () => {
  const theme = getTheme();

  const shimmerColors: IShimmerColors = {
    shimmer: theme.palette.neutralQuaternaryAlt,
  };

  const lessonComponents = [
    <TextLesson shimmerColors={shimmerColors} />,
    <BulletPointsLesson shimmerColors={shimmerColors} />,
    <QuizLesson shimmerColors={shimmerColors} />,
    <FlashCardLesson shimmerColors={shimmerColors} />,
  ];

  const randomNumbers = useMemo(() => {
    return [...new Array(15)].map(_ => Math.floor(Math.random() * 4));
  }, []);

  const classes = getClassNames();

  return (
    <>
      {[...new Array(15)].map((_, index) => (
        <div
          key={index}
          className={classes.shimmersCardContainer}
        >
          {/* Header */}
          <div className={classes.shimmersHeader}>
            <Shimmer
              shimmerColors={shimmerColors}
              styles={{
                root: {
                  width: '90%',
                  height: '2.2rem',
                  borderRadius: 5,
                  '.ms-ShimmerLine-root': {
                    height: '2.2rem',
                  }
                },
                shimmerWrapper: {
                  height: '2.2rem',
                  borderRadius: 5,
                },
              }}
            />
            <Shimmer
              shimmerColors={shimmerColors}
              styles={{
                root: {
                  width: '50%',
                  height: '2.2rem',
                  borderRadius: 5,
                  '.ms-ShimmerLine-root': {
                    height: '2.5rem',
                  }
                },
                shimmerWrapper: {
                  height: '2.2rem',
                  borderRadius: 5,
                },
              }}
            />
          </div>
          
          <div className={classes.shimmersLesson}>
            {/* Lesson */}
            {lessonComponents[randomNumbers[index]]}

            {/* Footer */}
            <div style={{ padding: '0 2rem' }}>
              <MLHorizontalDivider />
              <div className={classes.shimmersFooter}>
              {[...new Array(2)].map((_, index) => (
                <Shimmer
                  key={index}
                  shimmerColors={shimmerColors}
                  styles={{
                    root: {
                      width: `2.6rem`,
                      height: '2.3rem',
                      borderRadius: 2,
                      '.ms-ShimmerLine-root': {
                        height: '2.3rem',
                      }
                    },
                    shimmerWrapper: {
                      height: '2.3rem',
                      borderRadius: 2,
                    },
                  }}
                />
              ))}    
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default memo(Shimmers);