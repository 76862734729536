// import react
import { getClassNames } from './Navigation.classNames';
import ChevronLeft from 'assets/ml-icons/Controls/ChevronLeft/ChevronLeft';
import ChevronRight from 'assets/ml-icons/Controls/ChevronRight/ChevronRight';
import { useWatch } from 'react-hook-form';

interface INavigationProps {
  // questionsLength: number;
  selectedQuestionIndex: number;
  onNavigationClick: (newIndex: number) => void;
}

const Navigation: React.FC<INavigationProps> = ({ selectedQuestionIndex, onNavigationClick }) => {
  const quizes = useWatch({ name: 'quizes' });
  const questionsLength = quizes?.length || 0;
  
  const classes = getClassNames();

  return (
    <div className={classes.navigation}>
      <ChevronLeft
        disabled={selectedQuestionIndex === 0}
        onClick={() => onNavigationClick(selectedQuestionIndex - 1) }
        />
      <p className={classes.navigationLabel}>{`Question n. ${selectedQuestionIndex + 1} of ${questionsLength}`}</p>
      <ChevronRight
        disabled={selectedQuestionIndex === questionsLength - 1}
        onClick={() => onNavigationClick(selectedQuestionIndex + 1) }    
      />
    </div>
  );
};

export default Navigation;