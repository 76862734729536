import ChevronLeft from "assets/ml-icons/Controls/ChevronLeft/ChevronLeft";
import ChevronRight from "assets/ml-icons/Controls/ChevronRight/ChevronRight";
import { getClassNames } from "./MLPagination.classNames";

interface IMLPaginationProps {
  totalPages: number;
  currentPage: number;
  onPrevClick: () => void;
  onNextClick: () => void;
  onIndexClick: (page: number) => void;
}

const MLPagination: React.FC<IMLPaginationProps> = (
  {
    totalPages,
    currentPage,
    onPrevClick,
    onNextClick,
    onIndexClick
  }
) => {
  const classes = getClassNames();

  return (
    <div className={classes.paginationContainer}>
      <div
        className={currentPage + 1 === 1 ? `${classes.chevronContainer} ${classes.disabled}` : classes.chevronContainer}
        onClick={onPrevClick}
      >
        <ChevronLeft
          disabled={currentPage + 1 === 1}
          onClick={() => {}} 
        />
      </div>
      <div className={classes.pageNumbersContainer}>
        {[...new Array(totalPages)].map((_, index) => (
          <div
            key={index}
            className={currentPage + 1 === index + 1 ? `${classes.page} ${classes.currentPage}` : classes.page}
            onClick={() => onIndexClick(index)}
          >{index + 1}</div>
        ))}
      </div>
      <div
        className={currentPage + 1 === totalPages ? `${classes.chevronContainer} ${classes.disabled}` : classes.chevronContainer}
        onClick={onNextClick}
      >
        <ChevronRight
          disabled={currentPage + 1 === totalPages}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default MLPagination;