export default function Docx() {
  return (
    <img
      src={require('./docx-alt.png')}
      style={{
        height: '65%',
        width: 'auto',
      }}
      alt='Word' />
  );
}
