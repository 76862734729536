import React, { PropsWithChildren } from 'react';
import { IButtonStyles, PrimaryButton } from '@fluentui/react/lib/Button';
import { BORDER_RADIUS, BUTTON_FONT_SIZE, BUTTON_FONT_WEIGHT } from 'utils/constants/styles';

interface IMLPrimaryButtonProps {
  text?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  width100?: boolean;
  height?: string;
  fontSize?: string;
}

const MLPrimaryButton: React.FC<PropsWithChildren<IMLPrimaryButtonProps>> = (
  { text, icon, onClick, disabled, type = 'submit', width, width100, height, fontSize, children }
) => {
  const styles: IButtonStyles = {
    root: {
      width: width100 ? '100%' : width ? width : '8.5rem',
      minWidth: '3.6rem',
      height: height ? height : '3.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: BORDER_RADIUS,
      border: 'none',
      fontSize: fontSize ? fontSize : BUTTON_FONT_SIZE,
      fontWeight: BUTTON_FONT_WEIGHT,
      padding: '0px 12px',
      transition: 'background-color 500ms, color 500ms',
    },

    rootDisabled: {
      cursor: 'default',
    },

    rootHovered: {
      cursor: disabled ? 'default' : 'pointer',
    },

    flexContainer: {
      width: '100%',
      justifyContent: 'center',
      gap: 10,
      padding: 0,
    },
  }

  return (
    <PrimaryButton
      onClick={onClick}
      disabled={disabled}
      styles={styles}
      type={type}
    >
      {icon && <span style={{ display: 'flex', alignItems: 'center' }}>{icon}</span>}
      {text && <span>{text}</span>}
      {children}
    </PrimaryButton>
  );
};

export default MLPrimaryButton;
