import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IDateTimeClassNames {
  // dateTime: string;
  dateTimeContainer: string;
  dateGroupInput: string
  timeGroupInput: string;
  timeZoneGroupInput: string
  titleLabel: string;
  // error: string;
}

export const getClassNames = (): IDateTimeClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    // dateTime: {
    //   flex: 4,
    //   display: 'flex',
    //   flexDirection: 'column',
    // },

    dateTimeContainer: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      gap: 5,
    },

    dateGroupInput: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    timeGroupInput: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    timeZoneGroupInput: {
      overflow: 'hidden',
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleLabel: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.neutralSecondaryAlt,
    },

    // error: {
    //   width: '100%',
    // }
  });
}