import { IShimmerColors, Shimmer } from "@fluentui/react";
import { getClassNames } from "./BulletPoints.classNames";
import MLHorizontalDivider from "common/ml-components/MLHorizontalDivider";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

interface IBulletPointsLessonProps {
  shimmerColors: IShimmerColors;
}

const BulletPointsLesson: React.FC<IBulletPointsLessonProps> = ({ shimmerColors }) => {
  const classes = getClassNames();

  return (
    <div className={classes.bulletPointsLessonContainer}>
      <div className={classes.body}>
        {[...new Array(4)].map((_, index) => (
          <div key={index} className={classes.bulletPointContainer}>
            <div className={classes.bulletPointLineContainer}>
              <Shimmer
                shimmerColors={shimmerColors}
                styles={{
                  root: {
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '50%',
                    '.ms-ShimmerLine-root': {
                      height: '1.5rem',
                    }
                  },
                  shimmerWrapper: {
                    height: '1.5rem',
                    borderRadius: '50%',
                  },
                }}
              />
              <Shimmer
                shimmerColors={shimmerColors}
                styles={{
                  root: {
                    width: '100%',
                    height: '1.5rem',
                    borderRadius: 5,
                    '.ms-ShimmerLine-root': {
                      height: '1.5rem',
                    }
                  },
                  shimmerWrapper: {
                    height: '1.5rem',
                    borderRadius: 5,
                  },
                }}
              />
            </div>
            <div className={classes.bulletPointLineContainer}>
              <div style={{ width: '1.5rem' }}/>
              <Shimmer
                shimmerColors={shimmerColors}
                styles={{
                  root: {
                    width: `${(Math.random() * 10) + 80}%`,
                    height: '1.5rem',
                    borderRadius: 5,
                    '.ms-ShimmerLine-root': {
                      height: '1.5rem',
                    }
                  },
                  shimmerWrapper: {
                    height: '1.5rem',
                    borderRadius: 5,
                  },
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={classes.audioContainer}>
        <MLHorizontalDivider />
        <AudioPlayer shimmerColors={shimmerColors} />
      </div>
    </div>
  );
};

export default BulletPointsLesson;