import React from "react";
import { getClassNames } from "./EmbeddedContent.classNames";

interface IEmbeddedContentProps {
  src: string;
}

const EmbeddedContent: React.FC<IEmbeddedContentProps> = ({ src }) => {
  const classes = getClassNames();

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {src.includes('<iframe') && (
        <div className={classes.iframeContainer} dangerouslySetInnerHTML={{ __html: src}} />
      )}
    </div>
  )
};

export default EmbeddedContent;