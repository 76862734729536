import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IChevronLeftIconClassNames {
  icon: string;
}

export const getClassNames = (disabled: boolean): IChevronLeftIconClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    icon: {
      cursor: disabled ? 'auto' : 'pointer',
      transition: 'all 350ms',

      ':hover': {
          '*': {
            fill: disabled ? theme.palette.neutralQuaternary : theme.palette.themePrimary,
            transition: 'all 350ms',
          }
        },

      '*': {
        fill: disabled ? theme.palette.neutralQuaternary : theme.palette.black,
        transition: 'all 350ms',
      },
    }
  });
};