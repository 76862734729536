// import styles
import { getClassNames } from "./Header.classNames";

// import components
import PrimaryButton from "common/ml-components/buttons/MLPrimaryButton";
import Search from "assets/ml-icons/Controls/Search/search";

// import utils
import { ChangeEvent } from "react";
import AddSchedule from "assets/ml-icons/Buttons/AddSchedule/add-schedule";

interface IHeaderProps {
  onShowNewScheduleClick: () => void;
  searchSchedule: string;
  updateSearchSchedule: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Header = (props: IHeaderProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.searchBarContainer}>
        <input
          type="text"
          className={classes.searchBar}
          placeholder="Search"
          value={props.searchSchedule}
          onChange={props.updateSearchSchedule}  
        />
        <Search />
      </div>
      <PrimaryButton
        type="button"
        text="Add schedule"
        width='15rem'
        icon={<AddSchedule />}
        onClick={props.onShowNewScheduleClick}
      />
    </div>
  )
};

export default Header;