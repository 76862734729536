import { mergeStyleSets } from "@fluentui/react";

interface IActionButtonsClassNames {
  actionButtonsContainer: string;
}

export const getClassNames = (): IActionButtonsClassNames => {
  return mergeStyleSets({
    actionButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10,
      marginTop: '2rem',
    }
  });
};