// import react
import { useEffect, useRef, useState } from 'react';

// import styles
import { getClassNames } from "./Gamification.classNames";

// import components
import MLTextField from 'common/ml-components/inputs/MLTextField';
import MLCheckbox from 'common/ml-components/inputs/MLCheckbox';
import MLHorizontalDivider from 'common/ml-components/MLHorizontalDivider';
import MLDatePicker from 'common/ml-components/pickers/MLDatePicker/MLDatePicker';
import { useFormContext } from 'react-hook-form';
import { GamificationInfoDto } from 'api-client';

interface IGamificationProps {
  updateHeight: (height: number) => void;
}

const Gamification: React.FC<IGamificationProps> = ({ updateHeight }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { getValues, setValue } = useFormContext();
  const gamification = getValues('gamification') as GamificationInfoDto;

  const [isCourseCompletitionBonusPoints, setIsCourseCompletitionBonusPoints] = useState<boolean>(
    gamification.courseCompletitionBonusPoints !== undefined &&
    gamification.courseCompletitionBonusPoints !== null &&
    gamification.courseCompletitionBonusPoints !== 0
  );
  const [isCourseCompletitionOnTime, setIsCourseCompletitionOnTime] = useState<boolean>(
    gamification.courseCompletitionPoints?.totalMaxPoints !== undefined &&
    gamification.courseCompletitionPoints?.totalMaxPoints !== null &&
    gamification.courseCompletitionPoints?.totalMaxPoints > 0
  );
  const [isOneShotExamPassingBonusPoints, setIsOneShotExamPassingBonusPoints] = useState<boolean>(
    gamification.oneShotExamPassingBonusPoints !== undefined &&
    gamification.oneShotExamPassingBonusPoints !== null &&
    gamification.oneShotExamPassingBonusPoints !== 0
  );

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.courseOptionsContainer}>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <div style={{ height: '100%',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
            <div style={{ marginTop: '1.8rem' }}>
              <MLCheckbox
                name='courseCompletitionBonusPoints'
                label='Course completion bonus'
                uncontrolled
                onChange={(event, checked) => {
                  setIsCourseCompletitionBonusPoints(checked || false);
                  if (checked === false) setValue('gamification.courseCompletitionBonusPoints', null, { shouldDirty: true });
                }}
                checked={isCourseCompletitionBonusPoints}
              />
            </div>
            <MLTextField
              name='gamification.courseCompletitionBonusPoints'
              label='Score'
              width={'12rem'}
              suffix='pts'
              disabled={!isCourseCompletitionBonusPoints}
              formRequired={isCourseCompletitionBonusPoints}
              isNumeric
            />
          </div>

          <MLHorizontalDivider />

          <div style={{ height: '100%',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
            <div style={{ marginTop: '1.8rem' }}>
              <MLCheckbox
                name='oneShotExamPassingBonusPoints'
                label='One shot final quiz bonus'
                uncontrolled
                onChange={(event, checked) => {
                  setIsOneShotExamPassingBonusPoints(checked || false);
                  if (checked === false) setValue('gamification.oneShotExamPassingBonusPoints', null, { shouldDirty: true });
                }}
                checked={isOneShotExamPassingBonusPoints}
              />
            </div>
            <MLTextField
              name='gamification.oneShotExamPassingBonusPoints'
              label='Score'
              width={'12rem'}
              suffix='pts'
              disabled={!isOneShotExamPassingBonusPoints}
              formRequired={isOneShotExamPassingBonusPoints}
              isNumeric
            />
          </div>

          <MLHorizontalDivider />

          <div style={{ height: '100%',  display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <div style={{ height: '100%',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
              <MLCheckbox
                name='completitionOnTimeTotalPoints'
                label='Course completion on time'
                uncontrolled
                onChange={(event, checked) => {
                  setIsCourseCompletitionOnTime(checked || false);
                  if (checked === false) {
                    setValue('gamification.courseCompletitionPoints.targetCompletitionDate', null, { shouldDirty: true });
                    setValue('gamification.courseCompletitionPoints.totalMaxPoints', null, { shouldDirty: true });
                    setValue('gamification.courseCompletitionPoints.minimumPoints', null, { shouldDirty: true });
                    setValue('gamification.courseCompletitionPoints.degradationPeriod', null, { shouldDirty: true });
                    setValue('gamification.courseCompletitionPoints.degradationPoints', null, { shouldDirty: true });
                    setValue('gamification.courseCompletitionPoints', null, { shouldDirty: true });
                  };
                }}
                checked={isCourseCompletitionOnTime}
              />
            </div>
            <div style={{ height: '100%',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
              <MLDatePicker
                name='gamification.courseCompletitionPoints.targetCompletitionDate'
                label='Due date'
                width='20rem'
                disabled={!isCourseCompletitionOnTime}
                formRequired={isCourseCompletitionOnTime}

              />
              <MLTextField
                name='gamification.courseCompletitionPoints.totalMaxPoints'
                label='Maximum points'
                width={'12rem'}
                suffix='pts'
                disabled={!isCourseCompletitionOnTime}
                isNumeric
                formRequired={isCourseCompletitionOnTime}
              />
              <MLTextField
                name='gamification.courseCompletitionPoints.minimumPoints'
                label='Minimum points'
                width={'12rem'}
                disabled={!isCourseCompletitionOnTime}
                suffix='pts'
                isNumeric
                formRequired={isCourseCompletitionOnTime}
              />
            </div>
            <div style={{ height: '100%',  display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: '2rem' }}>
              <MLTextField
                name='gamification.courseCompletitionPoints.degradationPeriod'
                label='Degradation'
                width={'12rem'}
                disabled={!isCourseCompletitionOnTime}
                suffix='days'
                isNumeric
                formRequired={
                  !gamification?.courseCompletitionPoints?.totalMaxPoints ||
                  !gamification?.courseCompletitionPoints?.minimumPoints ? false : !gamification?.courseCompletitionPoints?.degradationPoints ? false : true
                }
              />
              <MLTextField
                name='gamification.courseCompletitionPoints.degradationPoints'
                label='Removing points'
                width={'12rem'}
                disabled={!isCourseCompletitionOnTime}
                suffix='pts'
                isNumeric
                formRequired={
                  !gamification?.courseCompletitionPoints?.totalMaxPoints ||
                  !gamification?.courseCompletitionPoints?.minimumPoints ? false : !gamification?.courseCompletitionPoints?.degradationPeriod ? false : true
                }
              />
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default Gamification;