import { memo } from 'react';
import { getTheme, IShimmerColors, Shimmer } from "@fluentui/react";
import { getClassNames } from "./Shimmers.classNames";

interface IShimmersProps {
  column: string
}


const Shimmers: React.FC<IShimmersProps> = ({ column }) => {
  const theme = getTheme();

  const shimmerColors: IShimmerColors = {
    shimmer: theme.palette.neutralQuaternaryAlt,
  };

  const textShimmer = () => {
    return (
      <Shimmer
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `${(Math.random() * 20) + 50}%`,
            height: '2rem',
            borderRadius: 5,
            '.ms-ShimmerLine-root': {
              height: '2rem',
            }
          },
          shimmerWrapper: {
            height: '2rem',
            borderRadius: 5,
          },
        }}
      />
    )
  };

  const statusShimmer = () => {
    return (
      <Shimmer
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `${(Math.random() * 20) + 50}%`,
            height: '2.6rem',
            borderRadius: 20,
            '.ms-ShimmerLine-root': {
              height: '2.6rem',
            }
          },
          shimmerWrapper: {
            height: '2.6rem',
            borderRadius: 20,
          },
        }}
      />
    )
  };

  const authorShimmer = () => {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Shimmer
          shimmerColors={shimmerColors}
          styles={{
            root: {
              width: `3.2rem`,
              height: '3.2rem',
              borderRadius: '50%',
              '.ms-ShimmerLine-root': {
                height: '3rem',
              }
            },
            shimmerWrapper: {
              height: '3.2rem',
              borderRadius: '50%',
            },
          }}
        />
        <Shimmer
          shimmerColors={shimmerColors}
          styles={{
            root: {
              width: `${(Math.random() * 20) + 50}%`,
              height: '2rem',
              borderRadius: 5,
              '.ms-ShimmerLine-root': {
                height: '2rem',
              }
            },
            shimmerWrapper: {
              height: '2rem',
              borderRadius: 5,
            },
          }}
        />
      </div>
      
    )
  }

  const classes = getClassNames();

  return (
    <>
      {column === 'Title' && 
        <div className={classes.cell}>
          {textShimmer()}
        </div>}
      {column === 'Status' &&
        <div className={classes.cell}>
          {statusShimmer()}
        </div>
      }
      {column === 'Category' &&
        <div className={classes.cell}>
          {textShimmer()}
        </div>
      }
      {column === 'Created' &&
        <div className={classes.cell}>
          {textShimmer()}
        </div>
      }
      {column === 'Author' &&
        <div className={classes.cell}>
          {authorShimmer()}
        </div>
      }
    </>
  );
};

export default memo(Shimmers);