import React, { useState } from 'react';
import MLCheckbox from 'common/ml-components/inputs/MLCheckbox';
import MLDatePicker from 'common/ml-components/pickers/MLDatePicker/MLDatePicker';
import { useFormContext } from 'react-hook-form';

interface IDatesProps {
}

const Dates: React.FC<IDatesProps> = () => {
  const { getValues, setValue } = useFormContext();
  const { validFromDate, expirationDate } = getValues('courseOptions');
  const [isValidFromChecked, setIsValidFromChecked] = useState(!!validFromDate);
  const [isExpiresOnChecked, setIsExpiresOnChecked] = useState(!!expirationDate);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      {/* Valid from block */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <MLCheckbox
          uncontrolled
          name='validFromCheck'
          label="Valid from"
          checked={isValidFromChecked}
          onChange={(_, checked) => {
            setIsValidFromChecked(!!checked);
            if (checked) setValue('courseOptions.validFromDate', new Date(), { shouldDirty: true });
            else setValue('courseOptions.validFromDate', null, { shouldDirty: true });
          }}
        />
        <MLDatePicker
          name='courseOptions.validFromDate'
          disabled={!isValidFromChecked}
          width='25rem'
        />
      </div>

      {/* Expires on block */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <MLCheckbox
          uncontrolled
          name='expiresOnCheck'
          label="Expires on"
          checked={isExpiresOnChecked}
          onChange={(_, checked) => {
            setIsExpiresOnChecked(!!checked);
            if (checked) setValue('courseOptions.expirationDate', new Date(), { shouldDirty: true });
            else setValue('courseOptions.expirationDate', null, { shouldDirty: true });
          }}
        />
        <MLDatePicker
          name='courseOptions.expirationDate'
          disabled={!isExpiresOnChecked}
          width='25rem'
        />
      </div>
    </div>
  );
};

export default Dates;
