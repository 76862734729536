import React from "react";
import { getClassNames } from "./MLNoPermission.classNames";

interface IMLNoPermissionProps {
  message: string;
}

const MLNoPermission: React.FC<IMLNoPermissionProps> = ({ message }) => {
  const classes = getClassNames();

  return (
    <div className={classes.noPermissionContainer}>
      <div className={classes.noPermissionMessageContainer}>
        <p className={classes.noPermissionMessage}>{message}</p>
      </div>
    </div>
  );
};

export default MLNoPermission;