import { Spinner, SpinnerSize } from "@fluentui/react";
import { getClassNames } from "./MLLoading.classNames";
import { useContext, useRef } from "react";
import { ANIMATION_DURATION } from "utils/constants/styles";
import { CSSTransition } from "react-transition-group";
import { LoadingContext } from "contexts/loading-context";


const MLLoading: React.FC = () => {
  const { isLoading, label } = useContext(LoadingContext);

  const loadingBackgroundRef = useRef<HTMLDivElement>(null);
  const spinnerContainerRef = useRef<HTMLDivElement>(null);

  const classes = getClassNames();

  return (
    <CSSTransition
      nodeRef={loadingBackgroundRef}
      in={isLoading}
      timeout={ANIMATION_DURATION}
      classNames={{
        enter: classes.loadingBackgroundEnter,
        enterActive: classes.loadingBackgroundEnterActive,
        exit: classes.loadingBackgroundExit,
        exitActive: classes.loadingBackgroundExitActive,
      }}
      unmountOnExit
    >
      <div ref={loadingBackgroundRef} className={classes.loadingBackground}>
      <CSSTransition
          nodeRef={spinnerContainerRef}
          in={isLoading}
          timeout={ANIMATION_DURATION}
          classNames={{
            appear: classes.spinnerContainerEnter,
            appearActive: classes.spinnerContainerEnterActive,
            exit: classes.spinnerContainerExit,
            exitActive: classes.spinnerContainerExitActive,
          }}
          appear
          unmountOnExit
        >
          <div ref={spinnerContainerRef} className={classes.spinnerContainer}>
            <Spinner
              styles={{
                label: {
                  fontWeight: 400,
                  marginTop: '5rem',
                  fontSize: '1.2rem',
                },
                circle: {
                  width: '5rem',
                  height: '5rem',
                  borderWidth: '0.2rem',
                },
              }}
              label={label}
              size={SpinnerSize.large}
            />
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default MLLoading;