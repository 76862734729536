// import react
import { useState } from "react";

// import styles
import { getClassNames } from "./Create.classNames";


// import utils
import { Logger } from "utils/logger";

// import components
import MLCard from "common/ml-components/MLCard";

// import components
import UploadDocumentIcon from "assets/ml-icons/Others/UploadDocument/upload-document";
import CreateFromScratchIcon from "assets/ml-icons/Others/CreateFromScratch/create-from-scratch";
import CreateFromScratch from "./components/CreateFromScratch/CreateFromScratch";
import UploadDocument from "./components/UploadDocument/UploadDocument";

const Create = () => {
  Logger.render("Create");
  const [selectedModal, setSelectedModal] = useState<string>('');

  const classes = getClassNames();

  return (
    <section className={classes.createPage}> 
      <MLCard
        Icon={<UploadDocumentIcon />}
        action={() => setSelectedModal('uploadDocument')}
        buttonLabel={"Upload a document"}
        description={"Choose a Word, PowerPoint or PDF document to start generating your microlearning course."}
      />

      <MLCard
        Icon={<CreateFromScratchIcon />}
        action={() => setSelectedModal('createFromScratch')}
        buttonLabel={"Create from scratch"}
        description={"Start creating your microlearning training, following our templates and with the help of AI."}
      />

      <CreateFromScratch
        show={selectedModal === 'createFromScratch'}
        onDismiss={() => setSelectedModal('')}
      />

      <UploadDocument
        show={selectedModal === 'uploadDocument'}
        onDismiss={() => setSelectedModal('')}
      />
    </section>
  )
};

export default Create;