import { mergeStyleSets, keyframes } from "@fluentui/react";

interface IHeaderClassNames {
  header: string;
  headerFadeIn: string;
}

export const getClassNames = (): IHeaderClassNames => {
  const fadeIn = keyframes({
    '0%': {
      opacity: 0,
      // transform: 'scale(0.9)',
    },

    '100%': {
      opacity: 1,
      // transform: 'scale(1)',
    },
  });

  return mergeStyleSets({
    header: {
      width: '100%',
      flex: '0 0 8rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '5rem',
      padding: '0 4rem',
    },

    headerFadeIn: {
      overflow: 'hidden',
      animationName: fadeIn,
      animationDuration: '500ms',
    },
  });
};