import { mergeStyleSets } from '@fluentui/react';

interface ITextLessonClassNames{
  textLessonContainer: string;
  body: string;
  audioContainer: string;
}

export const getClassNames = (): ITextLessonClassNames => {
  return mergeStyleSets({
    textLessonContainer: {
      flex: 1,
      paddingBottom: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    body: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 2rem',
    },

    audioContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 2rem',
    }
  });
};