import { mergeStyleSets } from '@fluentui/react';

interface IQuizLessonClassNames{
  quizLessonContainer: string;
}

export const getClassNames = (): IQuizLessonClassNames => {
  return mergeStyleSets({
    quizLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 2rem',
    },
  });
};