import MLTextField from "common/ml-components/inputs/MLTextField";
import { getClassNames } from "./Frequency.classNames";

const Frequency = () => {
  const classes = getClassNames();

  return (
    <div className={classes.frequencyContainer}>
      <MLTextField
        name="frequency"
        label="Frequency"
        formRequired
        suffix="days"
      />
    </div>
  )
};

export default Frequency;