import React, { PropsWithChildren } from 'react';
import { DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IContextualMenuProps, getTheme } from '@fluentui/react';
import { BORDER_RADIUS } from 'utils/constants/styles';

interface IMLContextualMenuProps {
  text: string;
  menuProps: IContextualMenuProps
}

const ContextualMenu: React.FC<PropsWithChildren<IMLContextualMenuProps>> = (
  { text, menuProps, children }
) => {

  const theme = getTheme();

  const styles: IButtonStyles = {
    root: {
      padding: '0 2.5rem',
      borderRadius: BORDER_RADIUS,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.neutralQuaternary}`,
    },

    rootHovered: {
      backgroundColor: theme.palette.white,
    },

    menuIcon: {
      marginLeft: 10,
      marginTop: 2,
      marginRight: -4
    },
  }

  return (
    <DefaultButton
      styles={styles}
      menuProps={menuProps}
    >
      {text && <span>{text}</span>}
      {children}
    </DefaultButton>
  );
};

export default ContextualMenu;
