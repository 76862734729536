import { useMemo } from 'react';
import { getClassNames } from './CourseDetails.classNames';

// import redux
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

// import FluentUI
import { IDropdownOption } from '@fluentui/react';

// import helpers
import { getLanguageFromCode, sortString } from 'utils/helpers/helpers';
import MLDropdown, { DropdownStyles } from 'common/ml-components/inputs/MLDropdown';

const CourseDetails: React.FC =  () => {
    const categories = useSelector((state: RootState) => state.settings.categories);
    const supportedLanguages = useSelector((state: RootState) => state.settings.settings?.supportedLanguages);

    const categoriesByLanguage: IDropdownOption[] = useMemo(() => {
      const transformedData = categories.filter(item => !item.archived)  // Filter out archived items
        .map(item => {
          const itTranslation = item.categoryTranslations?.find(translation => translation.language === "it-IT");
          return { key: item.id || '', text: itTranslation?.value || '', };
      });

      return transformedData || [];

    }, [categories]);
    
    const languages = useMemo((): IDropdownOption[] => {
      if (!supportedLanguages) return [];
      
      return supportedLanguages.map((language: string): IDropdownOption => ({
        key: language,
        text: getLanguageFromCode(language),
      })).sort((a: IDropdownOption, b: IDropdownOption) => sortString(a.text, b.text));
    }, [supportedLanguages]);

    const classes = getClassNames();

    return (
      <div className={classes.courseDetailsContainer}>
        <MLDropdown
          name='categoryId'
          label='Category'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={categoriesByLanguage}
        />
        <MLDropdown
          name='documentLanguage'
          label='Default language'
          dropdownStyle={DropdownStyles.DARK}
          formRequired
          options={languages}
        />
      </div>
    );
  };

export default CourseDetails;