import { mergeStyleSets } from '@fluentui/react';

interface ICourseDetailsClassNames {
  courseDetailsContainer: string;
}

export const getClassNames = (): ICourseDetailsClassNames => {
  return mergeStyleSets({
    courseDetailsContainer: {
      display: 'flex',
      gap: '2rem',
    },
  });
};