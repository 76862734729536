import MLNoPermission from "common/ml-components/MLNoPermission";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface IProtectedRouteProps extends PropsWithChildren {};

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ children }) => {
  const roles = useSelector((state: RootState) => state.account.roles);

  return (
    <>
      {roles?.includes("Admin") || roles?.includes("Manager") ? children : <MLNoPermission message="You don't have permission to access this page" />}
    </>
  );
};

export default ProtectedRoute;