import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { getClassNames } from './MLForm.classNames';
import { useForm, FormProvider, SubmitHandler, FieldValues, DefaultValues } from 'react-hook-form';
import { Icon } from '@fluentui/react';
import ActionButtons from './components/ActionButtons/ActionButtons';
import MLDialog from 'common/ml-components/MLDialog';

interface IMLFormProps<T extends FieldValues> extends PropsWithChildren {
  defaultValues?: DefaultValues<T>;
  onSubmit: SubmitHandler<T>;
  onDismiss: () => void;
  title?: string;
  submitButtonLabel?: string;
  compareData?: (data: T) => boolean;
  isTabForm?: boolean;
  gap?: string;
  noActionButtons?: boolean;
  noPaddingRight?: boolean;
  overflowHidden?: boolean;
  isTransitioning?: boolean;
}

const MLForm = <T extends FieldValues>({
  children,
  defaultValues,
  onSubmit,
  onDismiss,
  title,
  submitButtonLabel,
  compareData,
  isTabForm,
  gap,
  noActionButtons,
  noPaddingRight,
  overflowHidden,
  isTransitioning,
}: IMLFormProps<T>) => {
  const [isDataChanged, setIsDataChanged] = useState(!compareData);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const formContainerRef = useRef<HTMLDivElement>(null);

  const methods = useForm<T>({ defaultValues });
  const { formState: { dirtyFields }, watch } = methods;

  const onCancel = () => {
    if (!compareData) return onDismiss();
    if (isDataChanged) setShowDialog(true);
    else onDismiss();
  };

  const data = watch();
  useEffect(() => {
    // Check if any of the form values have changed
    if (!compareData) return;

    const hasChanged = Object.keys(dirtyFields).length > 0;
    if (hasChanged) {
      setIsDataChanged(compareData(data));
    } else setIsDataChanged(false);
  }, [dirtyFields, compareData, data]);

  if (isTransitioning !== undefined) {
    if (isTransitioning) {
      if (formContainerRef.current) {
        const container = formContainerRef.current;
        if (Number(container.clientHeight.toFixed(0)) >= Number(container.scrollHeight.toFixed(0))) {
          container.style.overflow = 'hidden';
        }
      }
    } else {
      if (formContainerRef.current) {
        const container = formContainerRef.current;
        container.style.overflow = 'auto';
      }
    }
  }

  const classes = getClassNames(isTabForm, noPaddingRight, gap, overflowHidden);

  return (
    <FormProvider {...methods}>
      <form className={classes.form} onSubmit={methods.handleSubmit(onSubmit)}>
        {title && (
          <div className={classes.header}>
            <h1 className={classes.title}>{title}</h1>
            <Icon className={classes.closeIcon} iconName="ChromeClose" onClick={onCancel} />
          </div>
        )}
        <div
          ref={formContainerRef}
          className={classes.formContainer}
        >
          {children}
        </div>
        {!noActionButtons && <ActionButtons
          onDismiss={onCancel}
          submitButtonLabel={submitButtonLabel}
          isSaveEnabled={isDataChanged}
          isTabForm={isTabForm}
        />}
        <MLDialog
          show={showDialog}
          onConfirm={() => {
            setShowDialog(false);
            onDismiss();
          }}
          onCancel={() => setShowDialog(false)}
          title='Unsaved changes'
          text='You have unsaved changes. Are you sure you want to cancel and lose all changes?'
        />
      </form>
    </FormProvider>
  );
};

export default MLForm;