import { mergeStyleSets } from '@fluentui/react'

interface IBadgesClassNames {
  badgesContainer: string;
}

export const getClassNames = (): IBadgesClassNames => {
  return mergeStyleSets({
    badgesContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
  })
}