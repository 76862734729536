import React, { useEffect, useState } from 'react';
import { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form';
import { IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, Pivot, PivotItem, getTheme } from '@fluentui/react';
import { ANIMATION_DURATION, MODAL_SECTION_TITLE_FONT_SIZE } from 'utils/constants/styles';
import { getClassNames } from './MLTabForm.classNames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import MLForm from '../MLForm/MLForm';

export interface IMLTab {
  key: string;
  label: string;
  component: React.ReactElement;
  ref: React.RefObject<HTMLDivElement>;
  height: number | 'auto';
}

interface IMLTabFormProps<T extends FieldValues> {
  title: string;
  defaultValues: DefaultValues<T>;
  tabs: IMLTab[];
  onSubmit: SubmitHandler<T>;
  onDismiss: () => void;
  compareData?: (data: T) => boolean;
  isTransitioning?: boolean;
}

const MLTabForm = <T extends FieldValues>({ title, defaultValues, tabs, onSubmit, onDismiss, compareData, isTransitioning }: IMLTabFormProps<T>) => {
  const [activeKey, setActiveKey] = useState(tabs[0]?.key);
  const [height, setHeight] = useState<number | string>('auto');
  const [overflow, setOverflow] = useState<'hidden' | 'auto'>('hidden');
  
  const theme = getTheme();

  const currentTab = tabs.find(tab => tab.key === activeKey);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setActiveKey(item.props.itemKey!);
    }
  };

  const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    root: { 
      marginLeft: -8,
    },
    link: {
      height: '3rem',
      fontSize: MODAL_SECTION_TITLE_FONT_SIZE,
      color: theme.palette.black,

      ':hover': {
        backgroundColor: 'transparent',
      },
      ':active': {
        backgroundColor: 'transparent',
      }
    },
  };

  if (isTransitioning !== undefined) {
    if (isTransitioning) {
      if (currentTab?.ref.current) {
        const container = currentTab?.ref.current;
        if (Number(container.clientHeight.toFixed(0)) >= Number(container.scrollHeight.toFixed(0))) {
          container.style.overflow = 'hidden';
        }
      }
    } else {
      if (currentTab?.ref.current) {
        const container = currentTab?.ref.current;
        container.style.overflow = 'auto';
      }
    }
  }

  useEffect(() => {
    if (currentTab?.ref.current) {
      const tabContentHeight = currentTab.height;
      setHeight(tabContentHeight);
      setOverflow('hidden');

      const timeoutId = setTimeout(() => {
        setHeight('auto');
        setOverflow('auto');
      }, ANIMATION_DURATION);

      return () => {
        if (currentTab?.ref.current) {
          clearTimeout(timeoutId);
          const tabContentHeight = currentTab.ref.current.scrollHeight;
          setHeight(tabContentHeight);
        }
      }
    }
  }, [currentTab]);

  const classes = getClassNames();

  return (
    <div className={classes.tabFormContainer}>
      <MLForm
        title={title}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
        isTabForm
        isTransitioning={isTransitioning}
      >
        <Pivot styles={pivotStyles} onLinkClick={handleLinkClick}>
          {tabs.map(tab => (
            <PivotItem key={tab.key} itemKey={tab.key} headerText={tab.label} />
          ))}
        </Pivot>
        <SwitchTransition>
          <CSSTransition
            key={activeKey}
            nodeRef={currentTab?.ref}
            timeout={ANIMATION_DURATION}
            classNames={{
              enter: classes.contentEnter,
              enterActive: classes.contentEnterActive,
              exit: classes.contentExit,
              exitActive: classes.contentExitActive,
            }}
          >
            <div
              ref={currentTab?.ref}
              className={classes.tabComponentContainer}
              style={{ height, overflow }}
            >
              {currentTab?.component}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </MLForm>
    </div>
  );
};

export default MLTabForm;