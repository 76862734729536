import { mergeStyleSets } from '@fluentui/react';
import { ANIMATION_DURATION, BORDER_RADIUS } from 'utils/constants/styles';

interface IMLLoadingRedirectClassNames {
  loadingBackground: string;
  spinnerContainer: string;

  loadingBackgroundEnter: string;
  loadingBackgroundEnterActive: string;
  loadingBackgroundExit: string;
  loadingBackgroundExitActive: string;

  spinnerContainerEnter: string;
  spinnerContainerEnterActive: string;
  spinnerContainerExit: string;
  spinnerContainerExitActive: string;
}

export const getClassNames = (): IMLLoadingRedirectClassNames => {
  return mergeStyleSets({
    loadingBackground: {
      zIndex: 10,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '5rem',
      borderRadius: BORDER_RADIUS,
      boxShadow: '0px 2px 20px 2px rgba(0, 0, 0, 0.2)',
    },

    loadingBackgroundEnter: {
      opacity: 0,
    },

    loadingBackgroundEnterActive: {
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    loadingBackgroundExit: {
      opacity: 1,
    },

    loadingBackgroundExitActive: {
      opacity: 0,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    spinnerContainerEnter: {
      opacity: 0,
      transform: 'scale(0.9)',
    },

    spinnerContainerEnterActive: {
      transform: 'scale(1)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    spinnerContainerExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    spinnerContainerExitActive: {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: `all ${ANIMATION_DURATION}ms`,
    }
  })
}