import { mergeStyleSets, keyframes } from '@fluentui/react';
import { IFadeClassNames } from 'utils/constants/styles';
import { FADE } from 'utils/constants/styles';

interface ICreatePageClassNames extends IFadeClassNames {
  createPage: string;
}

export const getClassNames = (): ICreatePageClassNames => {
  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    createPage: {
      width: '100%',
      height: '100vh',
      // background: getTheme().palette.themeLighter,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 100, // 50 with 3 cards

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    ...FADE.classNames,
  });
};