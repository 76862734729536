// react
import { PropsWithChildren, useEffect } from "react";

// redux
import { RootState, useAppDispatch } from "store/store";
import { setClients } from "store/slices/clients";
import { setSettings, setTimeZones } from "store/slices/settings";
import { useSelector } from "react-redux";

// models
import { ApiStatus } from "common/models/store/api-status";

export default function Application({children}: PropsWithChildren) {
  const apiStatus = useSelector((state: RootState) => state.clients.apiStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setClients()).unwrap().then(_ => {
      dispatch(setSettings());
      dispatch(setTimeZones());
    });
  }, [dispatch]);

  return (
    <>{apiStatus.api === ApiStatus.SUCCESS && children}</>
  )
}