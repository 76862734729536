// import react
import { useContext } from "react";
import Body from "./Body/body";
import { getClassNames } from "./lesson.classNames";
import Header from "./Header/header";
import Footer from "./Footer/footer";
import AudioPlayer from "./AudioPlayer/audio-player";
import { LessonDto, LessonTypeDto } from "../../../../../../api-client";
import { useAppDispatch } from "../../../../../../store/store";
import { setLessonToDelete, setLessonToEdit } from "../../../../../../store/slices/courses";
import { InfoContext } from "../../../../../../contexts/info-context";

import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface ILessonProps {
  lesson: LessonDto;
  index: number;
  onPlayAudioLessonId: string;
  setAudioLessonId: (id: string) => void;
  onEditLessonClick: () => void;
  onDeleteClick: () => void;
  disabled: boolean;
}

const Lesson = (props: ILessonProps) => {
  const { info: { modifiedLessons } } = useContext(InfoContext);
  const dispatch = useAppDispatch();

  const animateLayoutChanges = (args: any) => {
    const {isSorting, wasDragging } = args;
  
    if (isSorting || wasDragging ) {
      return defaultAnimateLayoutChanges(args);
    }
  
    return true;
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ animateLayoutChanges, id: props.lesson.id || '', disabled: props.disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "1" : "auto",
    opacity: isDragging ? 0.2 : 1,
  };

  const classes = getClassNames(props.disabled);

  return (
    <div
      className={classes.lessonContainer}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={classes.test}>
        <Header
          title={props.lesson.title || ''}
          lastModified={props.lesson.modified || ''}
          isModified={(props.lesson.id && modifiedLessons.includes(props.lesson.id)) || false}
          index={props.index}
        />
        <div className={classes.body}>
          <Body
            lesson={props.lesson}
            onPlayAudioLessonId={props.onPlayAudioLessonId}
            setAudioLessonId={props.setAudioLessonId}
          />
          <hr className={classes.hr} />
          {(props.lesson.type !== LessonTypeDto.Quiz &&
            props.lesson.type !== LessonTypeDto.FlashCard &&
            props.lesson.type !== LessonTypeDto.AudioOnly) && (
            <>
              <AudioPlayer
                lesson={props.lesson}
                onPlayAudioLessonId={props.onPlayAudioLessonId}
                setAudioLessonId={props.setAudioLessonId}
              />
              <hr className={classes.hr} />
            </>
          )}
          <Footer
            onEditLessonClick={() => { 
              dispatch(setLessonToEdit(props.lesson));
              props.onEditLessonClick();
            }}
            onDeleteClick={() => {
              dispatch(setLessonToDelete(props.lesson));
              props.onDeleteClick();
            }}
          />
        </div>
      </div>      
    </div>
  )
}

export default Lesson;