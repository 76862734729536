
import { createRef, useEffect, useRef, useState } from 'react';

// import components
import MLHorizontalDivider from 'common/ml-components/MLHorizontalDivider';

// import types
import { getClassNames } from './Domains.classNames';
import { useFormContext } from 'react-hook-form';
import MLCollection from 'common/ml-components/MLList/MLCollection/MLCollection';
import MLNewItem from 'common/ml-components/MLList/MLNewItem/MLNewItem';
import { v4 } from 'uuid';

interface IDomainsProps {
  updateHeight: (height: number) => void;
}

const Domains = ({ updateHeight }: IDomainsProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { getValues, setValue } = useFormContext();

  const fields = getValues('domains.collection');
  const [domains, setDomains] = 
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const onAdd = (value: string) => {
    const newItem = { id: v4(), value };
    setDomains([{ ...newItem, ref: createRef<HTMLDivElement>() }, ...domains]);
    setValue('domains.collection', [{ ...newItem }, ...domains], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...domains];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setDomains(newItems);
    setValue('domains.collection', newItems, { shouldDirty: true });
  };

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.domainsContainer}>
      <MLCollection
        items={domains}
        setItems={setDomains}
        title='Domains'
        onDelete={onDelete}
        maxHeight
        nonEditable
        prefix='https://'
      />
      <MLHorizontalDivider />
      <MLNewItem
        items={domains}
        setItems={setDomains}
        title='New domain'
        onAdd={onAdd}
        noMargin
        paddingRight
        prefix='https://'
      />
    </div>
  );
};

export default Domains;