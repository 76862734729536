import { mergeStyleSets, getTheme } from '@fluentui/react';

interface IMLPeoplePickerClassNames {
peoplePickerContainer: string;
label: string;
}

export const getClassNames = (): IMLPeoplePickerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    peoplePickerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },

    label: {
      fontWeight: '600',
      fontSize: '1.2rem',
      color: theme.palette.neutralSecondaryAlt,
    }
  });
};