import { mergeStyleSets } from '@fluentui/react';

interface ICourseDetailsClassNames {
  courseDetailsContainer: string;
}

export const getClassNames = (): ICourseDetailsClassNames => {
  return mergeStyleSets({
    courseDetailsContainer: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto auto',
      gap: '2rem',
    },
  });
};