//import styles
import { getClassNames } from './MLCard.classNames';

// import components
import { Spinner, SpinnerSize, Text } from '@fluentui/react';
import PrimaryButton from '../buttons/MLPrimaryButton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ApiStatus } from 'common/models/store/api-status';

interface ICardProps {
  Icon: JSX.Element;
  buttonLabel: string;
  description: string;
  action: () => void;
}

const MLCard: React.FC<ICardProps> = ({ Icon, buttonLabel, description, action }) => {
  const apiStatus = useSelector((state: RootState) => state.settings.apiStatus);
  const classes = getClassNames();

  return (
    <div className={classes.card}>
      <div className={classes.cardTop}>
        <div className={classes.iconContainer}>
          {Icon}
        </div>
      </div>
      <div className={classes.cardBottom}>
        <PrimaryButton
          text={apiStatus !== ApiStatus.LOADING ? buttonLabel : ''}
          icon={apiStatus === ApiStatus.LOADING ? <Spinner size={SpinnerSize.small} /> : undefined}
          disabled={apiStatus === ApiStatus.LOADING}
          type='button'
          onClick={action}
          width100
          height='4rem'
          fontSize='1.5rem'
        />
        <hr className={classes.divider} />
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  )
}

export default MLCard;