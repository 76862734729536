import { useEffect, useState, useCallback } from "react";
import { getClassNames } from "./list-item.classNames";
import { getTheme, Persona, PersonaSize } from "@fluentui/react";
import { StatusNames } from "common/models/Learnings/status";
import { Status } from "utils/constants/status";
import { CourseStatusDto, IdentityTypeDto } from "../../../../../../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../store/store";
import PrimaryButton from "common/ml-components/buttons/MLPrimaryButton";
import Delete from "assets/ml-icons/Buttons/Delete/delete";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton";
import Edit from "assets/ml-icons/Buttons/Edit/edit";
import Send from "assets/ml-icons/Buttons/Send/Send";
import Stop from "assets/ml-icons/Buttons/Stop/Stop";

export interface IListItemProps {
  item: any;
  courseStatus: CourseStatusDto | undefined;
  itemIndex: number;
  columnKey: string; 
  statusInfo: StatusNames;
  onDeleteClick: (item: any) => void;
  editListItem: () => void;
  onSendScheduleClick: (item: any) => void;
  onStopClick: (item: any) => void;
}

const ListItem = (props: IListItemProps) => {
  const [profilePicture, setProfilePicture] = useState<string>('');
  const usersClientApi = useSelector((state: RootState) => state.clients.clients.usersClientApi);
  const classes = getClassNames(props);
  const theme = getTheme();

  const getProfilePicture = useCallback(async function(userId: string): Promise<void> {
    try {
      if (!usersClientApi) return;

      const response = await usersClientApi.apiTenantUsersUpnOrIdPhotoGet(userId);
      setProfilePicture(`data:image/png;base64,${response.data}`);
    } catch (err) {
      console.error(err);
    }
  }, [usersClientApi]);

  const getGroupPicture = useCallback(async function(groupId: string): Promise<void> {
    try {
      if (!usersClientApi) return;

      const response = await usersClientApi.apiTenantGroupsUpnOrIdPhotoGet(groupId);
      setProfilePicture(`data:image/png;base64,${response.data}`);
    } catch (err) {
      console.error(err);
    }
  }, [usersClientApi]);

  const renderItem = () => {
    if (props.columnKey === '') {
      return (
        <div className={classes.buttonsColumn}>
          {props.statusInfo === StatusNames.Draft && (
            <>
            <PrimaryButton 
              type="button"
              width="3.8rem"
              icon={<Send colored color={props.courseStatus !== CourseStatusDto.Published && props.courseStatus !== CourseStatusDto.Running ? theme.palette.neutralTertiary : 'white'} />}
              onClick={() => props.onSendScheduleClick(props.item)}
              disabled={props.courseStatus !== CourseStatusDto.Published && props.courseStatus !== CourseStatusDto.Running}
            />
            <DefaultButton
              icon={<Edit />}
              onClick={props.editListItem}
             />
              <DefaultButton
                colored
                icon={<Delete colored />}
                onClick={() => props.onDeleteClick(props.item)}
              />
          </>
          )}
          {props.statusInfo === StatusNames.Running && (
            <DefaultButton
              colored 
              icon={<Stop colored />}
              onClick={() => props.onStopClick(props.item)}
            />
          )}
        </div>
      )
    };
    if (props.columnKey === 'Recipients') {
      return <div className={classes['columns']}>
        <Persona
          size={PersonaSize.size24}
          text={props.item[props.columnKey].displayName}
          imageUrl={profilePicture}
       />
        </div>;
    };
    if (props.columnKey === 'Status') return (
      <div className={classes.statusContainer}>
        {Status[props.statusInfo] && Status[props.statusInfo].icon()}
        <span className={classes.statusSpanLabel}>{props.statusInfo}</span>
      </div>
    );

    return <div className={classes.columns}>{props.item[props.columnKey]}</div>;
  };

  useEffect(() => {
    if (props.columnKey === 'Recipients') {
      if (props.item[props.columnKey].type === IdentityTypeDto.User)
        getProfilePicture(props.item[props.columnKey].id);
      else
        getGroupPicture(props.item[props.columnKey].id);
    };
  }, [getProfilePicture, getGroupPicture, props.columnKey, props.item]);

  return renderItem()
}

export default ListItem;