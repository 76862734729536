import QuestionList from "./components/QuestionList/QuestionList";
import { getClassNames } from "./Quiz.classNames";
import SelectedQuestion from "./components/SelectedQuestion/SelectedQuestion";
import MLVerticalDivider from "common/ml-components/MLVerticalDivider";
import { FinalQuizLessonDto } from "api-client";

interface IQuizProps {
  onDismiss: () => void;
  selectedQuestionIndex: number;
  onChangeQuestion: (index: number) => void;
  compareData?: (data: FinalQuizLessonDto) => boolean;
}

const Quiz: React.FC<IQuizProps> = ({ selectedQuestionIndex, onChangeQuestion, onDismiss, compareData }) => {
  const classes = getClassNames();

  return (
    <div className={classes.quizContainer}>
      <QuestionList
        selectedQuestionIndex={selectedQuestionIndex}
        onChangeQuestion={onChangeQuestion}
      />
      <MLVerticalDivider />
      <SelectedQuestion
        onDismiss={onDismiss}
        selectedQuestionIndex={selectedQuestionIndex}
        compareData={compareData}
      />
    </div>
  );
};

export default Quiz;