import { useState } from "react";
import InfoOutline from "assets/ml-icons/Others/Info/InfoOutline/InfoOutline";
import { getClassNames } from "./header.classNames";
import { DateTime } from "luxon";
import { TooltipHost } from "@fluentui/react";
import InfoSolid from "assets/ml-icons/Others/Info/InfoSolid/InfoSolid";

interface IHeaderProps {
  title: string;
  lastModified: string;
  isModified: boolean;
  index: number;
}

const Header = (props: IHeaderProps) => {
  const [modifiedLabel, setModifiedLabel] = useState<string>();
  const classes = getClassNames();

  const getLastModified = () => {
    if (DateTime.fromISO(props.lastModified).diffNow('days').days > -1) {
      setModifiedLabel(`Last modified: ${DateTime.fromISO(props.lastModified).setLocale('en').toRelative({style: 'short'})}`);
    } else {
      setModifiedLabel(`Last modified: ${DateTime.fromISO(props.lastModified).toLocaleString(DateTime.DATETIME_MED)}`);
    }
  }

  return (
    <div className={classes.headerContainer}>
      <h3 className={classes.header}>
        {`${props.index + 1}. ${props.title}`}
      </h3>
      <div className={classes.infoIconContainer} onMouseEnter={() => getLastModified()}>
        <TooltipHost
          content={modifiedLabel}
          calloutProps={{gapSpace: 5,}}
        >
          {props.isModified ? <InfoSolid /> : <InfoOutline />}
        </TooltipHost>
      </div>
    </div>
  )
}

export default Header;