// import components
import MLTextField from "common/ml-components/inputs/MLTextField";

interface ITextLessonProps {
  lessonTypeChanged: boolean;
}

const TextLesson: React.FC<ITextLessonProps> = ({ lessonTypeChanged }) => {
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.body"
        label="Body"
        multiline
        disabled={lessonTypeChanged}
        autoAdjustHeight
        resizable={false}
        formRequired
      />
    </>
  );
};

export default TextLesson;
