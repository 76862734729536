import { mergeStyleSets } from "@fluentui/react"

interface IEditLessonClassNames {
  editLessonContainer: string;
}

export const getClassNames = (): IEditLessonClassNames => {
  return mergeStyleSets({
    editLessonContainer: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      gap: '2rem',

      height: '50rem'
    },
  });
};