import { mergeStyleSets, getTheme, keyframes } from "@fluentui/react";
import { ANIMATION_DURATION } from "utils/constants/styles";

interface ILanguageOptionsNames {
  languagesOptionsContainer: string;
  errorContainer: string;
  errorLabel: string;
}

export const getClassNames = (): ILanguageOptionsNames => {
  const theme = getTheme();

  const errorFadeIn = keyframes({
    from: {
      opacity: 0,
      maxHeight: 0,
    }, to: {
      opacity: 1,
      maxHeight: '10rem',
    }
  });
  
  return mergeStyleSets({
    languagesOptionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },

    errorContainer: {
      animation: `${errorFadeIn} ${ANIMATION_DURATION}ms`
    },

    errorLabel: {
      fontSize: theme.fonts.small.fontSize,
      fontWeight: theme.fonts.xLarge.fontWeight,
      color: theme.palette.themePrimary,
      display: 'flex',
      flexDirection: 'column',
    },
  });
};