import { useRef } from "react";
import Pause from "assets/ml-icons/VideoPlayer/Pause/Pause";
import Play from "assets/ml-icons/VideoPlayer/Play/Play";
import { getClassNames } from "./Controls.classNames";
import { CSSTransition } from "react-transition-group";
import Muted from "assets/ml-icons/VideoPlayer/Muted/Muted";
import VolumeUp from "assets/ml-icons/VideoPlayer/VolumeUp/VolumeUp";

interface IControlsProps {
  showControls: boolean;
  togglePlayPause: () => void;
  isPlaying: boolean;
  currentTime: number;
  duration: number;
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  muted: boolean;
  toggleVolume: () => void;
}

export default function Controls({
  showControls,
  togglePlayPause,
  isPlaying,
  currentTime,
  duration,
  handleTimeChange,
  muted,
  toggleVolume
}: IControlsProps) {

  const nodeRef = useRef<HTMLDivElement>(null);
  const classes = getClassNames(currentTime, duration);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      key={'controls'}
      in={showControls}
      timeout={300}
      classNames={{
        enter: classes.fadeEnter,
        enterActive: classes.fadeEnterActive,
        exit: classes.fadeExit,
        exitActive: classes.fadeExitActive,
      }}
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={classes.controlsContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.buttonControl} onClick={togglePlayPause}>
          {isPlaying ? <Pause /> : <Play />}
        </div>
        <div className={classes.muteControl} onClick={toggleVolume}>
          {muted ? <Muted /> : <VolumeUp />}
        </div>
        <div className={classes.progressBar}>
          <input
            type="range"
            min="0"
            max={duration}
            step={0.1}
            value={currentTime}
            onChange={handleTimeChange}
          />
        </div>
        <div className={classes.progressTime}>
          {Math.floor(currentTime / 60)}:
          {Math.floor(currentTime % 60).toString().padStart(2, '0')} / 
          {Math.floor(duration / 60)}:
          {Math.floor(duration % 60).toString().padStart(2, '0')}
        </div>
      </div>
    </CSSTransition>
  )
}


// import { ChangeEventHandler, useRef } from "react";
// import { CSSTransition } from "react-transition-group";
// import { getClassNames } from "./Controls.classNames";
// import { Icon } from '@fluentui/react';

// interface IControlsProps {
//   videoRef: React.RefObject<HTMLVideoElement>;
//   currentTime: number;
//   duration: number;
//   updateCurrentTime: (time: number) => void;
//   muted: boolean;
//   updateVolume: () => void;
//   showControls: boolean;
//   onPlayClick: () => void;
//   onPauseClick: () => void;
// }


// export default function Controls({
//   videoRef,
//   currentTime,
//   duration,
//   updateCurrentTime,
//   muted,
//   updateVolume,
//   showControls,
//   onPlayClick,
//   onPauseClick }: IControlsProps) {
  
//   const classes = getClassNames(currentTime, duration || 0);

//   const nodeRef = useRef<HTMLDivElement>(null);

//   const handleTimeChange: ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const time = parseFloat(e.target.value);
//     updateCurrentTime(time);
//   };

//   const timeProgress = currentTime || 0;

//   const getFormattedTime = () => {
//     if (!duration) return '00:00 / 00:00';

//     const timeProgressMinutes = 
//       Math.floor(timeProgress / 60) > 0 && Math.floor(timeProgress / 60) < 10 
//       ? `0${Math.floor(timeProgress / 60)}` 
//       : `0${Math.floor(timeProgress / 60)}`;

//     const timeProgressSeconds = 
//       +(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0) > 9 
//       ? `${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}` 
//       : `0${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}`;

//     const timeDurationMinutes = Math.floor(duration / 60) > 0 && Math.floor(timeProgress / 60) < 10 
//     ? `0${Math.floor(duration / 60)}` 
//     : `0${Math.floor(duration / 60)}`;

//     const timeDurationSeconds = 
//       (duration - Math.floor(duration / 60) * 60) > 9 
//       ? `${(duration - Math.floor(duration / 60) * 60).toFixed(0)}` 
//       : `0${(duration - Math.floor(duration / 60) * 60).toFixed(0)}`;
    
//     return `${timeProgressMinutes}:${timeProgressSeconds} / ${timeDurationMinutes}:${timeDurationSeconds}`;
//   };

//   const getDurationStatus = () => {
//     if (duration === null) return '00:00 / 00:00';
//     else return getFormattedTime();
//   };

//   // const onVolumeClick = () => {
//   //   if (videoRef && videoRef.current) videoRef.current.muted = !videoRef.current.muted;
//   // };

//   return (
//     <CSSTransition
//       noderef={nodeRef}
//       key={'controls'}
//       in={showControls}
//       timeout={300}
//       classNames={{
//         enter: classes.fadeEnter,
//         enterActive: classes.fadeEnterActive,
//         exit: classes.fadeExit,
//         exitActive: classes.fadeExitActive,
//       }}
//       unmountOnExit
//     >
//       <div ref={nodeRef} className={classes.controlsContainer}>
//         <div className={classes.buttonsContainer} onClick={(e) => e.stopPropagation()}>
//           {videoRef.current && videoRef.current.paused === true && (
//             // <div className={classes.buttonControl} onClick={onPlayClick}><Play /></div>
//             <Icon iconName="Play" className={classes.buttonControl} onClick={onPlayClick} />
//           )}
//           {videoRef.current && videoRef.current.paused === false && (
//             // <div className={classes.buttonControl} onClick={onPauseClick}><Pause /></div>
//             <Icon iconName="Pause" className={classes.buttonControl} onClick={onPauseClick} />
//           )}
//           {videoRef.current === null && ( 
//             <div className={classes.buttonControl}><div className={classes.loader} /> </div>
//           )}
//           {videoRef && videoRef.current && videoRef.current.muted ? (
//             <Icon iconName="Volume0" className={classes.volume} onClick={updateVolume} />
//             ) : (
//             <Icon iconName="Volume3" className={classes.volume} onClick={updateVolume} />
//           )}
//         </div>
//         <div className={classes.timeBar} onClick={(e) => e.stopPropagation()}>
//           <input
//             type="range"
//             min="0"
//             step={0.1}
//             max={videoRef.current ? videoRef.current.duration : ""}
//             value={currentTime}
//             onChange={handleTimeChange}
//           />
//         </div>
//         <div className={classes.timeProgressContainer}>{getDurationStatus()}</div>
//       </div>
//     </CSSTransition>
//   );
// }