import { mergeStyleSets } from '@fluentui/react'

interface IDomainsClassNames {
  domainsContainer: string;
}

export const getClassNames = (): IDomainsClassNames => {
  return mergeStyleSets({
    domainsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
  })
}