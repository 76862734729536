import React, { useRef } from "react";
import { getClassNames } from './MLDialog.classNames';

import { CSSTransition } from 'react-transition-group';
import ActionButtons from "./components/ActionButtons/ActionButtons";

import { ANIMATION_DURATION } from 'utils/constants/styles';


interface IMLDialogProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text: string;
}

const MLDialog: React.FC<IMLDialogProps> = ({ show, onConfirm, onCancel, title, text }) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const classes = getClassNames();

  return (
    <CSSTransition
      nodeRef={backgroundRef}
      in={show}
      timeout={ANIMATION_DURATION}
      classNames={{
        enter: classes.backgroundEnter,
        enterActive: classes.backgroundEnterActive,
        exit: classes.backgroundExit,
        exitActive: classes.backgroundExitActive,
      }}
      unmountOnExit
    >
      <div ref={backgroundRef} className={classes.background}>
        <CSSTransition
          nodeRef={dialogRef}
          in={show}
          timeout={ANIMATION_DURATION}
          classNames={{
            appear: classes.dialogEnter,
            appearActive: classes.dialogEnterActive,
            exit: classes.dialogExit,
            exitActive: classes.dialogExitActive,
          }}
          unmountOnExit
          appear
        >
          <div ref={dialogRef} className={classes.dialog}>
            <h1 className={classes.title}>{title}</h1>
            <p className={classes.text}>{text}</p>
            <ActionButtons onConfirm={onConfirm} onDismiss={onCancel} />
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default MLDialog;