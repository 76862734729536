import { getClassNames } from "./Quote.classNames";

interface IQuoteProps {
  quote: string;
}

const Quote: React.FC<IQuoteProps> = ({ quote }) => {
  
  const classes = getClassNames();

  return (
    <div className={classes.quoteContainer}>
      <p className={classes.quote}>
        {quote}
      </p>
    </div>
  );
};

export default Quote;