import { useCallback, useState } from 'react';

// import styles
import { getClassNames } from './Learnings.classNames';

// import components
import Header from './components/Header/Header';

// import hooks
import useLearningsPage from './hooks/useLearningsPage';
import { useEffect } from 'react';

// import redux
import { RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { deleteCourseInError, getCourses } from '../../../store/slices/courses';
import DetailsList from './components/DetailsList/DetailsList';
import MLErrorGenerationDialog from 'common/ml-components/MLErrorGenerationDialog';
import useGenerating from './hooks/useGenerating';

const Learnings = () => {
  const {
    onMenuClick,
    search,
    updateSearch
  } = useLearningsPage();

  const [courseErrorId, setCourseErrorId] = useState<string>('');
  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) => state.courses.courses);

  const { courseProgresses } = useGenerating(courses);

  const init = useCallback(async () => {
    try {
      // if (!!courses) return;

      await dispatch(getCourses()).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  const classes = getClassNames();

  console.log('courses', courses);

  return (
    <section className={classes.learningsPage}>
      <Header onMenuClick={onMenuClick} search={search} updateSearch={updateSearch} />
      <DetailsList
        search={search}
        courses={courses}
        onCourseErrorClick={(courseErrorId: string) => setCourseErrorId(courseErrorId)}
        courseProgresses={courseProgresses}
      />
      <MLErrorGenerationDialog
        title='Delete course'
        text='There was an error during the course generation. Would you like to delete it?'
        error={(courseErrorId && courses?.find(el => el.id === courseErrorId)?.errors?.map(el => el.description).join('/n')) || ''}
        show={!!courseErrorId}
        onCancel={() => setCourseErrorId('')}
        onConfirm={async () => {
          try {
            await dispatch(deleteCourseInError({courseIdToDelete: courseErrorId})).unwrap();
            setCourseErrorId('');
          } catch (err) {
            console.error(err);
          }
        }}
      />
    </section>
  )
}

export default Learnings;