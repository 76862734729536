import Add from "assets/ml-icons/Buttons/Add/add";
import { getClassNames } from "./MLNewItem.classNames";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton";
import MLTextField from "common/ml-components/inputs/MLTextField";
import { useState } from "react";
import { IDropdownOption } from "@fluentui/react";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";

interface IMLNewItemProps {
  items: { id: string | number; value: string; secondaryValue?: string; active?: boolean; ref: React.RefObject<HTMLDivElement> }[];
  setItems: React.Dispatch<React.SetStateAction<{
    id: string | number;
    value: string;
    active?: boolean;
    ref: React.RefObject<HTMLDivElement>;
  }[]>>;
  disabled?: boolean;
  noMargin?: boolean;
  idNumber?: boolean;
  title?: string;
  secondaryTitle?: string;
  isSecondaryNumeric?: boolean;
  paddingRight?: boolean;
  options?: IDropdownOption[];
  prefix?: string;
  suffix?: string;
  secondarySuffix?: string;
  onAdd?: (newItem: string, newSecondaryItem?: string) => void;
}

const MLNewItem: React.FC<IMLNewItemProps> = ({
  disabled,
  noMargin,
  title,
  secondaryTitle,
  isSecondaryNumeric,
  paddingRight,
  options,
  prefix,
  suffix,
  secondarySuffix,
  onAdd,
 }) => {
  const [newItem, setNewItem] = useState<string>('');
  const [newSecondaryItem, setNewSecondaryItem] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isSecondaryError, setIsSecondaryError] = useState<boolean>(false);
  const classes = getClassNames(!!noMargin, !!paddingRight);

  return (
    <div className={classes.newItemContainer}>
      <div className={classes.newItem}>
        {options ? (
          <MLDropdown
            name='newItem'
            dropdownStyle={DropdownStyles.DARK}
            options={options}
            selectedKey={newItem}
            onChange={(e, option) => setNewItem(option?.key as string || '')}
            disabled={disabled}
            label={title}
            uncontrolled
            uncontrolledError={isError}
          />
        ) : (
          <MLTextField
            name='newItem'
            label={title}
            value={newItem}
            onChange={(e, newValue) => setNewItem(newValue || '')}
            uncontrolledError={isError}
            multiline
            autoAdjustHeight
            resizable={false}
            disabled={disabled}
            prefix={prefix}
            suffix={suffix}
            uncontrolled
          />
        )}
        {secondaryTitle && (
          <MLTextField
            name='newSecondaryItem'
            label={secondaryTitle}
            value={newSecondaryItem}
            onChange={(e, newValue) => {
              if (isSecondaryNumeric) {
                // Prevent non-numeric characters except for dot, minus, etc.
                const numericPattern = /^-?\d*\.?\d*$/;
                  
                // Only allow valid numeric values
                if (numericPattern.test(newValue || '')) {
                  setNewSecondaryItem(newValue || '');
                }

                return;
              }
              setNewSecondaryItem(newValue || '');
            }}
            uncontrolledError={isSecondaryError}
            multiline
            autoAdjustHeight
            resizable={false}
            disabled={disabled}
            suffix={secondarySuffix}
            uncontrolled
            width={'35%'}
          />
        )}
        <DefaultButton
          colored
          icon={<Add colored disabled={disabled} />}
          disabled={disabled}
          marginBottom
          onClick={() => {
            if (secondaryTitle) {
              if (newItem && newSecondaryItem) {
                if (isError) setIsError(false);
                if (isSecondaryError) setIsSecondaryError(false);
                onAdd && onAdd(newItem, newSecondaryItem);
                setNewItem('');
                setNewSecondaryItem('');
              } else {
                if (!newItem) setIsError(true);
                if (!newSecondaryItem) setIsSecondaryError(true);
              }
            } else {
              if (newItem) {
                if (isError) setIsError(false);
                onAdd && onAdd(newItem);
                setNewItem('');
              } else {
                if (!newItem) setIsError(true);
              }
            }
            
          }}
        />
      </div>
    </div>
  )
}

export default MLNewItem;