import { useSelector } from "react-redux";
import MLDefaultButton from "common/ml-components/buttons/MLDefaultButton";
import MLPrimaryButton from "common/ml-components/buttons/MLPrimaryButton";
import { getClassNames } from "./ActionButtons.classNames";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { ApiStatus } from "common/models/store/api-status";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef } from "react";
import { ANIMATION_DURATION } from "utils/constants/styles";
import { cancelError } from "store/slices/courses";

interface IActionButtonsProps {
  onDismiss: () => void;
  isSaveEnabled?: boolean;
  submitButtonLabel?: string;
  noPadding?: boolean;
  isTabForm?: boolean;
  errorMessage?: string;
}

const ActionButtons: React.FC<IActionButtonsProps> =
  ({ onDismiss, isSaveEnabled, submitButtonLabel, isTabForm, noPadding, errorMessage }) => {
  const settingsApiStatus = useSelector((state: RootState) => state.settings.apiStatus);
  const coursesApiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  const dispatch = useAppDispatch();

  const errorRef = useRef<HTMLParagraphElement>(null);

  const classes = getClassNames(isTabForm, noPadding);

  useEffect(() => {
    return () => {
      dispatch(cancelError());
    }
  }, [dispatch]);

  return (
    <div className={classes.buttonsAndErrorContainer}>
      <div className={classes.errorContainer}>
        <CSSTransition
          nodeRef={errorRef}
          in={coursesApiStatus.status === ApiStatus.ERROR}
          timeout={ANIMATION_DURATION}
          classNames={{
            enter: classes.errorFadeEnter,
            enterActive: classes.errorFadeEnterActive,
            exit: classes.errorFadeExit,
            exitActive: classes.errorFadeExitActive,
          }}
          unmountOnExit
        >
          <p ref={errorRef} className={classes.errorMessage}>{coursesApiStatus.errorMessage}</p>
        </CSSTransition>
      </div>
          
      <div className={classes.actionButtonsContainer}>
        <MLDefaultButton text="Cancel" onClick={() => {
          dispatch(cancelError());
          onDismiss();
        }} />
        <MLPrimaryButton
          text={(settingsApiStatus !== ApiStatus.SAVING && 
            coursesApiStatus.status !== ApiStatus.SAVING) ? 
            submitButtonLabel || "Save" : ''}
          icon={(settingsApiStatus === ApiStatus.SAVING || coursesApiStatus.status === ApiStatus.SAVING) ? 
            <Spinner size={SpinnerSize.small} /> : undefined}
          disabled={!isSaveEnabled}
        />
      </div>
    </div>
    
  );
};

export default ActionButtons;