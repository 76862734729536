import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ANIMATION_DURATION, BORDER_RADIUS } from 'utils/constants/styles';

interface IImageClassNames {
  image: string;

  imageEnter: string;
  imageEnterActive: string;
  imageExit: string
  imageExitActive: string;
}

export const getClassNames = (): IImageClassNames => {
  return mergeStyleSets({
    image: {
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: BORDER_RADIUS,
    },

    imageEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    imageEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    imageExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    imageExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};