// import components
import MLTextField from "common/ml-components/inputs/MLTextField";

interface IAudioLessonProps {}

const AudioLesson: React.FC<IAudioLessonProps> = () => {
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLTextField
        name="lesson.audioSource"
        label="Text"
        multiline
        autoAdjustHeight
        resizable={false}
      />
    </>
  );
};

export default AudioLesson;
