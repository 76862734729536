import { mergeStyleSets } from "@fluentui/react"
import { BORDER_RADIUS } from "utils/constants/styles";

interface IEmbeddedContentClassNames {
  iframeContainer: string;
}

export const getClassNames = (): IEmbeddedContentClassNames => {
  return mergeStyleSets({
    iframeContainer: {
      position: 'relative',
      // paddingBottom: '56.25%', // 16:9 aspect ratio
      width: '100%',
      aspectRatio: '16 / 9',
      // height: 0,
      overflow: 'hidden',
      maxWidth: '100%',
      borderRadius: BORDER_RADIUS, // Rounded corners

      'iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 0 // Remove iframe border
      }
    },
  });
};