import { IDropdownOption } from "@fluentui/react";
import { ScormStandard } from "api-client";
import MLForm from "common/ml-components/MLForm";
import MLHorizontalDivider from "common/ml-components/MLHorizontalDivider";
import MLModal from "common/ml-components/MLModal";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { getLanguageFromCode } from "utils/helpers/helpers";
import ScormVersion from "./components/ScormVersion/ScormVersion";
import ScormAudio from "./components/ScormAudio/ScormAudio";
import ScormLanguages from "./components/ScormLanguages/ScormLanguages";
import { LoadingContext } from "contexts/loading-context";
import saveAs from 'file-saver';

interface IScormDetailsProps {
  show: boolean;
  onDismiss: () => void;
}

interface IScormDetailsFormData {
  scormVersion: ScormStandard;
  includeAudio: boolean;
  specifiedLanguages: string[];
}

const ScormDetails: React.FC<IScormDetailsProps> = ({ show, onDismiss }) => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const lmsClientApi = useSelector((state: RootState) => state.clients.clients.lmsClientApi);
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const courseTitle = useSelector((state: RootState) => state.courses.course?.title);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const languages = supportedLanguages?.map(language => ({ key: language.language || '', text: getLanguageFromCode(language.language || ''), selected: true } as IDropdownOption)) || [];

  const defaultValues: IScormDetailsFormData = {
    scormVersion: ScormStandard.V12,
    includeAudio: true,
    specifiedLanguages: languages.map(lang => lang.key as string),
  }

  const onSubmit = async (data: IScormDetailsFormData) => {
    console.log('data', data);
    if (!lmsClientApi || !courseId) return;

    try {
      startLoading('Downloading the SCORM package...');
      const response = await lmsClientApi.exportScormAdvanced(
        courseId,
        data.scormVersion,
        { includeAudio: data.includeAudio, specifiedLanguages: data.specifiedLanguages },
        { responseType: 'arraybuffer' },
      );
      const blob = new Blob([(response.data as unknown as ArrayBuffer)]);
      const filename = `${courseTitle}-${data.specifiedLanguages.length === defaultValues.specifiedLanguages.length ? 'AllLanguages' : '[' + data.specifiedLanguages.join(',') + ']'}.zip`;
      saveAs(blob, filename);
      onDismiss();
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  return (
    <MLModal
      show={show}
      width="45rem"
    >
      <MLForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        submitButtonLabel="Export"
        title="Scorm options"
      >
        <ScormVersion />
        <MLHorizontalDivider />
        <ScormAudio />
        <MLHorizontalDivider />
        <ScormLanguages languages={languages} />
      </MLForm>
    </MLModal>
  );
};

export default ScormDetails;