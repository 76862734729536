import React, { createRef, useEffect, useRef, useState } from 'react';

// import components
import MLHorizontalDivider from 'common/ml-components/MLHorizontalDivider';

// import types
import { getClassNames } from './Categories.classNames';
import { useFormContext } from 'react-hook-form';
import MLCollection from 'common/ml-components/MLList/MLCollection/MLCollection';
import MLNewItem from 'common/ml-components/MLList/MLNewItem/MLNewItem';
import { v4 } from 'uuid';

interface ICategoriesProps {
  updateHeight: (height: number) => void;
}

const Categories = ({ updateHeight }: ICategoriesProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { getValues, setValue } = useFormContext();

  const fields = getValues('categories.collection');
  const [categories, setCategories] = 
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const onAdd = (value: string) => {
    const newItem = { id: v4(), value };
    setCategories([{ ...newItem, ref: createRef<HTMLDivElement>() }, ...categories]);
    setValue('categories.collection', [{ ...newItem }, ...categories], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...categories];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setCategories(newItems);
    setValue('categories.collection', newItems, { shouldDirty: true });
  };

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.categoriesContainer}>
      <MLCollection
        items={categories}
        setItems={setCategories}
        title='Categories'
        maxHeight
        nonEditable
        onDelete={onDelete}
      />
      <MLHorizontalDivider />
      <MLNewItem
        items={categories}
        setItems={setCategories}
        title='New category'
        noMargin
        paddingRight
        onAdd={onAdd}
      />
    </div>
  );
};

export default Categories;