import React, { createRef, useEffect, useRef, useState } from 'react';

// import components
import MLHorizontalDivider from 'common/ml-components/MLHorizontalDivider';

// import types
import { getClassNames } from './Badges.classNames';
import { useFormContext } from 'react-hook-form';
import MLCollection from 'common/ml-components/MLList/MLCollection/MLCollection';
import MLNewItem from 'common/ml-components/MLList/MLNewItem/MLNewItem';
import { v4 } from 'uuid';
import { GamificationBadgeDto } from 'api-client';

interface IBadgesProps {
  updateHeight: (height: number) => void;
}

const Badges = ({ updateHeight }: IBadgesProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { getValues, setValue } = useFormContext();

  const fields = getValues('badges.collection');
  const [badges, setBadges] = 
    useState<{ id: string | number; value: string; secondaryValue?: string;  ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.filter((field: GamificationBadgeDto) => !field.archived).map((field: GamificationBadgeDto) => ({
        id: field.id,
        value: field.badgeTitle,
        secondaryValue: field.achievementPoints?.toString(),
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const onAdd = (value: string, secondaryValue?: string) => {
    const newItem = { id: v4(), value, secondaryValue };
    setBadges([{ ...newItem, ref: createRef<HTMLDivElement>() }, ...badges]);
    const newBadge = {
      id: newItem.id,
      badgeTitle: newItem.value,
      achievementPoints: newItem.secondaryValue !== undefined ? +newItem.secondaryValue : 0,
      archived: false,
      imageUrl: '',
    } as GamificationBadgeDto;
    setValue('badges.collection', [{ ...newBadge }, ...fields], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...badges];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setBadges(newItems);
    const newBadges = fields.map((field: GamificationBadgeDto) => field.id === id ? { ...field, archived: true } : field);
    setValue('badges.collection', newBadges, { shouldDirty: true });
  };

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.badgesContainer}>
      <MLCollection
        items={badges}
        setItems={setBadges}
        title='Badges'
        maxHeight
        nonEditable
        onDelete={onDelete}
        secondarySuffix='pts'
      />
      <MLHorizontalDivider />
      <MLNewItem
        items={badges}
        setItems={setBadges}
        title='New badge'
        secondaryTitle='Score'
        noMargin
        paddingRight
        onAdd={onAdd}
        secondarySuffix='pts'
        isSecondaryNumeric
      />
    </div>
  );
};

export default Badges;