import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { getClassNames } from "./Banners.classNames";
import { CourseStatusDto, LanguageStatusDto, MLErrorDto } from "../../../../../api-client";
import { clearError, clearJustGenerated } from "store/slices/courses";
import { useAppDispatch } from "store/store";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { RefObject, useRef, useState } from "react";
import { Status } from "utils/constants/status";

interface IErrorBannerProps {
  status: CourseStatusDto | LanguageStatusDto | null | undefined;
  errors: MLErrorDto[] | null | undefined;
  justGenerated: boolean;
}

export default function Banners({ status, errors, justGenerated }: IErrorBannerProps) {
  const dispatch = useAppDispatch();
  const classes = getClassNames();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let nodeRef: RefObject<HTMLDivElement> | null = null;
  let key: string = '';

  // const backgroundRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const warningRef = useRef<HTMLDivElement>(null);
  const justGeneratedRef = useRef<HTMLDivElement>(null);

  const getStatusTypes = () => {
    if (status === CourseStatusDto.GenError) {
      if (errors && errors.length > 0) {
        if (errors[errors.length - 1].level === 'Warning') {
          nodeRef = warningRef;
          key = 'warning';
          return;
        }

        if (errors[errors.length - 1].level === 'Error') {
          nodeRef = errorRef;
          key = 'error';
          return;
        }
      }
    }
    
    if (justGenerated) {
      nodeRef = justGeneratedRef;
      key = 'justGenerated';
      return;
    }
    return null;
  }

  getStatusTypes();
  const message = (status === CourseStatusDto.GenError && errors && errors.length > 0 && errors[errors.length - 1].description) || 'The content generated by the AI may not be correct, check the output of the pills generated from your document';

  async function onCancelClick() {
    try {
      setIsLoading(true);
      if (status === CourseStatusDto.GenError) {
        await dispatch(clearError()).unwrap();
      } else if (justGenerated) {
        await dispatch(clearJustGenerated()).unwrap();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <CSSTransition
      nodeRef={bannerRef}
      key='banner'
      timeout={500}
      in={status === CourseStatusDto.GenError || justGenerated}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        exit: classes.exit,
        exitActive: classes.exitActive,
        appear: classes.enter,
        appearActive: classes.enterActive
      }}
      appear
      unmountOnExit
    >
      <div ref={bannerRef} className={classes.banner}>
        <SwitchTransition>
          <CSSTransition
            nodeRef={nodeRef}
            key={key}
            timeout={500}
            classNames={{
              enter: classes.enter,
              enterActive: classes.enterActive,
              exit: classes.exit,
              exitActive: classes.exitActive,
            }}>
              <div
                ref={nodeRef}
                className={`${classes.bannerContainer} ${key === 'error' ? classes.errorBanner : key === 'warning' ? classes.warningBanner : classes.justGeneratedBanner}`}>
                <div className={classes.messageContainer}>
                  <p className={classes.message} title={message}>{message}</p>
                </div>
                <div className={classes.iconContainer}>
                  {isLoading ? 
                    <Spinner
                      size={SpinnerSize.small}
                      styles={{ 
                        circle: {
                          borderColor: `${status === CourseStatusDto.GenError ? Status.GenError.color : Status.Draft.color} transparent transparent transparent`,
                        },
                      }}
                    /> : 
                    <Icon iconName="ChromeClose" className={classes.closeIcon} onClick={onCancelClick} />
                  }
                </div>

              </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </CSSTransition>
  );
}

// Il contenuto generato dall'AI potrebbe non essere corretto, verifica l'output delle pillole generate a partire dal tuo documento
// The content generated by the AI ​​may not be correct, check the output of the pills generated from your document