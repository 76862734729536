import { getClassNames } from "./text-lesson.classNames";

interface ITextLessonProps {
  text: string;
}

const TextLesson = (props: ITextLessonProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.textLessonContainer}>
      <div className={classes.textLessonScrollContainer}>
        <p className={classes.body}>{props.text}</p>
      </div>
    </div>
  );
}

export default TextLesson;