import { mergeStyleSets } from "@fluentui/react"
import { ANIMATION_DURATION, BORDER_RADIUS } from "utils/constants/styles";

interface IAddAttachmentClassNames {
  addAttachmentContainer: string;
  embedFormContainer: string;
  embedFormButtonsContainer: string;

  contentEnter: string;
  contentEnterActive: string;
  contentExit: string;
  contentExitActive: string;
}

export const getClassNames = (): IAddAttachmentClassNames => {
  return mergeStyleSets({
    addAttachmentContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
    },

    embedFormContainer: {
      width: '100%',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      // backgroundColor: 'white',
      borderRadius: BORDER_RADIUS,
    },

    embedFormButtonsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '2rem'
    },

    contentEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    contentEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    contentExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    contentExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};