import { getTheme } from "@fluentui/react";
import { getClassNames } from "./Forward.classNames";

interface IForwardProps {
  onForward: () => void;
}

const Forward = ({ onForward }: IForwardProps) => {
  const theme = getTheme();

  const classes = getClassNames();

  return (
    <div className={classes.iconContainer} onClick={onForward}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.8318 3.5C15.2217 2.24608 13.1983 1.5 11 1.5C5.7533 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.7533 20.5 11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 10.5479 20.4697 10.3411 20.3993 9.86101L20.399 9.85881C20.3389 9.44898 20.6224 9.06803 21.0322 9.00794C21.442 8.94784 21.823 9.23135 21.8831 9.64119L21.8849 9.65337C21.9568 10.1436 22 10.4384 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C13.6592 0 16.0985 0.944232 18 2.5144V0.75C18 0.335786 18.3358 0 18.75 0C19.1642 0 19.5 0.335786 19.5 0.75V4.25C19.5 4.66421 19.1642 5 18.75 5H15.25C14.8358 5 14.5 4.66421 14.5 4.25C14.5 3.83579 14.8358 3.5 15.25 3.5H16.8318Z" fill={theme.palette.neutralTertiary}/>
      </svg>
      <div className={classes.numberContainer}>5</div>
    </div>
  )
};

export default Forward;