import { mergeStyleSets } from "@fluentui/react";
import { ANIMATION_DURATION } from "utils/constants/styles";

interface IActionButtonsClassNames {
  buttonsAndErrorContainer: string;
  errorContainer: string;
  errorMessage: string;
  actionButtonsContainer: string;

  errorFadeEnter: string;
  errorFadeEnterActive: string;
  errorFadeExit: string;
  errorFadeExitActive: string;
}

export const getClassNames = (isTabForm: boolean | undefined, noPadding: boolean | undefined): IActionButtonsClassNames => {
  return mergeStyleSets({
    buttonsAndErrorContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },

    errorContainer: {
      width: '100%',
      flex: '0 0 2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    errorMessage: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: 'red',
    },

    actionButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10,
      // padding: isTabForm ? '0 2.5rem' : '0 1.5rem',

      padding: noPadding ? 0 : isTabForm ? '0 2.5rem 0 0' : '0 1.5rem 0 0',
    },

    errorFadeEnter: {
      opacity: 0,
      transform: 'scaleX(0)',
    },
    
    errorFadeEnterActive: {
      opacity: 1,
      transform: 'scaleX(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
    
    errorFadeExit: {
      opacity: 1,
      transform: 'scaleX(1)',
    },
    
    errorFadeExitActive: {
      opacity: 0,
      transform: 'scaleX(0)',
      transition: `all ${ANIMATION_DURATION}ms`,
    }
  });
};