import { getTheme } from "@fluentui/react";
import { getClassNames } from "./Backward.classNames";

interface IBackwardProps {
  onBackward: () => void;
}

const Backward = ({ onBackward }: IBackwardProps) => {
  const theme = getTheme();

  const classes = getClassNames();

  return (
    <div className={classes.iconContainer} onClick={onBackward}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.16821 3.5C6.77826 2.24608 8.80171 1.5 11 1.5C16.2467 1.5 20.5 5.75329 20.5 11C20.5 16.2467 16.2467 20.5 11 20.5C5.75329 20.5 1.5 16.2467 1.5 11C1.5 10.5479 1.53032 10.3411 1.60072 9.86101L1.60104 9.85881C1.66114 9.44898 1.37762 9.06803 0.967789 9.00794C0.557958 8.94784 0.177006 9.23135 0.11691 9.64119L0.115123 9.65337C0.0432335 10.1436 0 10.4384 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C8.34078 0 5.90147 0.944232 4 2.5144V0.75C4 0.335786 3.66421 0 3.25 0C2.83579 0 2.5 0.335786 2.5 0.75V4.25C2.5 4.66421 2.83579 5 3.25 5H6.75C7.16421 5 7.5 4.66421 7.5 4.25C7.5 3.83579 7.16421 3.5 6.75 3.5H5.16821Z" fill={theme.palette.neutralTertiary}/>
      </svg>
      <div className={classes.numberContainer}>5</div>
    </div>
  )
};

export default Backward;