import { getTheme } from '@fluentui/react';
import React from 'react';
import { getClassNames } from './CircularProgressBar.classNames';

interface CircularProgressBarProps {
  duration: number | undefined;  // Total duration of the audio
  timeProgress: number | undefined;  // Current time of the audio
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({ duration, timeProgress }) => {
  const radius = 70;
  const circumference = 2 * Math.PI * radius;

  const progress = timeProgress !== undefined && duration !== undefined ? (timeProgress / duration) * 100 : undefined;
  const dashOffset = progress === undefined ? circumference : isNaN(progress) ? circumference : circumference - (progress / 100) * circumference;

  // Get the thumb position (convert progress to angle and position)
  const angle = (timeProgress !== undefined && progress !== undefined) ? timeProgress === 0 ? 0 : (progress / 100) * 360 : undefined;
  const x = angle !== undefined ? 80 + radius * Math.cos((angle) * (Math.PI / 180)) : undefined; // X position of thumb
  const y = angle !== undefined ? 80 + radius * Math.sin((angle) * (Math.PI / 180)) : undefined; // Y position of thumb

  const classes = getClassNames();

  return (
    <svg height="100%" width="100%" viewBox="0 0 160 160" className={classes.progressCircle}>
      <circle
        className="progress-bg"
        stroke={getTheme().palette.themeLighter}
        strokeWidth="6"
        fill="none"
        r={radius}
        cx="80"
        cy="80"
      />
      <circle
        className="progress"
        stroke={getTheme().palette.themePrimary}
        strokeWidth="6"
        strokeDasharray={circumference}
        strokeDashoffset={dashOffset}
        strokeLinecap='round'
        fill="none"
        r={radius}
        cx="80"
        cy="80"
      />
      <circle
        className={classes.thumb}
        cx={x}  // X coordinate based on progress
        cy={y}  // Y coordinate based on progress
        r="3"   // Size of the thumb
      />
    </svg>
  );
};

export default CircularProgressBar;