// import react
import { useRef } from "react";

// import styles
import { getClassNames } from "./FileDetails.classNames";

// import models
import { CSSTransition } from "react-transition-group";
import { Icon } from "@fluentui/react";
import { useFormContext } from "react-hook-form";
import { getFileType } from "utils/helpers/helpers";
import MLTextField from "common/ml-components/inputs/MLTextField";
import { ANIMATION_DURATION } from "utils/constants/styles";

export interface IFileDetailsProps{
  showDetails: boolean;
  toggleShowDetails: () => void;
};

const FileDetails: React.FC<IFileDetailsProps> = ({ showDetails, toggleShowDetails }) => {
  const { watch } = useFormContext();
  const fileType = getFileType(watch('document'));

  const inputsContainerRef = useRef<HTMLDivElement>(null);

  const classNames = getClassNames(fileType, showDetails);

  return (
    <div className={classNames.fileDetailsContainer}>
      <div className={classNames.showDetails} onClick={() => { if (!!fileType) toggleShowDetails()}}>
        <p className={classNames.advancedOptionsLabel}>Advanced options</p>
        <Icon className={classNames.chevronDownMed} iconName="ChevronDownMed" />
      </div>

      <CSSTransition
        nodeRef={inputsContainerRef}
        timeout={ANIMATION_DURATION}
        in={showDetails}
        classNames={{
          enter: classNames.enter,
          enterActive: classNames.enterActive,
          enterDone: classNames.enterDone,
          exit: classNames.exit,
          exitActive: classNames.exitActive,
          exitDone: classNames.exitDone,
        }}
        unmountOnExit
      >
        <div ref={inputsContainerRef} className={classNames.inputsContainer}>
          
          {fileType === 'Pdf' && <div className={classNames.groupInput}>
            <MLTextField
              key={'pages'}
              name={'pages'}
              label='Pages'
              placeholder="1;3-5;10"
            />
          </div>}

          {fileType === "Docx" && (
            <div className={classNames.groupInput}>
              <MLTextField
                key={'chapterHeaderPrefix'}
                name={'chapterHeaderPrefix'}
                label='Custom chapters'
              />
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default FileDetails;
