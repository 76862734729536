// import components
import MLTextField from "common/ml-components/inputs/MLTextField";

interface IAudioLessonProps {
  lessonTypeChanged: boolean;
}

const AudioLesson: React.FC<IAudioLessonProps> = ({ lessonTypeChanged }) => {
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.audioSource"
        label="Text"
        multiline
        disabled={lessonTypeChanged}
        autoAdjustHeight
        resizable={false}
        formRequired
      />
    </>
  );
};

export default AudioLesson;
