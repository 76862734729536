// import react
import { useRef, useEffect } from 'react';

// import styles
import { getClassNames } from './QuizOptions.classNames';

// import components
import MLToggle from 'common/ml-components/inputs/MLToggle';
import MLTextField from 'common/ml-components/inputs/MLTextField';

interface IQuizOptionsProps {
  updateHeight: (height: number) => void;
}

const QuizOptions: React.FC<IQuizOptionsProps> = ({ updateHeight }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.quizOptionsContainer}>
      <MLTextField
        name='quizOptions.passingPercentage'
        label='Passing score'
        suffix='%'
        width='8rem'
        isPassingPercentage
      />
      <MLToggle
        name='quizOptions.isFinalQuizRepeatable'
        label='Multiple attempts'
      />
    </div>
  );
};

export default QuizOptions;