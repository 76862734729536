import { mergeStyleSets } from '@fluentui/react';

interface ILandingPageClassNames{
  landingPageContainer: string;
  message: string;
}

export const getClassNames = (): ILandingPageClassNames => {
    return mergeStyleSets({
      landingPageContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      message: {
        margin: 0,
        fontSize: '1.6rem',
      }
    });
};