import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IListItemProps } from "./list-item";
import { Status } from "utils/constants/status";

interface IListItemClassNames {
  columns: string;
  buttonsColumn: string;
  statusContainer: string;
  statusSpanLabel: string;
}

export const getClassNames = (props: IListItemProps): IListItemClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    columns: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      color: theme.palette.black
    },

    statusContainer: {
      position: 'relative',
      height: '100%',
      width: 'fit-content',
      fontSize: 14,
      padding: '4px 10px',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      transform: 'scaleY(0.95)',

      '::before': {
        content: "''",
        background: Status[props.statusInfo] ? Status[props.statusInfo].backgroundColor : 'unset',
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 100, 
      },

      '::after': {
        content: "''",
        border: `1px solid ${Status[props.statusInfo] ? Status[props.statusInfo].backgroundColor : 'unset'}`,
        position: "absolute",
        top: 0,
        left: 0,
        width: 'calc(100% - 1px)',
        height: 'calc(100% - 1px)',
        borderRadius: 100, 
      },

      '> svg': {
        marginTop: 1
      }
    },

    statusSpanLabel: {
      color: Status[props.statusInfo] ? Status[props.statusInfo].color : 'unset',
    },

    buttonsColumn: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 5,
      transform: 'scale(0.95)',
      height: 32
    }
  });
}