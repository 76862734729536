import { mergeStyleSets } from "@fluentui/react"

interface IScheduleClassNames {
  scheduleContainer: string;
  closeButtonContainer: string;
}

export const getClassNames = (): IScheduleClassNames => {
  return mergeStyleSets({
    scheduleContainer: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    closeButtonContainer: {
      width: '100%',
      marginTop: '2rem',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '1.5rem'
    }
  });
};