import { mergeStyleSets } from '@fluentui/react';
import { IListItemProps } from './ListItem';
import { Status } from 'utils/constants/status';
import { TEXT_FONT_SIZE, BUTTON_FONT_SIZE } from 'utils/constants/styles';

interface IListItemClassNames{
  status: string;
  statusContainer: string;
  statusSpanLabel: string;
  profilePic: string;
  generatingContainer: string;
  generationProgress: string;
}
export const getClassNames = (props: IListItemProps): IListItemClassNames => {
  return mergeStyleSets({
    status: {
      width: '100%',
      height: '3.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      fontSize: TEXT_FONT_SIZE,
    },

    statusContainer: {
      position: 'relative',
      width: 'fit-content',
      padding: '0.4rem 1rem',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginTop: 2,

      '::before': {
        content: "''",
        background: props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.backgroundColor : 'transparent',
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 100,
      },

      '::after': {
        content: "''",
        border: `1px solid ${props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.backgroundColor : 'transparent'}`,
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // width: 'calc(100% - 1px)',
        // height: 'calc(100% - 1px)',
        borderRadius: 100, 
      },

      '> svg': {
        marginTop: 1
      }
    },

    statusSpanLabel: {
      color: props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.color : 'transparent',
      fontSize: BUTTON_FONT_SIZE,
    },

    profilePic: {
      width: 32,
      height: 32,
      borderRadius: '50%'
    },

    generatingContainer: {
      overflow: 'hidden',
      position: 'relative',
      border: `1px solid ${props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.backgroundColor : 'transparent'}`,
      backgroundColor: '#c2daeb',
      width: 'fit-content',
      height: 'fit-content',
      padding: '0.4rem 1rem',
      borderRadius: 100,
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginTop: 2,
    },

    generationProgress: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: `${props.lightCourseProgress.progressPercentage === null ? 100 : props.lightCourseProgress.progressPercentage}%`,
      height: '100%',
      background: props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.backgroundColor : 'transparent',
      opacity: 0.2,
      transition: 'width 200ms ease',
    },
  });
};