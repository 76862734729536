// import components
import MLTextField from "common/ml-components/inputs/MLTextField";

interface IFlashCardLessonProps {
  lessonTypeChanged: boolean;
}

const FlashCardLesson: React.FC<IFlashCardLessonProps> = ({ lessonTypeChanged }) => {
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.question"
        label="Question"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.answer"
        label="Answer"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
        formRequired
      />
      <MLTextField
        name="lesson.explanation"
        label="Explanation"
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={lessonTypeChanged}
      />
    </>
  );
};

export default FlashCardLesson;
