import { mergeStyleSets, getTheme } from '@fluentui/react';

interface IMLDropdownClassNames {
  dropdownContainer: string;
  leftLabel: string;
  option: string;
  selectedOptionContainer: string;
}

export const getClassNames = (error: boolean): IMLDropdownClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    dropdownContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },

    leftLabel: {
      fontSize: '1.4rem',
      color: error ? theme.palette.red : theme.palette.black,
    },

    option: {
      flex: 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '2',
      whiteSpace: 'pre-wrap',
    },

    selectedOptionContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
  });
};