import { getClassNames } from "./AudioOnlyLesson.classNames";
import useAudioPlayer from "./hooks/useAudioPlayer";
import { getTheme, Spinner, SpinnerSize } from "@fluentui/react";
import { AudioOnlyLessonDto } from "api-client";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useParams } from "react-router-dom";
import CircularProgressBar from "./components/CircularProgressBar/CircularProgressBar";
import PlayRounded from "assets/ml-icons/AudioPlayer/PlayRounded/PlayRounded";
import PauseRounded from "assets/ml-icons/AudioPlayer/PauseRounded/PauseRounded";
import Forward from "assets/ml-icons/AudioPlayer/Forward/Forward";
import Backward from "assets/ml-icons/AudioPlayer/Backward/Backward";

interface IAudioOnlyLessonProps {
  lesson: AudioOnlyLessonDto;
  onPlayAudioLessonId: string;
  setAudioLessonId: (id: string) => void;
}

const AudioOnlyLesson = (props: IAudioOnlyLessonProps) => {
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const { lang } = useParams();

   const {
    audioSource,
    isLoading,
    audioRef, 
    progressBarRef, 
    onLoadedMetadata, 
    isPlaying, 
    onPlayClick, 
    onPauseClick, 
    timeProgress, 
    duration, 
    onProgressChange,
    onForward,
    onBackward
  } = useAudioPlayer(props.lesson, courseId, lang || documentLanguage, props.onPlayAudioLessonId, props.setAudioLessonId );

  const getFormattedTime = () => {
    if (!duration) return '';

    const timeProgressMinutes = 
      Math.floor(timeProgress / 60) > 0 && Math.floor(timeProgress / 60) < 10 
      ? `0${Math.floor(timeProgress / 60)}` 
      : `0${Math.floor(timeProgress / 60)}`;

    const timeProgressSeconds = 
      +(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0) > 9 
      ? `${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}` 
      : `0${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}`;

    const timeDurationMinutes = Math.floor(duration / 60) > 0 && Math.floor(timeProgress / 60) < 10 
    ? `0${Math.floor(duration / 60)}` 
    : `0${Math.floor(duration / 60)}`;

    const timeDurationSeconds = 
      (duration - Math.floor(duration / 60) * 60) > 9 
      ? `${(duration - Math.floor(duration / 60) * 60).toFixed(0)}` 
      : `0${(duration - Math.floor(duration / 60) * 60).toFixed(0)}`;
    
    return `${timeProgressMinutes}:${timeProgressSeconds} / ${timeDurationMinutes}:${timeDurationSeconds}`;
  };

  const theme = getTheme();

  const getIconStatus = () => {
    if (isLoading) return (
      <div className={classes.loaderContainer}>
        <Spinner
          size={SpinnerSize.large}
          styles={{ 
            circle: {
              borderColor: `${theme.palette.white} transparent transparent transparent`,
            },
          }}
        />
      </div>
    )
    if (isPlaying) return <PauseRounded onPauseClick={onPauseClick} />;
    else return <PlayRounded onPlayClick={onPlayClick} />
  };

  const getDurationStatus = () => {
    if (duration === null || isLoading) return '--:-- / --:--';
    else return getFormattedTime();
  };

  const classes = getClassNames();

  return (
    <div className={classes.audioPlayerContainer} draggable onDragStart={(e) => {e.preventDefault(); e.stopPropagation()}}>
      <audio 
        ref={audioRef} 
        src={audioSource}
        onLoadedMetadata={onLoadedMetadata}
      />

      <div className={classes.playerContainer}>
        <Backward onBackward={onBackward} />
        
        <CircularProgressBar
          timeProgress={timeProgress}
          duration={duration !== null ? duration : 0}
        />

        <Forward onForward={onForward} />

        <div className={classes.buttonContainer}>
          {getIconStatus()}
        </div>
      </div>


      <div className={classes.controlsContainer}>
        <div className={classes.timeProgressContainer}>
          {getDurationStatus()}
        </div>
      </div>

      <input hidden type="range" ref={progressBarRef} defaultValue='0' step={0.1} onChange={onProgressChange} />
    </div>
  )
};

export default AudioOnlyLesson;