import { mergeStyleSets, getTheme } from "@fluentui/react";
import { ANIMATION_DURATION } from "utils/constants/styles";

interface IAddScheduleClassNames {
  newSchedule: string;
  title: string;
  addScheduleFormContainerInfo: string;
  addScheduleFormContainerNotifications: string;
  enter: string;
  enterActive: string;
  exit: string;
  exitActive: string;
  enterDone: string;
  exitDone: string;
}

export const getClassNames = (): IAddScheduleClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    newSchedule: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
      paddingLeft: 1,
      borderBottom: `2px solid ${theme.palette.neutralLighter}`,
    },

    title: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.neutralSecondaryAlt,
      alignSelf: 'flex-start',
    },

    addScheduleFormContainerInfo: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      gap: '1rem',
    },

    addScheduleFormContainerNotifications: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      gap: '2rem',
    },

    enter: {
      opacity: 0,
      flex: '0 0 0',

      paddingBottom: 0,
      marginBottom: 0,
    },

    enterActive: {
      opacity: 1,
      flex: '0 0 22rem',
      paddingBottom: 10,
      marginBottom: 10,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    exit: {
      opacity: 1,
      flex: '0 0 22rem',
      paddingBottom: 10,
      marginBottom: 10,
    },

    exitActive: {
      opacity: 0,
      flex: '0 0 0',
      paddingBottom: 0,
      marginBottom: 0,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    exitDone: {
      paddingBottom: 0,
      marginBottom: 0,
      flex: '0 0 0',
    },

    enterDone: {
      paddingBottom: 10,
      marginBottom: 10,
      flex: '0 0 22rem',
    },
  });
}