import { DateTime } from "luxon";
import { BulletPointLessonDto, CourseCategoryDto, LessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto, TimeZoneDto } from "../../api-client";
import { FileTypeDto } from "../../api-client";
import { MIMETypes } from "../constants/file-types";
import timeZoneGroups from 'assets/data/timezones.json';

export const arrayBufferToBase64 = ( buffer: ArrayBuffer ) => {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
  binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
};

export const fileToBase64 = (file: File) => new Promise<string | null>((resolve, reject) => {
  try {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      console.log('fileReader.result', fileReader.result);
      resolve(fileReader.result as string)
    };
    fileReader.onerror = (err) => reject(err);
  } catch (err) {
    console.log('fileToBase64 err', err);
  }
});

export const getFileType = (file: File) => {
  if (!file) return;
  const fileMIME = file.type;
  let fileType: FileTypeDto | null = null;
  if (fileMIME === MIMETypes.DOCX) fileType = FileTypeDto.Docx;
  if (fileMIME === MIMETypes.PDF) fileType = FileTypeDto.Pdf;
  return fileType;
};

export const isFileTypeCorrect = (file: File | undefined | null): boolean => {
  const fileMIME = file?.type;
  if (!(fileMIME === MIMETypes.JPEG || fileMIME === MIMETypes.PNG || fileMIME === MIMETypes.WEBP || fileMIME === MIMETypes.MP4)) {
    return false;
  } else return true;
};

export const getLesson = (lesson: LessonDto) => {
  switch (lesson.type) {
    case LessonTypeDto.Text: return lesson as TextLessonDto;
    case LessonTypeDto.BulletPoints: return lesson as BulletPointLessonDto;
    case LessonTypeDto.Quiz: return lesson as QuizLessonDto;
    default: return null;
  }
};

export const getLanguageFromCode = (languageCode: string): string => new Intl.DisplayNames(['en'], { type: "language",
  languageDisplay: 'standard',
  style: 'short' }).of(languageCode.includes('US') || languageCode.includes('GB') ? languageCode : languageCode.substring(0, 2)) || '';

export const sortString = (a: string, b: string) => a > b ? 1 : (a < b ? -1 : 0);

export const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getTime(): DateTime {
  const now = DateTime.now();
  const minutes = now.minute;
  return now.set({hour: minutes < 31 ? now.hour : now.hour + 1, minute: minutes < 31 ? 30 : 0});
}

export function getSelectedTimeZone(timeZones: TimeZoneDto[]): string {
  const currentTimeZone = DateTime.now().zoneName;
  if (!currentTimeZone) return '';

  const group = timeZoneGroups.find(group => group.utc.includes(currentTimeZone));
  if (!group) return '';
  const selectedTimeZone = timeZones.find(timeZone => timeZone.id && group.utc.includes(timeZone.id));
  if (selectedTimeZone) return selectedTimeZone.standardName || '';
  return '';
}

export function windowsId2Iana(wId : string): string | undefined {
  const matchingTimeZone = timeZoneGroups.find((zone) => zone.value === wId);
  if (matchingTimeZone) {
    return matchingTimeZone.utc[0]; // Return the first IANA time zone from the utc array
  }
  // Return null or throw an error if no matching time zone is found
  return '';
};

export function hexToBytes(hex: string) {
  const bytes = [];
  for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substring(i, 2), 16));
  }
  return new Uint8Array(bytes);
}

export const remToPx = (rem: number) => {
  // Get the root font size (default is 16px)
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return rem * rootFontSize;
};

type NewCategory = {
  id: string;
  value: string;
};

export const transformCategories = (
  originalCategories: CourseCategoryDto[],
  newCategories: NewCategory[]
): CourseCategoryDto[] => {
  const result: CourseCategoryDto[] = [];
  const originalMap = new Map(originalCategories.map(cat => [cat.categoryTranslations?.find(t => t.language === 'it-IT')?.value, cat]));

  for (const newCat of newCategories) {
    const originalCat = originalMap.get(newCat.value);
    if (originalCat) {
      // Category exists in original, use original data
      result.push({
        ...originalCat,
        id: originalCat.id // Ensure we use the original ID
      });
    } else {
      // New category, create new entry with only the provided language
      result.push({
        id: newCat.id,
        categoryTranslations: [
          { language: 'it-IT', value: newCat.value }
        ],
        archived: false
      });
    }
  }

  // Add any categories that were in the original but not in the new list as archived
  for (const originalCat of originalCategories) {
    const italianValue = originalCat.categoryTranslations?.find(t => t.language === 'it-IT')?.value;
    if (italianValue && !newCategories.some(newCat => newCat.value === italianValue)) {
      result.push({
        ...originalCat,
        archived: true
      });
    }
  }

  return result;
};

export const syncOriginalArray = (original: CourseCategoryDto[], transformed: { id: string, category: string }[]): CourseCategoryDto[] => {
  // Create a map of the transformed data by id for quick lookup
  const transformedMap = new Map(transformed.map(item => [item.id, item.category]));

  // Create a deep copy of the original array
  const updatedOriginal = JSON.parse(JSON.stringify(original)) as CourseCategoryDto[];

  // Update existing items and remove items not present in the transformed array
  original = updatedOriginal.filter(item => {
      if (transformedMap.has(item.id || '')) {
          const newCategory = transformedMap.get(item.id || '');
          const translation = item.categoryTranslations?.find(translation => translation.language === "it-IT");
          if (translation) {
              translation.value = newCategory;
          } else {
              // If "it-IT" translation is missing, add it
              item.categoryTranslations?.push({ language: "it-IT", value: newCategory });
          }
          // Remove the item from the map once it's processed
          transformedMap.delete(item.id || '');
          return true;
      } else {
          // Item not in transformed array, it should be removed
          return false;
      }
  });

  // Add new items from the transformed array that were not in the original array
  transformedMap.forEach((category, id) => {
      original.push({
          id: id,
          categoryTranslations: [{ language: "it-IT", value: category }],
          archived: false
      });
  });

  return original;
}