import { useRef, useState } from 'react';
import { getClassNames } from './Document.classNames';
import { useFormContext } from 'react-hook-form';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from 'utils/constants/styles';
import SelectedDocument from './components/SelectedDocument/SelectedDocument';

export interface IDocument {
  name: string;
  type: string;
};

const Document: React.FC = () => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const [selectedFile, setSelectedFile] = useState<IDocument | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const key = selectedFile?.name || 'Drag and drop a file here or click to select';
  const noFileRef = useRef<HTMLDivElement>(null);
  const selectedFileRef = useRef<HTMLDivElement>(null);
  const nodeRef = selectedFile ? selectedFileRef : noFileRef;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const file = event.dataTransfer.files?.[0];
    if (file) {
      setValue('document', file as any, { shouldValidate: true });
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      setSelectedFile({ name: fileNameWithoutExtension, type: file.type });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue('document', file as any, { shouldValidate: true });
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      setSelectedFile({ name: fileNameWithoutExtension, type: file.type });
    }
  };

  const classes = getClassNames(!!errors.document);

  return (
    <div
      className={classes.documentContainer}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => inputRef.current?.click()}
    >
      <input
        type='file'
        id='document'
        style={{ display: 'none' }}
        {...register('document', { required: 'Required' })}
        onChange={handleFileChange}
        accept='.docx,.pdf' // Add accept attribute to only allow docx and pdf files
        ref={inputRef}
      />
      <SwitchTransition>
        <CSSTransition
          nodeRef={nodeRef}
          key={key}
          timeout={ANIMATION_DURATION}
          classNames={{
            enter: classes.documentEnter,
            enterActive: classes.documentEnterActive,
            exit: classes.documentExit,
            exitActive: classes.documentExitActive,
          }}
        >
          <div ref={nodeRef} className={classes.document}>
            {selectedFile ? (
              <SelectedDocument selectedFile={selectedFile} />
            ) : (
              <label className={classes.label}>Drag and drop a file here or click to select</label>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default Document;