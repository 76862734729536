import { mergeStyleSets } from "@fluentui/react";

interface IChevronRightIconClassNames {
  icon: string;
}

export const getClassNames = (disabled: boolean): IChevronRightIconClassNames => {
  return mergeStyleSets({
    icon: {
      cursor: disabled ? 'auto' : 'pointer',
      transition: 'all 350ms',
    }
  });
};