import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IAudioPlayerClassNames {
  audioPlayerContainer: string;
  playerContainer: string;
  loaderContainer: string;
  icon: string;
  volumeIcon: string;
  statusContainer: string;
  buttonContainer: string;
  controlsContainer: string;
  timeProgressContainer: string;
}

export const getClassNames = (): IAudioPlayerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    audioPlayerContainer: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'space-around',
      gap: '2rem',
      cursor: 'default',
    },

    playerContainer: {
      overflow: 'hidden',
      position: 'relative',
      flex: '0 0 18rem',
      width: '100%',
      padding: '0 3rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'default'
    },

    loaderContainer: {
      width: 80,
      height: 80,
      borderRadius: '50%',
      backgroundColor: theme.palette.themeTertiary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 3,
    },

    // progressBarContainer: {
    //   'input[type="range"]': {
    //     '--range-progress': 0,
    //     '-webkit-appearance': 'none',
    //     position: 'relative',
    //     background: theme.palette.neutralQuaternary,
    //     width: '100%',
    //     height: 3,
    //     borderRadius: 2,
    //     cursor: 'pointer',
    //   },

    //   'input[type="range"]::before': {
    //     content: '""',
    //     height: 3,
    //     background: theme.palette.themePrimary,
    //     width: timeProgress && duration && `${(timeProgress / duration) * 100}%`,
    //     borderBottomLeftRadius: 2,
    //     borderTopLeftRadius: 2,
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //   },

    //   'input[type="range"]::-webkit-slider-thumb': {
    //     '-webkit-appearance': 'none',
    //     height: 8,
    //     width: 8,
    //     borderRadius: '50%',
    //     border: 'none',
    //     background: theme.palette.themePrimary,
    //     cursor: 'pointer',
    //     position: 'relative',
    //   },

    //   'input[type="range"]:active::-webkit-slider-thumb': {
    //     transform: 'scale(1.2)',
    //   },
    // },

    icon: {
      fontSize: 45,
      cursor: 'pointer'
    },

    statusContainer: {
      height: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
    },

    buttonContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    controlsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '6rem',
    },

    volumeIcon: {
      fontSize: 18,
      cursor: 'pointer'
    },

    timeProgressContainer: {
      display: 'flex',
      fontSize: 14, 
    }
  });
}