import { createRef, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getClassNames } from "./SelectedQuestion.classNames";
import ActionButtons from "common/ml-components/MLForm/components/ActionButtons/ActionButtons";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "utils/constants/styles";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import { FinalQuizLessonDto } from "api-client";
import MLDialog from "common/ml-components/MLDialog";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";

interface ISelectedQuestionProps {
  onDismiss: () => void;
  selectedQuestionIndex: number;
  compareData?: (data: FinalQuizLessonDto) => boolean;
}

const SelectedQuestion: React.FC<ISelectedQuestionProps> = ({
  onDismiss,
  selectedQuestionIndex,
  compareData
 }) => {
  const [isDataChanged, setIsDataChanged] = useState(!compareData);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { control, formState: { dirtyFields }, setValue } = useFormContext();

  const data = useWatch({ control });

  const [options, setOptions] =
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      data.quizes[selectedQuestionIndex].options.map((field: { id: string | number; response: string; }) => ({
        id: field.id,
        value: field.response,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  useEffect(() => {
    setOptions(() => (
      data.quizes[selectedQuestionIndex].options.map((field: { id: string | number; response: string; }) => ({
        id: field.id,
        value: field.response,
        ref: createRef<HTMLDivElement>(),
      }))
    ));
  }, [selectedQuestionIndex, data.quizes]);

  const correctAnswerId = data.quizes[selectedQuestionIndex].correctAnswerId;

  const nodeRef = useRef<HTMLDivElement>(null);

  const onCancel = () => {
    if (!compareData) return onDismiss();
    if (isDataChanged) setShowDialog(true);
    else onDismiss();
  };

  useEffect(() => {
    // Check if any of the form values have changed
    if (!compareData) return;

    const hasChanged = Object.keys(dirtyFields).length > 0;
    if (hasChanged) {
      setIsDataChanged(compareData(data as FinalQuizLessonDto));
    } else setIsDataChanged(false);
  }, [dirtyFields, compareData, data]);

  const onDragEnd = () => {
    setValue(`quizes.${selectedQuestionIndex}.options`, options.map(opt => ({ id: opt.id, response: opt.value })), { shouldDirty: true });
  };

  const onAdd = (value: string) => {
    const maxId = options.length > 0 ? Math.max(...options.map(item => item.id as number)) : -1;
    const newItem = {
      id: maxId + 1,
      value
    };
    setOptions([...options, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    const newOptions = options.map(opt => ({ id: opt.id, response: opt.value }));
    setValue(`quizes.${selectedQuestionIndex}.options`, [...newOptions, { id: newItem.id, response: newItem.value }], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...options];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setOptions(newItems);
    const newOptions = newItems.map(opt => ({ id: opt.id, response: opt.value }));
    setValue(`quizes.${selectedQuestionIndex}.options`, newOptions, { shouldDirty: true });
    // if (correctAnswerId === id) setValue(`quizes.${selectedQuestionIndex}.correctAnswerId`, newOptions[0].id, { shouldDirty: true });
  };

  const onChange = (id: string | number, newValue: string) => {
    const newItems = [...options];
    const editItem = newItems.find((item) => item.id === id);
    if (!editItem) return;

    editItem.value = newValue;
    const newOptions = newItems.map(opt => ({ id: opt.id, response: opt.value }));
    setValue(`quizes.${selectedQuestionIndex}.options`, newOptions, { shouldDirty: true });
  };

  const selectedQuestionId = data.quizes[selectedQuestionIndex].id;

  const classes = getClassNames(false);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <SwitchTransition>
      <CSSTransition
        key={selectedQuestionId}
        nodeRef={nodeRef}
        timeout={ANIMATION_DURATION}
        classNames={{
          enter: classes.questionEnter,
          enterActive: classes.questionEnterActive,
          exit: classes.questionExit,
          exitActive: classes.questionExitActive,
        }}
      >
        <div ref={nodeRef} className={classes.selectedQuestionContainer}>
          <MLTextField
            key={`question-${selectedQuestionId}-${selectedQuestionIndex}`}
            name={`quizes.${selectedQuestionIndex}.question`}
            label="Question"
            multiline
            autoAdjustHeight
            resizable={false}
          />
            <MLCollection
              key={`collection-${selectedQuestionId}-${selectedQuestionIndex}`}
              items={options}
              setItems={setOptions}
              title="Answers"
              disabledIndex={options.findIndex((opt: any) => opt.id === correctAnswerId)}
              draggable
              onDragEnd={onDragEnd}
              onDelete={onDelete}
              onChangeValue={onChange}
            />
            <MLNewItem
              items={options}
              setItems={setOptions}
              onAdd={onAdd}
            />
            <MLDropdown
              key={`correctAnswer-${selectedQuestionId}-${selectedQuestionIndex}`}
              name={`quizes.${selectedQuestionIndex}.correctAnswerId`}
              label='Correct answer'
              dropdownStyle={DropdownStyles.COLORED}
              dropdownWidth={300}
              paddingRight="5rem"
              options={options.map((option: any) => ({ key: option.id, text: option.value }))}
            />
        </div>
      </CSSTransition>
    </SwitchTransition>

    <ActionButtons
      onDismiss={onCancel}
      isSaveEnabled={isDataChanged}
    />
    <MLDialog
      show={showDialog}
      onConfirm={() => {
        setShowDialog(false);
        onDismiss();
      }}
      onCancel={() => setShowDialog(false)}
      title='Unsaved changes'
      text='You have unsaved changes. Are you sure you want to cancel and lose all changes?'
    />
    </div>
    
  );
};

export default SelectedQuestion;