import { mergeStyleSets } from '@fluentui/react';

interface ILessonsClassNames{
  lessons: string;
}

export const getClassNames = (): ILessonsClassNames => {
    return mergeStyleSets({
      lessons: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 34rem)',
        justifyContent: 'space-between',
        gap: '3rem',
        overflowY: 'auto',
        padding: '0px 4rem 3.5rem',
      },
    });
};