import { getTheme } from "@fluentui/react";
import { IIconProps } from "common/models/Icons/icon-props";

const Analytics = (props: IIconProps): JSX.Element => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" stroke={getTheme().palette.neutralTertiary} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 2V13H9V2C9 1.44772 8.55228 1 8 1H6C5.44772 1 5 1.44772 5 2ZM4 6V2C4 0.895431 4.89543 0 6 0H8C9.10457 0 10 0.895431 10 2V3H12C13.1046 3 14 3.89543 14 5V13.5C14 13.7761 13.7761 14 13.5 14H0.5C0.223858 14 0 13.7761 0 13.5V8C0 6.89543 0.89543 6 2 6H4ZM4 13V7H2C1.44772 7 1 7.44772 1 8V13H4ZM10 13H13V5C13 4.44772 12.5523 4 12 4H10V13Z" fill="#232323"/>
    </svg>
  )
};

export default Analytics;