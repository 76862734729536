import { mergeStyleSets, getTheme } from '@fluentui/react';
import { BORDER_RADIUS } from 'utils/constants/styles';

interface IMLPaginationClassNames {
  paginationContainer: string;
  chevronContainer: string;
  disabled: string;
  page: string;
  pageNumbersContainer: string;
  currentPage: string;
}

export const getClassNames = (): IMLPaginationClassNames => {
  const theme = getTheme();
  
  return mergeStyleSets({
    paginationContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '3rem',
    },

    chevronContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5rem 0.8rem',
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: BORDER_RADIUS,
      cursor: 'pointer',
      transition: 'all 350ms',
      
      ':hover': {
        transition: 'all 350ms',
        border: `1px solid ${theme.palette.themePrimary}`,
        '*': {
          fill: theme.palette.themePrimary,
          transition: 'all 350ms',
        }
      }
    },

    disabled: {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
    },

    page: {
      fontSize: '1.4rem',
      fontWeight: 400,
      color: theme.palette.black,
      border: `1px solid ${theme.palette.neutralLight}`,
      borderRadius: BORDER_RADIUS,
      height: '2.8rem',
      width: '2.8rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 300ms',
      cursor: 'pointer',
    },

    pageNumbersContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5rem',
    },

    currentPage: {
      backgroundColor: theme.palette.themeLighter,
      // fontSize: '2rem',
      fontWeight: 700,
      color: theme.palette.themePrimary,
      transition: 'all 300ms',
    },
  });
};