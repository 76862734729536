// import styles
import { getClassNames } from "./ListItem.classNames";

// import models
import { StatusNames } from "common/models/Learnings/status";

// import utils
import { Status } from "utils/constants/status";
import { Persona } from "@fluentui/react";
import { PersonaSize, getTheme } from "@fluentui/react";
import { GenerationActionCode, LightCourseProgressDto } from "api-client";

export interface IListItemProps {
  item: any;
  index: number;
  columnKey: string;
  lightCourseProgress: LightCourseProgressDto;
}

const ListItem = (props: IListItemProps) => {
  const classes = getClassNames(props);

  const getItem = () => {
    if (props.columnKey === 'Status') {
      if (!props.lightCourseProgress.status) return <></>;
      if (props.lightCourseProgress.status === StatusNames.Generating) {
        if (props.lightCourseProgress.latestAction !== GenerationActionCode.AddNewLesson &&
          props.lightCourseProgress.latestAction !== GenerationActionCode.EditLesson
        ) {
            const statusLabel = props.lightCourseProgress.progressPercentage === 0 ? 'Starting' : (props.lightCourseProgress.progressPercentage === 100 || props.lightCourseProgress.progressPercentage === null) ? 'Finalizing' : 'Generating';
            return (
              <div className={classes.generatingContainer} title={`${props.lightCourseProgress.progressPercentage === null ? 100 : props.lightCourseProgress.progressPercentage}%`}>
                {Status[props.lightCourseProgress.status]?.icon()}
                <span className={classes.statusSpanLabel}>{statusLabel}</span>
                <div className={classes.generationProgress}/>
              </div>
            )
          } else {
            const statusLabel = 'Generating';
            return (
              <div className={classes.generatingContainer} title={`${props.lightCourseProgress.progressPercentage}%`}>
                {Status[props.lightCourseProgress.status]?.icon()}
                <span className={classes.statusSpanLabel}>{statusLabel}</span>
                <div className={classes.generationProgress}/>
              </div>
            )
          } 
        } else return (
            <div className={classes.statusContainer}>
              {props.lightCourseProgress.status ? Status[props.lightCourseProgress.status]?.icon() : <></>}
              <span className={classes.statusSpanLabel}>{props.item[props.columnKey] === StatusNames.GenError ? 'Error' : props.item[props.columnKey]}</span>
            </div>
          )
    };

    if (props.columnKey === 'Created') return (
      <div className={classes.status}>
        <span>{new Date(props.item[props.columnKey]).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</span>
      </div>
    );

    if (props.columnKey === 'Author') { 
      return (
        <div className={classes.status}>
          <Persona
            imageUrl={props.item.Author.profilePicture ? `data:image/png;base64,${props.item.Author.profilePicture}` : ''}
            size={PersonaSize.size32}
            text={props.item.Author.displayName || props.item.Author.username}
            initialsColor={getTheme().palette.themePrimary}
            imageAlt={props.item.Author.displayName ? props.item.Author.displayName : ''}
          />

        </div>
      );}

    return (
    <div className={classes.status}>
      <span>{props.item[props.columnKey]}</span>
    </div>  
    )
  }

  return (
    getItem()
  )
}

export default ListItem;