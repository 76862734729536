import React from 'react';
import { NormalPeoplePicker, IPersonaProps, IBasePickerStyles, IPeoplePickerProps } from '@fluentui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { BORDER_RADIUS, TEXT_FONT_SIZE } from 'utils/constants/styles';
import { getTheme } from '@fluentui/react';
import { getClassNames } from './MLPeoplePicker.classNames';

interface IMLPeoplePickerProps extends IPeoplePickerProps {
  name: string;
  label?: string; // New label prop
  formRequired?: boolean;
  people?: IPersonaProps[];
  uncontrolled?: boolean;
  uncontrolledError?: boolean;
}

const MLPeoplePicker: React.FC<IMLPeoplePickerProps> = ({
  name,
  label, // Destructure label prop
  formRequired,
  people = [],
  uncontrolled,
  uncontrolledError,
  ...props
}) => {
  const { control } = useFormContext();
  const theme = getTheme();

  const getPickerStyles = (error: boolean): Partial<IBasePickerStyles> => {
    return {
      root: {
        width: '100%',
        height: '3.2rem',
        borderRadius: BORDER_RADIUS,
      },
      input: {
        height: '3rem',
        fontSize: TEXT_FONT_SIZE,
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.neutralLighterAlt,
        borderColor: error ? theme.palette.red : 'transparent',
        color: 'black',
        transition: 'background-color 300ms',
      },
      itemsWrapper: {
        height: '3rem',
        padding: '0.2rem',
        borderRadius: BORDER_RADIUS,
      },
      text: {
        height: '3.2rem',
        borderRadius: BORDER_RADIUS,
        selectors: {
          ':hover': {
            borderColor: error ? theme.palette.red : theme.palette.neutralSecondary,
            borderRadius: BORDER_RADIUS,
          },
          ':focus': {
            borderColor: error ? theme.palette.red : theme.palette.neutralSecondary,
            borderRadius: BORDER_RADIUS,
          },
          '&::after': {
            borderRadius: BORDER_RADIUS
          },
        },
      },
    };
  };

  const classes = getClassNames();

  return (
    <div className={classes.peoplePickerContainer}> {/* Container for label and picker */}
      {label && ( // If label exists, render it
        <label className={classes.label}>
          {label}
        </label>
      )}

      {uncontrolled ? (
        <NormalPeoplePicker
          {...props}
          styles={getPickerStyles(!!uncontrolledError)}
          onResolveSuggestions={(filterText) => {
            return people.filter((person) =>
              person.text?.toLowerCase().includes(filterText.toLowerCase())
            );
          }}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{ required: formRequired ? 'true' : undefined }}
          render={({ field, fieldState: { error } }) => (
            <NormalPeoplePicker
              {...field}
              {...props}
              styles={getPickerStyles(!!error)}
              onItemSelected={(selectedItem?: IPersonaProps | undefined) => {
                if (selectedItem) field.onChange(selectedItem);
                return selectedItem || null;
              }}
              // onChange={(items) => field.onChange(items)}
              defaultSelectedItems={field.value ? field.value : []}
            />
          )}
        />
      )}
    </div>
  );
};

export default MLPeoplePicker;


// import { NormalPeoplePicker, IPersonaProps, IBasePickerStyles, IPeoplePickerProps } from '@fluentui/react';
// import { useFormContext, Controller } from 'react-hook-form';
// import { BORDER_RADIUS, TEXT_FONT_SIZE } from 'utils/constants/styles';
// import { getTheme } from '@fluentui/react';

// interface IMLPeoplePickerProps extends IPeoplePickerProps {
//   name: string;
//   formRequired?: boolean;
//   people?: IPersonaProps[];
//   uncontrolled?: boolean;
//   uncontrolledError?: boolean;
// }

// const MLPeoplePicker: React.FC<IMLPeoplePickerProps> = ({
//   name,
//   formRequired,
//   people = [],
//   uncontrolled,
//   uncontrolledError,
//   ...props
// }) => {
//   const { control } = useFormContext();
//   const theme = getTheme();

//   const getPickerStyles = (error: boolean): Partial<IBasePickerStyles> => {
//     return {
//       root: {
//         width: '100%',
//         borderRadius: BORDER_RADIUS,
//       },
//       input: {
//         minHeight: '3.2rem',
//         fontSize: TEXT_FONT_SIZE,
//         borderRadius: BORDER_RADIUS,
//         backgroundColor: theme.palette.neutralLighterAlt,
//         borderColor: error ? theme.palette.red : 'transparent',
//         color: 'black',
//         transition: 'background-color 300ms',
//       },
//       itemsWrapper: {
//         minHeight: '3.2rem',
//         padding: '0.2rem',
//         borderRadius: BORDER_RADIUS,
//       },
//       text: {
//         borderRadius: BORDER_RADIUS,
//         selectors: {
//           ':hover': {
//             borderColor: error ? theme.palette.red : theme.palette.neutralSecondary,
//             borderRadius: BORDER_RADIUS,
//           },
//           ':focus': {
//             borderColor: error ? theme.palette.red : theme.palette.neutralSecondary,
//             borderRadius: BORDER_RADIUS,
//           },
//           '&::after': {
//             borderRadius: BORDER_RADIUS
//           },
//         },
//       },
//     };
//   };

//   return (
//     <>
//       {uncontrolled ? (
//         <NormalPeoplePicker
//           {...props}
//           styles={getPickerStyles(!!uncontrolledError)}
//           onResolveSuggestions={(filterText) => {
//             return people.filter((person) =>
//               person.text?.toLowerCase().includes(filterText.toLowerCase())
//             );
//           }}
//         />
//       ) : (
//         <Controller
//           name={name}
//           control={control}
//           rules={{ required: formRequired ? 'true' : undefined }}
//           render={({ field, fieldState: { error } }) => (
//             <NormalPeoplePicker
//               {...field}
//               {...props}
//               styles={getPickerStyles(!!error)}
//               onChange={(items) => field.onChange(items)}
//             />
//           )}
//         />
//       )}
//     </>
//   );
// };

// export default MLPeoplePicker;
