import { getClassNames } from "./generating.classNames";

const Generating = () => {
  const classes = getClassNames();

  return (
    <svg className={classes.icon} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1C4.13401 1 1 4.13401 1 8C1 8.27614 0.776142 8.5 0.5 8.5C0.223858 8.5 0 8.27614 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C7.72386 16 7.5 15.7761 7.5 15.5C7.5 15.2239 7.72386 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1Z" fill="#001d52"/>
    </svg>
  )
};

export default Generating;