import React from 'react';
import { DatePicker, IDatePickerStyles, IDatePickerProps } from '@fluentui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { BORDER_RADIUS } from 'utils/constants/styles';
import { getTheme } from '@fluentui/react';

interface IMLDatePickerProps extends IDatePickerProps {
  name: string;
  width?: string;
  formRequired?: boolean;
  uncontrolled?: boolean;
  uncontrolledError?: boolean;
}

const MLDatePicker: React.FC<IMLDatePickerProps> = ({
  name,
  width,
  formRequired,
  uncontrolled,
  uncontrolledError,
  ...props
}) => {
  const { control } = useFormContext();
  const theme = getTheme();

  const getDatePickerStyles = (error: boolean): Partial<IDatePickerStyles> => {
    return {
      root: {
        width: width || '100%',
        // height: '3.2rem',
        borderRadius: BORDER_RADIUS,
  
        '*': {
          borderRadius: BORDER_RADIUS,
        }
      },
  
      textField: {
        borderRadius: BORDER_RADIUS,
      },
  
      readOnlyTextField: {
        borderRadius: BORDER_RADIUS
      },
  
      wrapper: {
        borderRadius: BORDER_RADIUS,

        'label': {
          fontSize: '1.2rem',
          color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
          fontWeight: 600,
          padding: 0,
          marginBottom: '0.5rem',
        },
  
        'div[class^="ms-TextField-field field-"]': {
          display: 'flex',
          alignItems: 'center'
        },
  
        '.ms-TextField-fieldGroup': {
          height: '3.2rem',
          border: 'none',
          borderRadius: BORDER_RADIUS,
  
          '*': {
            background: getTheme().palette.neutralLighterAlt,
            borderRadius: BORDER_RADIUS,
          }
        },
  
        'div[class^="ms-TextField-fieldGroup"]::after': {
          borderRadius: BORDER_RADIUS,
        },
      },
      
      statusMessage: {
        display: 'none'
      }
    };
  };

  return (
    <>
      {uncontrolled ? (
        <DatePicker
          {...props}
          styles={getDatePickerStyles(!!uncontrolledError)}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{ required: formRequired ? 'true' : undefined }}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              {...props}
              styles={getDatePickerStyles(!!error)}
              onSelectDate={(date) => field.onChange(date)}
            />
          )}
        />
      )}
    </>
  );
};

export default MLDatePicker;
