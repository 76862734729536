import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IFrequencyClassNames {
  frequencyContainer: string;
  titleLabel: string;
  daysLabel: string;
}

export const getClassNames = (): IFrequencyClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    frequencyContainer: {
      position: 'relative',
      flex: '0 0 15%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    daysLabel: {
      position: 'absolute',
      right: 10,
      bottom: 7.5,
      fontSize: theme.fonts.medium.fontSize,
      color: theme.palette.neutralTertiary
    },
  });
}