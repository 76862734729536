import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IMLHorizontalDividerClassNames {
  horizontalDivider: string;
}

export const getClassNames = (): IMLHorizontalDividerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    horizontalDivider: {
      width: '100%',
      border: 'none',
      borderTop: `1px solid ${theme.palette.neutralLight}`,
    }
  });
};