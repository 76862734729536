import React, { PropsWithChildren } from 'react';
import { DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { IContextualMenuProps, getTheme } from '@fluentui/react';
import { BORDER_RADIUS, BUTTON_FONT_SIZE, BUTTON_FONT_WEIGHT } from 'utils/constants/styles';

interface IMLDefaultButtonProps {
  text?: string;
  fontSize?: string;
  onClick?: () => void;
  colored?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  type?: 'button';
  menuProps?: IContextualMenuProps;
  marginBottom?: boolean;
}

const MLDefaultButton: React.FC<PropsWithChildren<IMLDefaultButtonProps>> = (
  { text, fontSize, onClick, colored, icon, disabled, children, marginBottom }
) => {

  const theme = getTheme();

  const styles: IButtonStyles = {
    root: {
      minWidth: text ? '8.5rem' : '3.8rem',
      height: '3.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: BORDER_RADIUS,
      border: colored ? 'none' : `1px solid ${theme.palette.neutralLight}`,
      fontSize: fontSize || BUTTON_FONT_SIZE,
      fontWeight: BUTTON_FONT_WEIGHT,
      color: colored ? theme.palette.themePrimary : theme.palette.black,
      backgroundColor: colored ? theme.palette.themeLighter : theme.palette.white,
      padding: '0px 12px',
      transition: 'background-color 500ms, color 500ms',
      marginBottom: marginBottom ? '0.1rem' : 0,
    },

    flexContainer: {
      width: '100%',
      justifyContent: 'center',
      gap: 10,
      padding: 0,
    },

    rootHovered: {
      backgroundColor: colored ? theme.palette.themeLight : theme.palette.neutralLight,
      color: colored ? theme.palette.themePrimary : theme.palette.black,
    }
  }

  return (
    <DefaultButton
      onClick={onClick}
      disabled={disabled}
      styles={styles}
    >
      {icon && <span style={{ display: 'flex', alignItems: 'center' }}>{icon}</span>}
      {text && <span>{text}</span>}
      {children}
    </DefaultButton>
  );
};

export default MLDefaultButton;
