import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CourseCategoryDto, GamificationBadgeDto, LmsStatusInfoDto, SettingsDto, TimeZoneDto } from "../../api-client";
import { RootState } from "../store";
import { ApiStatus } from 'common/models/store/api-status';
import { MIMETypes } from "utils/constants/file-types";
import axios from "axios";
import { ApplicationSettings } from "utils/settings/application";

export interface ISettingsState {
  apiStatus: ApiStatus;
  settings: SettingsDto | undefined;
  appSettings: {
    scormSettings: LmsStatusInfoDto | undefined;
  };
  categories: CourseCategoryDto[];
  timeZones: TimeZoneDto[];
  badges: GamificationBadgeDto[];
  error: any;
}

export const setSettings = createAsyncThunk<{settings: SettingsDto | undefined, scormSettings: LmsStatusInfoDto | undefined, categories: CourseCategoryDto[], badges: GamificationBadgeDto[]} | undefined, void>('/setSettings', async (_, thunkAPI) => {
  try {
    const { settingsClientApi, lmsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi || !lmsClientApi) return undefined;

    const promiseResponse = await Promise.allSettled([settingsClientApi.apiSettingsGet(), lmsClientApi.getLmsStatus(), settingsClientApi.getAllCategories(), settingsClientApi.apiSettingsGetBadgesGet()]);

    return {
      settings: promiseResponse[0].status === 'fulfilled' ? promiseResponse[0].value.data : undefined,
      scormSettings: promiseResponse[1].status === 'fulfilled' ? promiseResponse[1].value.data : undefined,
      categories: promiseResponse[2].status === 'fulfilled' ? promiseResponse[2].value.data : [],
      badges: promiseResponse[3].status === 'fulfilled' ? promiseResponse[3].value.data : [],
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
});

export const setTimeZones = createAsyncThunk<TimeZoneDto[], void>('/setTimeZones', async (_, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) return [];

    const response = await settingsClientApi.getTimeZones();
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const updateDomains = createAsyncThunk<string[], string[]>('/updateDomains', async (domains, thunkAPI) => {
  try {
    const { lmsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!lmsClientApi) return [];
    
    await lmsClientApi.setWhitelistedDomains(domains);
    return domains;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const updateCategories = createAsyncThunk<CourseCategoryDto[], CourseCategoryDto[]>('/updateCategories', async (categories, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) return [];

    await settingsClientApi.overrideCategories(categories);
    return categories;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const updateBadges = createAsyncThunk<GamificationBadgeDto[], GamificationBadgeDto[]>('/updateBadges', async (badges, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) return [];

    await settingsClientApi.apiSettingsOverrideBadgesPost(badges);
    return badges;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const getCompanyLogo = createAsyncThunk<{ src: string, contentType: MIMETypes }, void>('/getCompanyLogo', async (_, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) throw new Error();

    const companyLogoResponse = await settingsClientApi.getCompanyLogo({
      responseType: 'arraybuffer'
    });
    const contentType = companyLogoResponse.headers["content-type"];
    if (!contentType) throw new Error();

    const blob = new Blob([(companyLogoResponse.data as unknown as ArrayBuffer)], { type: contentType.toString() });
    const blobUrl = URL.createObjectURL(blob);
    return {
      src: blobUrl,
      contentType
    }
  } catch (err) {
    console.error(err);
    return {
      src: '',
      contentType: undefined,
    }
  }
});

export const uploadCompanyLogo = createAsyncThunk<void, { logo: File }>('/uploadCompanyLogo', async ({ logo }, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) throw new Error();

    const { token } = (thunkAPI.getState() as RootState).account;
    if (!token) throw new Error();

    const formData = new FormData();
    formData.append('attachment', logo);

    await axios.post(`${ApplicationSettings.IAI_APP_API_URL}/api/Settings/UploadCompanyLogo`, {
      attachment: formData.get('attachment'),
    }, { headers: {
      'Authorization': `Bearer ${token}`, // Replace with your actual authorization token
      'Content-Type': 'multipart/form-data',
    }},)
  } catch (err) {
    console.error(err);
  }
});

const initialState: ISettingsState = {
  apiStatus: ApiStatus.IDLE,
  settings: undefined,
  appSettings: {
    scormSettings: undefined,
  },
  categories: [],
  timeZones: [],
  badges: [],
  error: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        settings: action.payload?.settings,
        appSettings: {
          scormSettings: action.payload?.scormSettings,
        },
        categories: action.payload?.categories || [],
        badges: action.payload?.badges || [],
      }
    });
    builder.addCase(setSettings.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.LOADING,
      }
    });
    builder.addCase(setTimeZones.fulfilled, (state, action) => {
      return {
        ...state,
        timeZones: action.payload
      }
    });

    // updateDomains
    builder.addCase(updateDomains.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        appSettings: {
          ...state.appSettings,
          scormSettings: {
            ...state.appSettings.scormSettings,
            whitelistedDomains: action.payload,
          }
        }
      }
    });
    builder.addCase(updateDomains.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SAVING,
      }
    });
    builder.addCase(updateDomains.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.ERROR,
      }
    });

    // updateCategories
    builder.addCase(updateCategories.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        categories: action.payload,
        settings: {
          ...state.settings,
          categories: action.payload,
        }
      }
    });
    builder.addCase(updateCategories.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SAVING,
      }
    });
    builder.addCase(updateCategories.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.ERROR,
      }
    });

    // updateBadges
    builder.addCase(updateBadges.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        settings: {
          ...state.settings,
        },
        badges: action.payload,
      }
    });
    builder.addCase(updateBadges.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SAVING,
      }
    });
    builder.addCase(updateBadges.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.ERROR,
      }
    });

    // uploadCompanyLogo
    builder.addCase(uploadCompanyLogo.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SAVING,
      }
    });
    builder.addCase(uploadCompanyLogo.fulfilled, (state, action) => {
      return {
        ...state,
        companyLogo: action.payload,
        apiStatus: ApiStatus.SUCCESS,
      }
    })
  },
});

export default settingsSlice.reducer;