import { mergeStyleSets } from "@fluentui/react";
import { ANIMATION_DURATION, MODAL_CLOSE_ICON, MODAL_TITLE_FONT_SIZE } from "utils/constants/styles";

interface IFormClassNames {
  form: string;
  formContainer: string;
  header: string;
  title: string;
  closeIcon: string;
}

export const getClassNames = (isTabForm: boolean | undefined, noPaddingRight: boolean | undefined, gap: string | undefined, overflowHidden: boolean | undefined): IFormClassNames => {
  return mergeStyleSets({
    form: {
      overflow: 'hidden',
      width: '100%',
      flex: 1,
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: gap ? gap : '4rem',
    },

    formContainer: {
      overflowY: overflowHidden ? 'hidden' : 'auto',
      overflowX: 'visible',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      scrollbarGutter: 'stable',
      paddingRight: isTabForm || noPaddingRight ? 0 : '1rem',
      transition: `height ${ANIMATION_DURATION}ms`
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: isTabForm ? '0 3rem 0 0' : '0 1.5rem 0 0',
    },

    title: {
      fontSize: MODAL_TITLE_FONT_SIZE,
    },

    closeIcon: {
      cursor: 'pointer',
      fontSize: MODAL_CLOSE_ICON,
      marginTop: 6,
    },
  })
}