import { CourseStatusDto, LightCourseProgressDto } from "api-client";
import { ICourse } from "common/models/ICourse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCourseAfterGeneration, updateGenerationProgress } from "store/slices/courses";
import { RootState, useAppDispatch } from "store/store";

const useGenerating = (courses: ICourse[] | undefined) => {
  const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);
  const dispatch = useAppDispatch();

  // course progesses
  const [courseProgresses, setCourseProgresses] = useState<Record<string, LightCourseProgressDto>>();

  // interval ref
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // ref to track courses being fetched
  const fetchingCoursesRef = useRef<Set<string>>(new Set());

  const fetchSingleCourse = useCallback(async (courseId: string) => {
    // check if the course is already being fetched
    if (fetchingCoursesRef.current.has(courseId)) return;

    // Mark as fetching
    fetchingCoursesRef.current.add(courseId);

    try {
      // dispatch call to getCourse(id) and update the courses state
      await dispatch(getCourseAfterGeneration({ id: courseId }));
    } catch (err) {
      console.error(err);
    } finally {
      // Remove from fetching set once done
      fetchingCoursesRef.current.delete(courseId);
    }
  }, [dispatch]);

  // Polling function to fetch the status of 'generating' courses
  const fetchGeneratingCoursesStatus = useCallback(async () => {
    if (!courses) return;

    const generatingCourses = courses.filter(course => course.status === CourseStatusDto.Generating);
    if (generatingCourses.length === 0) {
      // If no generating courses left, clear the interval
      if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null; // Reset the ref
      }
      return;
    }

    const response = await coursesClientApi?.getCoursesProgress();
    if (!response?.data) return;
    const progressData: Record<string, LightCourseProgressDto> = response.data;
    setCourseProgresses(progressData);

    const coursesToUpdate = courses.filter(course => {
      if (course.id && progressData[course.id]) {
        const progress = progressData[course.id];
        dispatch(updateGenerationProgress({ id: course.id, progress: progress.progressPercentage || 0 }));
        return course.status === CourseStatusDto.Generating && progress.status !== CourseStatusDto.Generating
      } else return false; 
    }).map(course => course.id || '');

    // Fetch full course details for those that changed from generating to draft
    coursesToUpdate.forEach(courseId => {
        fetchSingleCourse(courseId);
    });
  }, [courses, coursesClientApi, fetchSingleCourse, dispatch]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      fetchGeneratingCoursesStatus();
    }, 10000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Clean up interval on unmount
      }
    };
  }, [fetchGeneratingCoursesStatus]);

  return {
    courseProgresses
  }
};

export default useGenerating;