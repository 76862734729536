import { getClassNames } from "./audio-player.classNames";
import useAudioPlayer from "./hooks/useAudioPlayer";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { LessonDto } from "../../../../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { useParams } from "react-router-dom";

interface IAudioPlayerProps {
  lesson: LessonDto;
  onPlayAudioLessonId: string;
  setAudioLessonId: (id: string) => void;
}

const AudioPlayer = (props: IAudioPlayerProps) => {
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const { lang } = useParams();

   const {
    audioSource,
    isLoading,
    audioRef, 
    progressBarRef, 
    onLoadedMetadata, 
    isPlaying, 
    onPlayClick, 
    onPauseClick, 
    timeProgress, 
    duration, 
    onProgressChange, 
    onVolumeClick
  } = useAudioPlayer(props.lesson, courseId, lang || documentLanguage, props.onPlayAudioLessonId, props.setAudioLessonId );

  const classes = getClassNames(timeProgress, audioRef.current?.duration);

  const getFormattedTime = () => {
    if (!duration) return '';

    const timeProgressMinutes = 
      Math.floor(timeProgress / 60) > 0 && Math.floor(timeProgress / 60) < 10 
      ? `0${Math.floor(timeProgress / 60)}` 
      : `0${Math.floor(timeProgress / 60)}`;

    const timeProgressSeconds = 
      +(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0) > 9 
      ? `${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}` 
      : `0${(timeProgress - Math.floor(timeProgress / 60) * 60).toFixed(0)}`;

    const timeDurationMinutes = Math.floor(duration / 60) > 0 && Math.floor(timeProgress / 60) < 10 
    ? `0${Math.floor(duration / 60)}` 
    : `0${Math.floor(duration / 60)}`;

    const timeDurationSeconds = 
      (duration - Math.floor(duration / 60) * 60) > 9 
      ? `${(duration - Math.floor(duration / 60) * 60).toFixed(0)}` 
      : `0${(duration - Math.floor(duration / 60) * 60).toFixed(0)}`;
    
    return `${timeProgressMinutes}:${timeProgressSeconds} / ${timeDurationMinutes}:${timeDurationSeconds}`;
  };

  const getIconStatus = () => {
    if (isLoading) return <Spinner size={SpinnerSize.small} style={{marginRight: 2}} />
    if (isPlaying) return <Icon iconName="Pause" className={classes.icon} onClick={onPauseClick} />;
    else return <Icon iconName="Play" className={classes.icon} onClick={onPlayClick} />
  };

  const getDurationStatus = () => {
    // if (duration === null || isLoading) return <Spinner size={SpinnerSize.small} />;
    if (duration === null || isLoading) return '--:-- / --:--';
    else return getFormattedTime();
  }

  return (
    <div className={classes.audioPlayerContainer} draggable onDragStart={(e) => {e.preventDefault(); e.stopPropagation()}}>
      <audio 
        ref={audioRef} 
        src={audioSource}
        onLoadedMetadata={onLoadedMetadata}/>
      <div className={classes.progressBarContainer}>
        <input type="range" ref={progressBarRef} defaultValue='0' step={0.01} onChange={onProgressChange} />
      </div>
      <div className={classes.statusContainer}>
        <div className={classes.controlsContainer}>
          {getIconStatus()}
          {audioRef && audioRef.current && audioRef.current.muted ? (
            <Icon iconName="Volume0" className={classes.icon} onClick={onVolumeClick} />
            ) : (
            <Icon iconName="Volume3" className={classes.icon} onClick={onVolumeClick} />
          )}
        </div>
        <div className={classes.timeProgressContainer}>{getDurationStatus()}</div>
      </div>
    </div>
  )
};

export default AudioPlayer;