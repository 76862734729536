// import react
import { createContext, useCallback, useState } from 'react';

export interface ILoadingContext {
  isLoading: boolean;
  label: string;
  startLoading: (label: string) => void;
  stopLoading: () => void;
}

export const LoadingContext = createContext<ILoadingContext>({
  isLoading: false,
  label: '',
  startLoading: () => {},
  stopLoading: () => {},
});

export const LoadingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');

  // const startLoadingRedirect = () => setIsRedirect(true);
  const startLoading = useCallback((label: string) => {
    setLabel(label);
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => setIsLoading(false), []);

  return (
    <LoadingContext.Provider
      value={{ 
        isLoading,
        label,
        startLoading,
        stopLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
};