import { mergeStyleSets } from '@fluentui/react';
import { ANIMATION_DURATION } from 'utils/constants/styles';

interface IAppLayoutClassNames {
  appLayout: string;
  main: string;

  pageEnter: string;
  pageEnterActive: string;
  pageExit: string;
  pageExitActive: string;
}

export const getClassNames = (): IAppLayoutClassNames => {
  return mergeStyleSets({
    appLayout: {
      overflow: 'hidden',
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
    },

    main: {
      overflow: 'hidden',
      position: 'relative',
      flex: 1,
    },

    // menuIcon: {
    //   fontSize: theme.fonts.xLarge.fontSize,
    //   cursor: 'pointer',
    //   position: 'absolute',
    //   top: 10,
    //   left: 10,
    //   zIndex: 2,
    // },

    pageEnter: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    
    pageEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `opacity ${ANIMATION_DURATION}ms, transform ${ANIMATION_DURATION}ms`,
    },
    
    pageExit: {
      opacity: 1,
      transform: 'scale(1)',
    },
    
    pageExitActive: {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: `opacity ${ANIMATION_DURATION}ms, transform ${ANIMATION_DURATION}ms`,
    },
  })
}