import { getClassNames } from './SelectedDocument.classNames';
// import Pdf from 'assets/icons/various/files/Pdf/Pdf';
import { IDocument } from '../../Document';
import Pdf from 'assets/ml-icons/Others/Pdf/Pdf';
import Docx from 'assets/ml-icons/Others/Docx/Docx';
// import Docx from 'assets/icons/various/files/Docx/Docx';

interface ISelectedDocumentProps {
  selectedFile: IDocument;
}

const SelectedDocument: React.FC<ISelectedDocumentProps> = ({ selectedFile }) => {
  const classes = getClassNames();

  return (
    <div className={classes.selectedDocument}>
      {selectedFile.type === 'application/pdf' && <Pdf />}
      {selectedFile.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && <Docx />}
      <label className={classes.label}>{selectedFile.name}</label>
    </div>
  );
}

export default SelectedDocument;