import { mergeStyleSets, getTheme } from "@fluentui/react";

interface ICircularProgressBarClassNames {
  progressCircle: string;
  progressBg: string;
  progress: string;
  thumb: string;
}

export const getClassNames = (): ICircularProgressBarClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    progressCircle: {
      transform: 'rotate(-90deg)',
    },

    progressBg: {
        
    },

    progress: {

    },

    thumb: {
      fill: theme.palette.themePrimary,
    },
  });
};