import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getClassNames } from './Logo.classNames';
import { useFormContext } from 'react-hook-form';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from 'utils/constants/styles';
import { isFileTypeCorrect } from 'utils/helpers/helpers';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { Spinner, SpinnerSize } from '@fluentui/react';

export interface ILogo {
  name: string;
  type: string;
};

const Logo: React.FC = () => {
  const settingsClientApi = useSelector((state: RootState) => state.clients.clients.settingsClientApi);
  const { register, watch, setValue, formState: { errors } } = useFormContext();

  const logo = watch('company.logo');

  const [isLoading, setIsLoading] = useState<boolean>(logo === undefined);
  const [src, setSrc] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const loadingRef = useRef<HTMLDivElement>(null);
  const noFileRef = useRef<HTMLDivElement>(null);
  const selectedFileRef = useRef<HTMLDivElement>(null);
  const nodeRef = isLoading ? loadingRef : src ? selectedFileRef : noFileRef;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.files?.[0] && isFileTypeCorrect(e.dataTransfer.files[0])) {
      const chosenFile = e.dataTransfer.files?.[0];
      if (chosenFile) {
        const blob = new Blob([chosenFile]);
        const blobUrl = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          if (!chosenFile) return;
          setValue('company.logo', chosenFile, { shouldDirty: true });
        };
        img.src = blobUrl;
        setSrc(blobUrl);
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.[0] && isFileTypeCorrect(e.currentTarget.files[0])) {
      const chosenFile = e.currentTarget.files?.[0];
      if (chosenFile) {
        const blob = new Blob([chosenFile]);
        const blobUrl = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          if (!chosenFile) return;
          setValue('company.logo', chosenFile, { shouldDirty: true });
        };
        img.src = blobUrl;
        setSrc(blobUrl)
      }
    }
  };

  const fetchCompanyLogo = useCallback(async () => {
    try {
      const companyLogoResponse = await settingsClientApi?.getCompanyLogo({
        responseType: 'arraybuffer'
      });
      const companyLogo = companyLogoResponse?.data;
      if (!companyLogo) {
        return
      };

      const logoContentType = companyLogoResponse.headers['content-type'];
      const blob = new Blob([(companyLogoResponse.data as unknown as ArrayBuffer)], { type: logoContentType });
      const blobUrl = URL.createObjectURL(blob);
      const img = new Image();
        img.onload = () => {
          if (!companyLogo) return;
          setValue('company.logo', new File([companyLogo], 'logo', { type: logoContentType }));
        };
        img.src = blobUrl;
        setSrc(blobUrl);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [settingsClientApi, setValue]);

  useEffect(() => {
    if (logo === undefined) fetchCompanyLogo()
  }, [fetchCompanyLogo, logo]);

  const classes = getClassNames(!!errors.document);

  return (
    <div className={classes.logoSettings}>
      <div
        className={classes.logoContainer}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        <input
         type='file'
         id='logo'
         style={{ display: 'none' }}
         {...register('company.logo')}
         onChange={handleFileChange}
         accept="image/*"
         ref={inputRef}
       />

        <SwitchTransition>
          <CSSTransition
            nodeRef={nodeRef}
            key={isLoading ? "spinner" : src ? src : "addLogo"}
            timeout={ANIMATION_DURATION}
            classNames={{
              enter: classes.documentEnter,
              enterActive: classes.documentEnterActive,
              exit: classes.documentExit,
              exitActive: classes.documentExitActive,
            }}
          >
            <div ref={nodeRef} className={classes.logo}>
              {isLoading && <Spinner size={SpinnerSize.large} />}
              {src && !isLoading && <img src={src} alt="" className={classes.logoImage} />}
              {!src && !isLoading && <label className={classes.label}>Drag a logo here or click to select</label>}
            </div>
          </CSSTransition>
        </SwitchTransition>
        
      </div>
    </div>
  );
}

export default Logo;