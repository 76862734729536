import { mergeStyleSets, getTheme } from "@fluentui/react"
import { ANIMATION_DURATION, BORDER_RADIUS } from "utils/constants/styles";

interface IQuizLessonClassNames {
  selectedQuestionContainer: string;
  titleGroupInput: string;
  titleInput: string;
  titleLabel: string;
  questionGroupInput: string;
  questionInput: string;
  optionsGroupInput: string;
  optionGroupInput: string;
  optionContainer: string;
  optionInput: string;
  newOptionContainer: string;
  newOptionInput: string;
  correctAnswerGroupInput: string;
  dropdownAnswerContainer: string;
  correctAnswerExplainationGroupInput: string;
  correctAnswerExplainationInput: string;

  questionEnter: string;
  questionEnterActive: string;
  questionExit: string;
  questionExitActive: string;
}

export const getClassNames = (disabled: boolean): IQuizLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    selectedQuestionContainer: {
      overflow: 'auto',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      paddingRight: '1rem',
    },

    titleGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    questionGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    questionInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    optionsGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10
    },

    optionGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },

    optionInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    newOptionContainer: {
      marginTop: 20,
      paddingLeft: 34,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10
    },

    newOptionInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    correctAnswerGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    dropdownAnswerContainer: {
      width: 300,
      flex: '0 0 40px',
      display: 'flex',
      alignItems: 'center',
      opacity: disabled ? 0.3 : 1,
      ...(disabled && {
        pointerEvents: 'none'
      })
    },

    correctAnswerExplainationGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    correctAnswerExplainationInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    questionEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    questionEnterActive: {
      transform: 'scale(1)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    questionExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    questionExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};