import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IForwardClassNames {
  'iconContainer': string;
  numberContainer: string;
}

export const getClassNames = (): IForwardClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'iconContainer': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '*': {
        transition: 'all 300ms',
      },

      ':hover': {
        '*': {
          color: theme.palette.themePrimary,
          fill: theme.palette.themePrimary,
          transition: 'all 300ms',
        }
      }
    },

    numberContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 600,
      color: theme.palette.neutralSecondary
    }
  });
}