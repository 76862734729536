import { UserDto } from "../../api-client";
import { IUser } from "common/models/LearningDetails/user";

export const mapUsers = (users: UserDto[]): IUser[] => {
  return users.map((user): IUser => ({
    text: user.displayName || '',
    key: user.id,
    imageUrl: '',
    showSecondaryText: false,
    userType: user.type
  }))
};