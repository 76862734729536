import MLModal from 'common/ml-components/MLModal';
import MLForm from 'common/ml-components/MLForm';
import { FinalQuizLessonDto } from "api-client";
import { useState } from "react";
import Navigation from "./components/Navigation/Navigation";
import Quiz from "./components/Quiz/Quiz";
import _ from 'lodash';
import { useAppDispatch } from 'store/store';
import { editFinalQuiz } from 'store/slices/courses';
import { useParams } from 'react-router-dom';


interface IFinalQuizProps {
  show: boolean;
  onDismiss: () => void;
  finalQuiz: FinalQuizLessonDto;
}

const FinalQuiz: React.FC<IFinalQuizProps> = ({ show, onDismiss, finalQuiz }) => {
  const dispatch = useAppDispatch();
  const { lang } = useParams();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const defaultValues: FinalQuizLessonDto = {
    ...finalQuiz,
  };

  const onSubmit = async (data: FinalQuizLessonDto) => {
    try {
      await dispatch(editFinalQuiz({  finalQuiz: data, lang })).unwrap();
      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  const onNavigationClick = (newIndex: number) => {
    setSelectedQuestionIndex(newIndex);
    // setSelectedQuestionId(finalQuiz?.quizes?.[newIndex]?.id || ''); // CHANGE!
  };
  
  const compareData = (data: FinalQuizLessonDto) => {
    return !_.isEqual(data, defaultValues);
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width='110rem'
    >
      <MLForm
        title='Final quiz'
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
        noPaddingRight
        noActionButtons
        overflowHidden
        gap="2rem"
      >
        <Navigation
          selectedQuestionIndex={selectedQuestionIndex}
          onNavigationClick={onNavigationClick}
        />
        <Quiz
          selectedQuestionIndex={selectedQuestionIndex}
          // selectedQuestionId={selectedQuestionId}
          onChangeQuestion={onNavigationClick}
          onDismiss={onDismiss}
          compareData={compareData}
        />
      </MLForm>
    </MLModal>
  )
};

export default FinalQuiz;