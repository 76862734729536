import { IShimmerColors, Shimmer, getTheme } from "@fluentui/react";

const Shimmers: React.FC = () => {
  const theme = getTheme();

  const shimmerColors: IShimmerColors = {
    shimmer: theme.palette.neutralQuaternary,
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '2rem', height: '3rem', }}>
        <Shimmer
          shimmerColors={shimmerColors}
          styles={{
            shimmerWrapper: {
              height: '100%',
              borderRadius: 5
            },
          }}
          style={{ flex: '0 0 20rem', height: '100%' }}
        />
        <Shimmer
          shimmerColors={shimmerColors}
          styles={{
            shimmerWrapper: {
              height: '100%',
              borderRadius: 20
            },
          }}
          style={{ flex: '0 0 10rem', height: '100%' }}
        />
      </div>
    </>
  );
};

export default Shimmers;