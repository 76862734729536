// import styles
import { getClassNames } from "./DateTime.classNames";

import MLDatePicker from "common/ml-components/pickers/MLDatePicker/MLDatePicker";
import MLTimePicker from "common/ml-components/pickers/MLTimePicker/MLTimePicker";
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface IDateTimeProps {}

const DateTime = (props: IDateTimeProps) => {
  const { timeZones } = useSelector((state: RootState) => state.settings);

  const classes = getClassNames();

  return (
      <div className={classes.dateTimeContainer}>
        <div style={{ flex: '0 0 19rem', overflow: 'hidden' }}>
          <MLDatePicker
            name='startDate'
            label='Start Date'
            formRequired
          />
        </div>

        <div style={{ flex: '0 0 9rem', overflow: 'hidden' }}>
          <MLTimePicker
            name='time'
            label='Time'
            formRequired
          />
        </div>

        <div style={{ flex: 1, overflow: 'hidden' }}>
          <MLDropdown
            name='timeZone'
            label='Time zone'
            formRequired
            dropdownStyle={DropdownStyles.LIGHT}
            dropdownWidth={"auto"}
            paddingRight="5rem"
            options={timeZones.map(timeZone => ({key: timeZone.standardName || '', text: timeZone.displayName || '' }))}
          />
        </div>
      </div>
  )
};

export default DateTime;