import { IDropdownOption } from "@fluentui/react";
import { LessonTypeDto } from "../../api-client";

export const lessonTypesOptions: IDropdownOption[] = [
    {
        key: LessonTypeDto.Text,
        text: "Text"
    },
    {
        key: LessonTypeDto.BulletPoints,
        text: "Bullet points"
    },
    {
        key: LessonTypeDto.Quiz,
        text: "Quiz"
    },
    {
        key: LessonTypeDto.FlashCard,
        text: "Flash card"
    },
    {
        key: LessonTypeDto.AudioOnly,
        text: "Audio only"
    }
];