import { getClassNames } from './CourseInfo.classNames';

// import components
import MLTextField from 'common/ml-components/inputs/MLTextField';

const CourseInfo: React.FC = () => {
  const classes = getClassNames();

  return (
    <div className={classes.courseInfoContainer}>
      <MLTextField
        name='title'
        label='Title'
        formRequired
      />
      <MLTextField
        name='description'
        label='Description'
        formRequired
        multiline
        rows={5}
        autoAdjustHeight={false}
        resizable={false}
      />
    </div>
  );
};

export default CourseInfo;