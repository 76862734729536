import { mergeStyleSets } from "@fluentui/react"
import { ANIMATION_DURATION } from "utils/constants/styles";

interface IAttachmentClassNames {
  attachmentContainer: string;
  closeIcon: string;

  contentEnter: string;
  contentEnterActive: string;
  contentExit: string;
  contentExitActive: string;
}

export const getClassNames = (): IAttachmentClassNames => {
  return mergeStyleSets({
    attachmentContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem',
    },

    closeIcon: {
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
    },

    contentEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    contentEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    contentExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    contentExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};