import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IDropdownOption } from '@fluentui/react';
import { getClassNames } from './ScormLanguages.classNames';
import MLCheckbox from 'common/ml-components/inputs/MLCheckbox';
import MLDropdown, { DropdownStyles } from 'common/ml-components/inputs/MLDropdown';

interface IScormLanguagesProps {
  languages: IDropdownOption[];
}

const ScormLanguages: React.FC<IScormLanguagesProps> = ({ languages }) => {
  const { setValue } = useFormContext();
  const [allLanguages, setAllLanguages] = React.useState(true);

  const onRenderLanguagesTitle = (options: any) => {
    if (!options.length) return <div></div>
    if (options.length === 1) return <div>{`${options.length} language selected`}</div>
    return <div>{`${options.length} languages selected`}</div>
  };

  const classes = getClassNames();

  return (
    <div className={classes.scormLanguagesContainer}>
      <div className={classes.optionContainer}>
        <MLCheckbox
          name='allLanguages'
          label='Include all languages'
          uncontrolled
          onChange={(event, checked) => {
            setAllLanguages(checked || false);
            if (checked) setValue('specifiedLanguages', languages.map((lang: IDropdownOption) => lang.key as string));
          }}
          checked={allLanguages}
          boxSide='end'
        />
      </div>
      <div className={classes.optionContainer}>
        <MLDropdown
          name='specifiedLanguages'
          label='Languages'
          formRequired
          options={languages}
          multiSelect
          onRenderTitle={onRenderLanguagesTitle}
          dropdownWidth={250}
          dropdownStyle={DropdownStyles.LIGHT}
          disabled={allLanguages}
          leftLabel
        />
        {/* <h4 className={errors['supportedLanguages'] ? `${classes.optionTitle} ${classes.optionTitleError}` : classes.optionTitle}>Languages</h4> */}
        {/* <Controller
          name={'supportedLanguages'}
          control={control}
          rules={{ validate: (value) => (
            value.filter((lang: IDropdownOption) => lang.selected).length > 0 || 'Required'
          ) }}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <Dropdown
              name={name} 
              value={value}
              options={languages}
              onChange={(event, option) => {
                const selectedKeys = value.map((lang: IDropdownOption) => {
                  if (lang.key === option.key) return { ...lang, selected: !lang.selected }
                  else return lang;
                });
                onChange(selectedKeys);

              }}
              width='25rem'
              style={DropdownStyles.LIGHT}
              error={error}
              multiSelect
              disabled={includeAllLanguages}
              onRenderTitle={onRenderLanguagesTitle}
            />
          )}
        /> */}
      </div>
    </div>
  );
};

export default ScormLanguages;
