import { mergeStyleSets } from "@fluentui/react"

interface IQuestionListClassNames {
  questionListContainer: string;
}

export const getClassNames = (): IQuestionListClassNames => {
  return mergeStyleSets({
    questionListContainer: {
      overflow: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      paddingRight: '1rem',
    },
  });
};