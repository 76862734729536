import { mergeStyleSets, getTheme } from '@fluentui/react';
import { TEXT_FONT_SIZE } from 'utils/constants/styles';
import { BORDER_RADIUS } from 'utils/constants/styles';

interface IDocumentClassNames {
  selectedDocument: string;
  label: string;
}

export const getClassNames = (): IDocumentClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    selectedDocument: {
      width: '100%',
      height: '100%',
      padding: '0 1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      color: theme.palette.black,
      background: theme.palette.white,
      borderRadius: BORDER_RADIUS,
      boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.1)'
    },
    label: {
      fontSize: TEXT_FONT_SIZE,
      cursor: 'pointer',
      height: '100%',
      marginTop: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};