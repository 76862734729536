import { useCallback, useContext, useState } from 'react';

// import styles
import { getClassNames } from "./Learning.classNames";

// import components
import Header from "./components/LearningHeader/LearningHeader";
import Lessons from "./components/Lessons/lessons";
import MLDialog from 'common/ml-components/MLDialog';

// import hooks
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import redux
import { RootState, useAppDispatch } from 'store/store';
import { cleanCourse, cloneCourse, deleteCourse, deleteLesson, getCourse, publishCourse, setLessonToEdit, unpublishCourse, generateFinalQuiz } from 'store/slices/courses';

// import forms
import CourseConfigurations from './components/Forms/CourseConfigurations';
import EditLesson from './components/Forms/EditLesson';
import Schedule from './components/Forms/Schedule';
import ScormDetails from './components/Forms/ScormDetails/ScormDetails';
import FinalQuiz from './components/Forms/FinalQuiz/FinalQuiz';
import Banners from './components/Banners/Banners';
import { useSelector } from 'react-redux';
import { ANIMATION_DURATION } from 'utils/constants/styles';
import NewLesson from './components/Forms/NewLesson';
import GenerateLesson from './components/Forms/GenerateLesson/GenerateLesson';
import { timeout } from 'utils/helpers/helpers';
import { LoadingContext } from 'contexts/loading-context';
import { CourseStatusDto, FileType, FinalQuizLessonDto, LessonTypeDto } from 'api-client';

import saveAs from 'file-saver';

const Learning = () => {
  const course = useSelector((state: RootState) => state.courses.course);
  const defaultStatus = useSelector((state: RootState) => state.courses.course?.status);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const justGenerated = useSelector((state: RootState) => state.courses.course?.statusInfo?.justGenerated);
  const errors = useSelector((state: RootState) => state.courses.course?.errors);
  const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);
  const dispatch = useAppDispatch();
  const { id, lang } = useParams();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const [showDeleteLesson, setShowDeleteLesson] = useState<boolean>(false);
  const [showDeleteCourse, setShowDeleteCourse] = useState<boolean>(false);
  const [showEditLesson, setShowEditLesson] = useState<boolean>(false);
  const [showNewLesson, setShowNewLesson] = useState<boolean>(false);
  const [showCourseConfigurations, setShowCourseConfigurations] = useState<boolean>(false);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [showScormDetails, setShowScormDetails] = useState<boolean>(false);
  const [showFinalQuiz, setShowFinalQuiz] = useState<boolean>(false);
  const [generateLesson, setGenerateLesson] = useState<boolean>(false);
  const [publishOrUnpublish, setPublishOrUnpublish] = useState<boolean>(false);
  const [clone, setClone] = useState<boolean>(false);
  const [finalQuiz, setFinalQuiz] = useState<boolean>(false);

  const init = useCallback(async () => {
    try {
      if (!!course) return;
      if (!id) return;
      await dispatch(getCourse({ id, lang })).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [course, id, lang, dispatch]);

  const onDeleteLesson = async () => {
    try {
      await dispatch(deleteLesson({ lang })).unwrap();
      setShowDeleteLesson(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onDeleteCourse = async () => {
    try {
      await dispatch(deleteCourse()).unwrap();
      setShowDeleteCourse(false);
      startLoading('Redirecting to the learnings page...');
      await timeout(2000);
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  };

  const publishOrUnpublishCourse = async () => {
    try {
      setPublishOrUnpublish(false);
      if (course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running) {
        startLoading('Unpublishing the course')
        await dispatch(unpublishCourse())
      } else {
        startLoading('Publishing the course')
        await dispatch(publishCourse());
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onCloneCourse = async () => {
    try {
      setClone(false);
      startLoading('Cloning the course...');
      await dispatch(cloneCourse()).unwrap();
      startLoading('Redirecting to the learnings page...');
      await timeout(6000);
      // await dispatch(getCourses()).unwrap();
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  };

  const onGenerateFinalQuiz = async () => {
    try {
      await dispatch(generateFinalQuiz({ lang: lang || course?.documentLanguage || '' })).unwrap();
      setFinalQuiz(false);
      startLoading('Redirecting to the learnings page...');
      await timeout(6000);
      // await dispatch(getCourses()).unwrap();
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  };

  const onDownloadDocument = async () => {
    if (!course?.id) return;

    try {
      startLoading('Downloading the document...');
      const data = await coursesClientApi?.downloadCourseDocument(course.id, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([(data?.data as unknown as ArrayBuffer)]);
      let filename;
      if (course.generatedByFileType === FileType.Pdf) filename = `${course.title?.replaceAll(' ', '')}.pdf`;
      if (course.generatedByFileType === FileType.Docx) filename = `${course.title?.replaceAll(' ', '')}.docx`;
      if (course.generatedByFileType === FileType.Pptx) filename = `${course.title?.replaceAll(' ', '')}.pptx`;
      saveAs(blob, filename);
      stopLoading();
    } catch (err) {
      console.error(err);
    }
  };

  const dialogs = [
    {
      title: 'Delete lesson',
      content: 'Are you sure you want to delete the lesson?',
      onConfirm: onDeleteLesson,
      show: showDeleteLesson,
      onDismiss: () => setShowDeleteLesson(false),
    },
    {
      title: 'Delete course',
      content: 'Are you sure you want to delete the course?',
      show: showDeleteCourse,
      onConfirm: onDeleteCourse,
      onDismiss: () => setShowDeleteCourse(false),
    },
    {
      title: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running ? 'Unpublish the course' : 'Publish the course',
      content: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running ? 'Are you sure you want to unpublish the course?' : 'Are you sure you want to publish the course?',
      show: publishOrUnpublish,
      onConfirm: publishOrUnpublishCourse,
      onDismiss: () => setPublishOrUnpublish(false),
    },
    {
      title: 'Clone course',
      content: 'Are you sure you want to clone the course?',
      show: clone,
      onConfirm: onCloneCourse,
      onDismiss: () => setClone(false),
    },
    {
      title: 'Generate final quiz',
      content: 'Are you sure you want to generate the final quiz?',
      show: finalQuiz,
      onConfirm: onGenerateFinalQuiz,
      onDismiss: () => setFinalQuiz(false),
    },
  ];

  const finalQuizLesson = course?.lessons?.find(lesson => lesson.type === LessonTypeDto.FinalQuiz) as FinalQuizLessonDto;

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    return () => {
      dispatch(cleanCourse());
    }
  }, [dispatch]);

  const classes = getClassNames();

  return ( 
    <section className={classes.learningViewPage}>
      <Banners
        status={lang ? supportedLanguages?.find(language => language.language === lang)?.status : defaultStatus}
        errors={errors}
        justGenerated={!!justGenerated}
      />

      <Header
        status={lang ? supportedLanguages?.find(language => language.language === lang)?.status : defaultStatus}
        onConfigureClick={() => setShowCourseConfigurations(true)}
        onScheduleClick={() => setShowSchedule(true)}
        onCourseDeleteClick={() => setShowDeleteCourse(true)}
        onExportScormClick={() => setShowScormDetails(true)}
        onFinalQuizClick={() => setShowFinalQuiz(true)}
        onNewLessonClick={() => setShowNewLesson(true)}
        onGenerateLessonClick={() => setGenerateLesson(true)}
        onPublishOrUnpublishClick={() => setPublishOrUnpublish(true)}
        onCloneCourseClick={() => setClone(true)}
        onDownloadDocumentClick={onDownloadDocument}
        onGenerateFinalQuizClick={() => setFinalQuiz(true)}
      />

      <Lessons
        lessons={course?.lessons?.filter(lesson => lesson.type !== LessonTypeDto.FinalQuiz) || []}
        onEditLessonClick={() => setShowEditLesson(true)}
        onDeleteClick={() => setShowDeleteLesson(true)}
      />

      <EditLesson
        show={showEditLesson}
        onDismiss={() => {
          setShowEditLesson(false);
          setTimeout(() => dispatch(setLessonToEdit(undefined)), ANIMATION_DURATION);
        }}
      />

      <NewLesson
        show={showNewLesson}
        onDismiss={() => {
          setShowNewLesson(false);
        }}
      />

      <CourseConfigurations
        show={showCourseConfigurations}
        onDismiss={() => setShowCourseConfigurations(false)}
      />

      <Schedule
        show={showSchedule}
        onDismiss={() => setShowSchedule(false)}
      />

      {finalQuizLesson &&
        <FinalQuiz
          show={showFinalQuiz}
          onDismiss={() => setShowFinalQuiz(false)}
          finalQuiz={finalQuizLesson}
        />
      }

      <ScormDetails
        show={showScormDetails}
        onDismiss={() => setShowScormDetails(false)}
      />

      <GenerateLesson
        show={generateLesson}
        onDismiss={() => setGenerateLesson(false)}
      />

      {dialogs.map((dialog) => (
        <MLDialog
          key={dialog.title}
          title={dialog.title}
          text={dialog.content}
          show={dialog.show}
          onConfirm={dialog.onConfirm}
          onCancel={dialog.onDismiss}
        />
      ))}

    </section>
  )
}

export default Learning;