// import styles
import { getClassNames } from "./Header.classNames";

// import components
import Search from "assets/ml-icons/Controls/Search/search";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { ApiStatus } from "common/models/store/api-status";

interface IHeaderProps {
  onMenuClick: () => void;
  search: string;
  updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Header = (props: IHeaderProps) => {
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);

  const classes = getClassNames(
    apiStatus.status !== ApiStatus.SUCCESS &&
    apiStatus.status !== ApiStatus.IDLE
  );

  return (
    <div className={classes.header}>
      <div className={classes.pageTitleContainer}>
        <h1 className={classes.title}>Learnings</h1>
      </div>
      <div className={classes.searchBarContainer}>
        <input type="text" className={classes.searchBar} placeholder="Search learnings" value={props.search} onChange={props.updateSearch} />
        <Search />
      </div>
    </div>
  );
}

export default Header;