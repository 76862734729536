import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS } from "utils/constants/styles";

interface ICourseOptionsNames {
  courseOptionsContainer: string;
  titleLabel: string;
  textInput: string;
  textArea: string;
}

export const getClassNames = (): ICourseOptionsNames => {
  const theme = getTheme();

  return mergeStyleSets({
    courseOptionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4rem',
      paddingRight: '1rem',
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    textInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none'
    },

    textArea: {
      resize: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
    },
  });
};