import { createRef, useMemo, useState } from 'react';

// import components
import MLModal from 'common/ml-components/MLModal';
import MLTabForm from 'common/ml-components/MLTabForm';
import Company from './components/Company/Company';

// import react-hook-form
import { SubmitHandler } from 'react-hook-form';

// import redux
import { useAppDispatch } from 'store/store';

// import helpers
import _ from "lodash";

// import models
import { IMLTab } from 'common/ml-components/MLTabForm/MLTabForm';
import { uploadCompanyLogo } from 'store/slices/settings';

interface IGeneralSettingsProps {
  show: boolean;
  onDismiss: () => void;
}

export interface IGeneralSettingsFormData {
  company: {
    logo: File | undefined;
  }
}

const GeneralSettings: React.FC<IGeneralSettingsProps> = ({ show, onDismiss }) => {
  const dispatch = useAppDispatch();

  const defaultValues: IGeneralSettingsFormData = useMemo(() => ({
    company: {
      logo: undefined,
    },
  }), []);

  const updateHeight = (key: string, height: number) => {
    setTabs(prevTabs => {
      return prevTabs.map(tab => {
        if (tab.key === key) {
          return {
            ...tab,
            height
          };
        }
        return tab;
      });
    });
  };

  const [tabs, setTabs] =  useState<IMLTab[]>([
    {
      key: 'company',
      label: 'Company',
      component: <Company updateHeight={(height: number) => updateHeight('company', height)} />,
      ref: createRef(),
      height: 'auto'
    }
  ]);

  const compareData = (data: IGeneralSettingsFormData) => {
    return !_.isEqual(data.company, defaultValues.company);
  };

  const onSubmit: SubmitHandler<IGeneralSettingsFormData> = async (data) => {
    try {
      if (!_.isEqual(data.company.logo, defaultValues.company.logo)) {
        if (!data.company.logo) return;
        await dispatch(uploadCompanyLogo({ logo: data.company.logo })).unwrap();
      }
      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width="50rem"
      isTabForm
    >
      <MLTabForm<IGeneralSettingsFormData>
        title="General settings"
        defaultValues={defaultValues}
        tabs={tabs}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
      />
    </MLModal>
  )
};

export default GeneralSettings;