import { mergeStyleSets, keyframes } from '@fluentui/react';
import { FADE, IFadeClassNames } from 'utils/constants/styles';

interface ILearningsPageClassNames extends IFadeClassNames {
  learningsPage: string;
  menuOpened: string;
  menuClosed: string;
  content: string;
}

export const getClassNames = (): ILearningsPageClassNames => {
  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    learningsPage: {
      width: '100%',
      padding: '20px 40px',
      overflow: 'hidden',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
      transition: 'all 350ms ease-in-out',
    },

    content: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
    },

    menuOpened: {
      gridTemplateColumns: '300px 1fr',
    },

    menuClosed: {
      gridTemplateColumns: '0 1fr',
    },

    ...FADE.classNames,
  });
};