import React, { useMemo } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { ScormStandard } from 'api-client';
import { getClassNames } from './ScormVersion.classNames';
import MLDropdown, { DropdownStyles } from 'common/ml-components/inputs/MLDropdown';

interface IScormVersionProps {}

const ScormVersion: React.FC<IScormVersionProps> = () => {
  const scormVersions: IDropdownOption[] = useMemo(() => [
    {
      key: ScormStandard.V12,
      text: '1.2',
    },
    {
      key: ScormStandard.V20043ed,
      text: '2004 3rd Edition',
    },
    {
      key: ScormStandard.V20044ed,
      text: '2004 4th Edition',
    },
  ], []);

  const classes = getClassNames();

  return (
    <div className={classes.optionContainer}>
      <MLDropdown
        name={'scormVersion'}
        label='Scorm Version'
        dropdownWidth={200}
        options={scormVersions}
        dropdownStyle={DropdownStyles.LIGHT}
        leftLabel
      />
    </div>
  );
};

export default ScormVersion;
