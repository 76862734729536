import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker, ITimePickerProps, getTheme, IComboBoxStyles } from '@fluentui/react';
import { BORDER_RADIUS } from 'utils/constants/styles';

interface IMLTimePickerProps extends ITimePickerProps {
  name: string;
  formRequired?: boolean;
  uncontrolled?: boolean;
  uncontrolledError?: boolean;
}

const MLTimePicker = forwardRef<HTMLInputElement, IMLTimePickerProps>(({
  name,
  formRequired,
  uncontrolled,
  uncontrolledError,
  ...props
}, ref) => {
  const { control } = useFormContext();
  const theme = getTheme();

  const getTimePickerStyles = (error: boolean): Partial<IComboBoxStyles> | undefined => {
    return {
      root: {
        width: props.dropdownWidth || '100%',
        height: '3.2rem',
        borderRadius: BORDER_RADIUS,
  
        'div[class^="css"]': {
          '&::after': {
            border: `1px solid ${theme.palette.neutralQuaternary}`,
            borderRadius: BORDER_RADIUS,
          }
        },
      },
  
      rootPressed: {
        border: `2px solid ${theme.palette.themePrimary}`,
      },
  
      rootHovered: {
        'div[class^="css"]': {
          '&:hover::after': {
            border: `1px solid ${theme.palette.neutralQuaternary}`,
          }
        },
      },

      label: {
        fontSize: '1.2rem',
        color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
        fontWeight: 600,
        padding: 0,
        marginBottom: '0.5rem',
      },
  
      callout: {
        borderTop: `2px solid ${theme.palette.themePrimary}`,
  
        '*::-webkit-scrollbar': {
          width: 6,
        },
      }
    };
  };

  return (
    <>
      {uncontrolled ? (
        <TimePicker
          {...props}
          ref={ref} // Pass the ref here
          styles={getTimePickerStyles(!!uncontrolledError)}
          calloutProps={{ directionalHintFixed: true }}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{ required: formRequired ? 'true' : undefined }}
          render={({ field, fieldState: { error } }) => (
            <TimePicker
              {...field}
              {...props}
              ref={ref} // Pass the ref here as well
              styles={getTimePickerStyles(!!error)}
              onChange={(e, time) => field.onChange(time)}
              calloutProps={{ directionalHintFixed: true }}
            />
          )}
        />
      )}
    </>
  );
});

export default MLTimePicker;





// import React from 'react';
// import { useFormContext, Controller } from 'react-hook-form';
// import { TimePicker, ITimePickerProps, getTheme, IComboBoxStyles } from '@fluentui/react';
// import { BORDER_RADIUS, TEXT_FONT_SIZE } from 'utils/constants/styles';

// interface IMLTimePickerProps extends ITimePickerProps {
//   name: string;
//   formRequired?: boolean;
//   uncontrolled?: boolean;
//   uncontrolledError?: boolean;
// }

// const MLTimePicker: React.FC<IMLTimePickerProps> = ({
//   name,
//   formRequired,
//   uncontrolled,
//   uncontrolledError,
//   ...props
// }) => {
//   const { control } = useFormContext();
//   const theme = getTheme();

//   const getTimePickerStyles = (error: boolean): Partial<IComboBoxStyles> | undefined => {
//     return {
//       root: {
//         width: props.dropdownWidth || '100%',
//         height: '3.2rem',
//         borderRadius: BORDER_RADIUS,
  
//         'div[class^="css"]': {
//           '&::after': {
//             border: `1px solid ${theme.palette.neutralQuaternary}`,
//             borderRadius: BORDER_RADIUS,
//           }
//         },
//       },
  
//       rootPressed: {
//         border: `2px solid ${theme.palette.themePrimary}`,
//       },
  
//       rootHovered: {
//         'div[class^="css"]': {
//           '&:hover::after': {
//             border: `1px solid ${theme.palette.neutralQuaternary}`,
//           }
//         },
//       },

//       label: {
//         fontSize: '1.2rem',
//         color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
//         fontWeight: 600,
//         padding: 0,
//         marginBottom: '0.5rem',
//       },
  
//       callout: {
//         borderTop: `2px solid ${theme.palette.themePrimary}`,
  
//         '*::-webkit-scrollbar': {
//           width: 6,
//         },
//       }
//     };
//   };

//   return (
//     <>
//       {uncontrolled ? (
//         <TimePicker
//           {...props}
//           styles={getTimePickerStyles(!!uncontrolledError)}
//           calloutProps={{ directionalHintFixed: true }}
//         />
//       ) : (
//         <Controller
//           name={name}
//           control={control}
//           rules={{ required: formRequired ? 'true' : undefined }}
//           render={({ field, fieldState: { error } }) => (
//             <TimePicker
//               {...field}
//               {...props}
//               styles={getTimePickerStyles(!!error)}
//               onChange={(e, time) => field.onChange(time)}
//               calloutProps={{ directionalHintFixed: true }}
//             />
//           )}
//         />
//       )}
//     </>
//   );
// };

// export default MLTimePicker;
