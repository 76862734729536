import React, { useEffect, useRef, useState } from "react";
import { getClassNames } from './MLErrorGenerationDialog.classNames';

import { CSSTransition } from 'react-transition-group';
import ActionButtons from "./components/ActionButtons/ActionButtons";

import { ANIMATION_DURATION } from 'utils/constants/styles';


interface IMLErrorGenerationDialogProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  error: string;
}

const MLErrorGenerationDialog: React.FC<IMLErrorGenerationDialogProps> = ({ show, onConfirm, onCancel, title, text, error }) => {
  const [errorMessage, setErrorMessage] = useState<string>(error);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const classes = getClassNames();

  useEffect(() => {
    if (!errorMessage) setErrorMessage(error);

    return () => {
      if (errorMessage) setTimeout(() => setErrorMessage(''), 350);
    }
  }, [errorMessage, error]);

  return (
    <CSSTransition
      nodeRef={backgroundRef}
      in={show}
      timeout={ANIMATION_DURATION}
      classNames={{
        enter: classes.backgroundEnter,
        enterActive: classes.backgroundEnterActive,
        exit: classes.backgroundExit,
        exitActive: classes.backgroundExitActive,
      }}
      unmountOnExit
    >
      <div ref={backgroundRef} className={classes.background}>
        <CSSTransition
          nodeRef={dialogRef}
          in={show}
          timeout={ANIMATION_DURATION}
          classNames={{
            appear: classes.dialogEnter,
            appearActive: classes.dialogEnterActive,
            exit: classes.dialogExit,
            exitActive: classes.dialogExitActive,
          }}
          unmountOnExit
          appear
        >
          <div ref={dialogRef} className={classes.dialog}>
            <h1 className={classes.title}>{title}</h1>
            <p className={classes.text}>{text}</p>
            <p className={classes.error}>{errorMessage}</p>
            <ActionButtons
              onConfirm={onConfirm}
              onDismiss={onCancel}
              confirmLabel="Delete"
            />
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default MLErrorGenerationDialog;