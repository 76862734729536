import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IHeaderClassNames {
  headerContainer: string;
  header: string;
  infoIconContainer: string;
}

export const getClassNames = (): IHeaderClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    headerContainer: {
      position: 'relative',
      padding: '2rem 3rem 2rem 2rem',
      background: theme.palette.themeLighterAlt
    },

    header: {
      color: theme.palette.themePrimary,
      fontSize: '1.8rem',
    },

    infoIconContainer: {
      position: 'absolute',
      top: 5,
      right: 5,
      transform: 'scale(0.7)',
      cursor: 'pointer',
    },
  });
}