import { mergeStyleSets } from "@fluentui/react"
import { TEXT_FONT_SIZE } from "utils/constants/styles";

interface IQuoteClassNames {
  quoteContainer: string;
  quote: string;
}

export const getClassNames = (): IQuoteClassNames => {
  return mergeStyleSets({
    quoteContainer: {
      width: '100%',
      height: '100%',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
    },

    quote: {
      width: '100%',
      maxHeight: '100%',
      fontSize: TEXT_FONT_SIZE,
      padding: '1rem',
      overflow: 'auto',
      scrollbarGutter: 'auto',
    }
  });
};