import { mergeStyleSets } from '@fluentui/react';
import { ANIMATION_DURATION } from 'utils/constants/styles';

interface IRouterClassNames {
  route: string;

  pageEnter: string;
  pageEnterActive: string;
  pageExit: string;
  pageExitActive: string;
}

export const getClassNames = (): IRouterClassNames => {
  return mergeStyleSets({
    route: {
      overflow: 'hidden',
      flex: 1,
    },

    pageEnter: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    
    pageEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `opacity ${ANIMATION_DURATION}ms, transform ${ANIMATION_DURATION}ms`,
    },
    
    pageExit: {
      opacity: 1,
      transform: 'scale(1)',
    },
    
    pageExitActive: {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: `opacity ${ANIMATION_DURATION}ms, transform ${ANIMATION_DURATION}ms`,
    },
  })
}