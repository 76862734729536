import { useSelector } from "react-redux";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton";
import PrimaryButton from "common/ml-components/buttons/MLPrimaryButton";
import { getClassNames } from "./ActionButtons.classNames";
import { RootState } from "../../../../../store/store";
import { ApiStatus } from "common/models/store/api-status";
import { Spinner, SpinnerSize } from "@fluentui/react";

interface IActionButtonsProps {
  onDismiss: () => void;
  onConfirm: () => void;
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ onDismiss, onConfirm }) => {
  const settingsApiStatus = useSelector((state: RootState) => state.settings.apiStatus);
  const coursesApiStatus = useSelector((state: RootState) => state.courses.apiStatus);

  const classes = getClassNames();

  return (
    <div className={classes.actionButtonsContainer}>
      <DefaultButton
        text="Cancel"
        onClick={onDismiss}
      />
      <PrimaryButton
        text={(settingsApiStatus !== ApiStatus.SAVING && coursesApiStatus.status !== ApiStatus.SAVING) ? "Confirm" : ''}
        icon={(settingsApiStatus === ApiStatus.SAVING || coursesApiStatus.status === ApiStatus.SAVING) ? <Spinner size={SpinnerSize.small} /> : undefined}
        type="button"
        onClick={onConfirm}
      />
    </div>
  );
};

export default ActionButtons;