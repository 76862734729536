import { Dropdown, IDropdownProps, IDropdownStyles, getTheme } from "@fluentui/react";
import SelectedOption from "assets/ml-icons/Dropdown/SelectedOption/selected-option";
import { useFormContext, Controller } from "react-hook-form";
import { BORDER_RADIUS, TEXT_FONT_SIZE } from "utils/constants/styles";
import { getClassNames } from "./MLDropdown.classNames";
import { IconNames } from "common/models/Icons/icon-names";

export enum DropdownStyles {
  LIGHT = 'light',
  DARK = 'dark',
  COLORED = 'colored',
}

interface IMLDropdownProps extends IDropdownProps {
  name: string;
  dropdownStyle: DropdownStyles;
  formRequired?: boolean;
  uncontrolled?: boolean;
  uncontrolledError?: boolean;
  iconName?: IconNames;
  leftLabel?: boolean;
  paddingRight?: string;
}

const MLDropdown: React.FC<IMLDropdownProps> = ({
  name,
  dropdownStyle,
  formRequired,
  uncontrolled,
  uncontrolledError,
  iconName,
  leftLabel,
  paddingRight,
  ...props
}) => {
  const methods = useFormContext();

  const theme = getTheme();
  const getDropdownStyles = (error: boolean): Partial<IDropdownStyles> => {
    switch (dropdownStyle) {
      case DropdownStyles.LIGHT: return {
        root: {
          width: props.dropdownWidth || '100%',
        },

        title: {
          color: props.disabled ? theme.palette.neutralTertiary : 'unset',
          border: `1px solid ${theme.palette.neutralQuaternary}`,
          borderRadius: BORDER_RADIUS,
          paddingLeft: 10,
          paddingRight: paddingRight || 0,

          '&:hover': {
            borderColor: theme.palette.neutralQuaternary,
          }
        },
        
        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
          '.ms-Callout-main': {
            borderRadius: BORDER_RADIUS,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          marginRight: 5,
        },

        caretDown: {
          color: theme.palette.neutralDark,
        },

        dropdown: {
          '&:focus::after': {
            borderRadius: BORDER_RADIUS
          },

          '&:hover': {
            borderColor: theme.palette.neutralQuaternary,
            '.ms-Dropdown-title': {
              borderColor: theme.palette.neutralQuaternary,
            }
          }
        },

        dropdownItem: {
          minHeight: 'unset',
          height: 'fit-content',
          padding: '0.8rem 1rem'
        },

        dropdownItemSelected: {
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '0.8rem 1rem'
        },

        label: {
          fontSize: '1.2rem',
          color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
          fontWeight: 600,
          padding: 0,
          marginBottom: '0.5rem',
        },

        subComponentStyles: {
          label: {},
          panel: {},
          multiSelectItem: {
            root: {
              fontSize: TEXT_FONT_SIZE,
              minHeight: 'unset',
              height: 'fit-content',
              padding: '0.5rem',
            },
            checkbox: {
              marginRight: '0.8rem',
              borderRadius: BORDER_RADIUS,
            },
            label: {
              padding: '0 0.4rem',
            },
            text: {
              fontSize: TEXT_FONT_SIZE,
            },
          }
        },
      };

      case DropdownStyles.DARK: return {
        root: {
          width: props.dropdownWidth || '100%',
          borderRadius: BORDER_RADIUS,
        },

        title: {
          fontSize: TEXT_FONT_SIZE,
          border: 'none',
          background: props.disabled ? theme.palette.neutralLighter : theme.palette.neutralLighterAlt,
          color: props.disabled ? theme.palette.neutralTertiary : 'unset',
          borderRadius: BORDER_RADIUS,
          paddingLeft: 10,
          paddingRight: 0,
        },

        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
          '.ms-Callout-main': {
            borderRadius: BORDER_RADIUS,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          marginRight: 5,
        },

        caretDown: {
          color: theme.palette.neutralDark,
        },

        dropdownItem: {
          fontSize: TEXT_FONT_SIZE,
          minHeight: 'unset',
          height: 'fit-content',
          padding: '0.5rem',
        },
        
        dropdownItemSelected: {
          fontSize: TEXT_FONT_SIZE,
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '0.5rem',
        },

        dropdownOptionText: {
          fontSize: TEXT_FONT_SIZE,
        },

        subComponentStyles: {
          label: {},
          panel: {},
          multiSelectItem: {
            root: {
              fontSize: TEXT_FONT_SIZE,
              minHeight: 'unset',
              height: 'fit-content',
              padding: '0.5rem',
            },
            checkbox: {
              marginRight: '0.8rem',
            },
            text: {
              fontSize: TEXT_FONT_SIZE,
            },
          }
        },

        dropdown: {
          '&:focus::after': {
            borderRadius: BORDER_RADIUS
          }
        },

        label: {
          fontSize: '1.2rem',
          color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
          fontWeight: 600,
          padding: 0,
          marginBottom: '0.5rem',
        }
      };

      case DropdownStyles.COLORED: return {
        root: {
          width: props.dropdownWidth || '100%',
          // height: props.drop || 'auto',
        },

        title: {
          // height: height || 'auto',
          color: theme.palette.black,
          background: props.disabled ? theme.palette.neutralTertiary : theme.palette.themeLighter,
          // fontWeight: theme.fonts.xLarge.fontWeight,
          border: props.disabled ? `1px solid ${theme.palette.neutralTertiary}` : `1px solid ${theme.palette.themeLighter}`,
          borderRadius: BORDER_RADIUS,
          paddingLeft: 10,
          paddingRight: paddingRight || 0,

          '&:hover': {
            borderColor: theme.palette.themeLighter,
          }
        },
        
        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
          '.ms-Callout-main': {
            borderRadius: BORDER_RADIUS,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          marginRight: 5,
          '*': {
            // color: 'white'
          }
        },

        dropdown: {
          '&:active': {
            // color: theme.palette.white,
            '.ms-Dropdown-title': {
              // color: theme.palette.white,
            },
            '.ms-Dropdown-caretDown': {
              // color: theme.palette.white,
            },
          },

          '&:focus': {
            // color: theme.palette.white,
            '.ms-Dropdown-title': {
              // color: theme.palette.white,
            },
            '.ms-Dropdown-caretDown': {
              // color: theme.palette.white,
            },
          },

          '&:focus::after': {
            borderRadius: BORDER_RADIUS
          },

          '&:hover': {
            borderColor: theme.palette.themeLighter,
            '.ms-Dropdown-title': {
              // color: theme.palette.white,
              borderColor: theme.palette.themeLighter,
            },
            '.ms-Dropdown-caretDown': {
              // color: theme.palette.white,
            },
          }
        },

        dropdownItem: {
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },

        dropdownItemSelected: {
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },

        label: {
          fontSize: '1.2rem',
          color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
          fontWeight: 600,
          padding: 0,
          marginBottom: '0.5rem',
        },
      }
    }
  };

  const classes = getClassNames(!!methods?.formState?.errors[name]);

  const onRenderOption = (option: any, value: any) => {
    if (option.key === value) {
      return (
        <div className={classes.selectedOptionContainer}>
          <SelectedOption />
          <span className={classes.option}>{option.text}</span>
        </div>
      )
    } else return <span className={classes.option}>{option.text}</span>;
  };

  if (uncontrolled) return (
    <Dropdown
      {...props}
      styles={() => getDropdownStyles(!!uncontrolledError)}
      onRenderOption={(option) => onRenderOption(option, props.selectedKey)}
    />
  );

  return (
    <>
      {leftLabel ? (
        <div className={classes.dropdownContainer}>
          <p className={classes.leftLabel}>{props.label}</p>
          <Controller
            name={name}
            control={methods.control}
            rules={{ required: formRequired ? 'true': undefined }}
            render={({ field, fieldState: { error } }) => (
              props.multiSelect ? (
                <Dropdown
                  {...field}
                  {...props}
                  label=''
                  styles={() => getDropdownStyles(!!error)}
                  selectedKeys={field.value}
                  onChange={(_, option) => {
                    const newSelectedKeys = option?.selected
                      ? [...field.value, option.key] 
                      : field.value.filter((key: string) => key !== option?.key);
                    field.onChange(newSelectedKeys);
                  }}
                  calloutProps={{ directionalHintFixed: true }}
                />
              ) : (
                <Dropdown
                  {...field}
                  {...props}
                  label=''
                  styles={() => getDropdownStyles(!!error)}
                  selectedKey={field.value}
                  onChange={(_, option) => option && field.onChange(option.key)}
                  calloutProps={{ directionalHintFixed: true }}
                  onRenderOption={(option) => onRenderOption(option, field.value)}
                />
              )
            )}
          />
        </div>
      ) : (
        <Controller
          name={name}
          control={methods.control}
          rules={{ required: formRequired ? 'true': undefined }}
          render={({ field, fieldState: { error } }) => (
            props.multiSelect ? (
              <Dropdown
                {...field}
                {...props}
                styles={() => getDropdownStyles(!!error)}
                selectedKeys={field.value}
                onChange={(_, option) => {
                  const newSelectedKeys = option?.selected 
                    ? [...field.value, option.key] 
                    : field.value.filter((key: string) => key !== option?.key);
                  field.onChange(newSelectedKeys);
                }}
                calloutProps={{ directionalHintFixed: true }}
              />
            ) : (
              <Dropdown
                {...field}
                {...props}
                styles={() => getDropdownStyles(!!error)}
                selectedKey={field.value}
                onChange={(_, option) => option && field.onChange(option.key)}
                calloutProps={{ directionalHintFixed: true }}
                onRenderOption={(option) => onRenderOption(option, field.value)}
              />
            )
          )}
        />
      )}
    </>
  )
}

export default MLDropdown;