import Add from "assets/ml-icons/Buttons/Add/add";
import AddSchedule from "assets/ml-icons/Buttons/AddSchedule/add-schedule";
import Edit from "assets/ml-icons/Buttons/Edit/edit";
import Filter from "assets/ml-icons/Controls/Filter/filter";
import Analytics from "assets/ml-icons/Buttons/Analytics/analytics";
import ChangeLanguage from "assets/ml-icons/Dropdown/ChangeLanguage/change-language";
import Configure from "assets/ml-icons/Buttons/Configure/configure";
import Delete from "assets/ml-icons/Buttons/Delete/delete";
import Publish from "assets/ml-icons/Buttons/PublishIcon/publish";
import Schedule from "assets/ml-icons/Buttons/Schedule/Schedule";
import Unpublish from "assets/ml-icons/Buttons/Unpublish/unpublish";
import Reorder from "assets/ml-icons/Controls/Reorder/reorder";
import Send from "assets/ml-icons/Buttons/Send/Send";
import { IconNames } from "common/models/Icons/icon-names";
import { IIconProps } from "common/models/Icons/icon-props";
import AddLesson from "assets/ml-icons/Buttons/AddLesson/add-lesson";
import FinalQuiz from "assets/ml-icons/Buttons/FinalQuiz/final-quiz";
import Download from "assets/ml-icons/Buttons/Download/download";
import Clone from "assets/ml-icons/Buttons/Clone/Clone";
import ScormIcon from "assets/ml-icons/Buttons/Scorm/Scorm";
import Stop from "assets/ml-icons/Buttons/Stop/Stop";
import SharePointIcon from "assets/ml-icons/Buttons/SharePoint/SharePoint";

export const Icons: { [key in IconNames]: (props: IIconProps) => JSX.Element } = {
  [IconNames.CONFIGURE]: Configure,
  [IconNames.ANALYTICS]: Analytics,
  [IconNames.SCHEDULE]: Schedule,
  [IconNames.PUBLISH]: Publish,
  [IconNames.UNPUBLISH]: Unpublish,
  [IconNames.DELETE]: Delete,
  [IconNames.CHANGE_LANGUAGE]: ChangeLanguage,
  [IconNames.ADD]: Add,
  [IconNames.REORDER]: Reorder,
  [IconNames.ADD_SCHEDULE]: AddSchedule,
  [IconNames.EDIT]: Edit,
  [IconNames.FILTER]: Filter,
  [IconNames.SEND]: Send,
  [IconNames.ADD_LESSON]: AddLesson,
  [IconNames.FINAL_QUIZ]: FinalQuiz,
  [IconNames.DOWNLOAD]: Download,
  [IconNames.CLONE]: Clone,
  [IconNames.SCORM]: ScormIcon,
  [IconNames.SHAREPOINT]: SharePointIcon,
  [IconNames.STOP]: Stop
};