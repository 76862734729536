import Delete from "assets/ml-icons/Buttons/Delete/delete";
import { getClassNames } from "./footer.classNames";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton/MLDefaultButton";
import Edit from "assets/ml-icons/Buttons/Edit/edit";

export interface IFooterProps {
  onEditLessonClick: () => void;
  onDeleteClick: () => void;
}

const Footer = (props: IFooterProps) => {
  const classes = getClassNames(props);

  return (
    <footer className={classes.footer}>
      <div className={classes.actionEditContainer} onClick={(e) => {
        e.stopPropagation();
        props.onEditLessonClick()
      }}>
        <DefaultButton
          colored
          icon={<Edit colored />}
          onClick={() => {}}
        />
      </div>
      <div
        className={classes.actionHideContainer}
        onClick={props.onDeleteClick}      
      >
        <DefaultButton
          colored
          icon={<Delete colored />}
          onClick={() => {}}
        />
      </div>
    </footer>
  )
};

export default Footer;