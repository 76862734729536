import { mergeStyleSets, getTheme } from '@fluentui/react';
import { BORDER_RADIUS, TEXT_FONT_SIZE } from 'utils/constants/styles';

interface IDocumentClassNames {
  label: string;
  documentContainer: string;
  document: string;
  documentEnter: string;
  documentEnterActive: string;
  documentExit: string;
  documentExitActive: string;
}

export const getClassNames = (error: boolean): IDocumentClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    label: {
    fontSize: TEXT_FONT_SIZE,
    color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
    fontWeight: 600,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    },

    documentContainer: {
      width: '100%',
      // flex: '0 0 2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
      cursor: 'pointer',
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
    },

    document: {
      width: '100%',
      height: '5rem',
    },

    documentEnter: {
      opacity: 0,
    },

    documentEnterActive: {
      opacity: 1,
      transition: 'opacity 250ms',
    },

    documentExit: {
      opacity: 1,
    },

    documentExitActive: {
      opacity: 0,
      transition: 'opacity 250ms',
    },
  });
};