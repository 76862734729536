import { IIconProps } from "common/models/Icons/icon-props";

const Configure = (props: IIconProps): JSX.Element => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.95 2C12.7184 0.85888 11.7095 0 10.5 0C9.29052 0 8.28164 0.85888 8.05001 2H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H8.05001C8.28164 4.14112 9.29052 5 10.5 5C11.7297 5 12.752 4.11217 12.961 2.94254C12.9575 2.96177 12.9539 2.98093 12.95 3H15.5C15.7761 3 16 2.77614 16 2.5C16 2.22386 15.7761 2 15.5 2H12.95ZM10.5 4C9.67157 4 9 3.32843 9 2.5C9 1.67157 9.67157 1 10.5 1C11.3284 1 12 1.67157 12 2.5C12 3.32843 11.3284 4 10.5 4ZM7.94999 11C7.71836 9.85888 6.70948 9 5.5 9C4.29052 9 3.28164 9.85888 3.05001 11H0.5C0.223858 11 0 11.2239 0 11.5C0 11.7761 0.223858 12 0.5 12H3.05001C3.28164 13.1411 4.29052 14 5.5 14C6.70948 14 7.71836 13.1411 7.94999 12H15.5C15.7761 12 16 11.7761 16 11.5C16 11.2239 15.7761 11 15.5 11H7.94999ZM5.5 13C4.67157 13 4 12.3284 4 11.5C4 10.6716 4.67157 10 5.5 10C6.32843 10 7 10.6716 7 11.5C7 12.3284 6.32843 13 5.5 13Z" fill="#232323"/>
    </svg>
  )
};

export default Configure;