import { mergeStyleSets } from '@fluentui/react';

interface ICourseInfoClassNames {
  courseInfoContainer: string;
}

export const getClassNames = (): ICourseInfoClassNames => {
  return mergeStyleSets({
    courseInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
  });
};