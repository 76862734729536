import { IndexedResponseDto } from "../../../../../../../../api-client";
import { getClassNames } from "./quiz-lesson.classNames";

interface IQuizProps {
  question: string;
  options: IndexedResponseDto[];
  correctAnswerId: number | undefined;
}

const QuizLesson = (props: IQuizProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.quizLessonContainer}>
      <div className={classes.quizContainer}>
        <div className={classes.quizScrollContainer}>
          <p className={classes.question}>{props.question}</p>
          <br />
          <ul className={classes.answerList}>
            {props.options.map((option: IndexedResponseDto, index: number) => (
            <li key={index} className={option.id === props.correctAnswerId ? classes.correctAnswer : classes.answer}>
              <div className={classes.letterContainer}><h3 className={classes.letter}>{String.fromCharCode(97 + index)}</h3></div>
              <p className={classes.response}>{option.response}</p>
            </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
};

export default QuizLesson;