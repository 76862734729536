import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS } from "utils/constants/styles";

interface IQuizOptionsNames {
  quizOptionsContainer: string;
  titleLabel: string;
  textInput: string;
  textArea: string;
  percentageContainer: string;
  groupPercentage: string;
  percentageInput: string;
  attemptsContainer: string;
  generateQuizContainer: string;
  toggleContainer: string;
}

export const getClassNames = (): IQuizOptionsNames => {
  const theme = getTheme();

  return mergeStyleSets({
    quizOptionsContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      gap: '4rem',
      padding: '2rem 0',
      paddingRight: '1rem',
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    textInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none'
    },

    textArea: {
      resize: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
    },

    percentageContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },

    groupPercentage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
    },

    percentageInput: {
      position: 'relative',
      width: 50,
      height: 35,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BORDER_RADIUS,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
    },

    attemptsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
    },

    toggleContainer: {
      flex: '0 0 35px',
      width: 60,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.themeLight,
      borderRadius: BORDER_RADIUS
    },

    generateQuizContainer: {
      marginTop: 20,
    },
  });
};