import { mergeStyleSets, getTheme } from '@fluentui/react';
import { BORDER_RADIUS, TEXT_FONT_SIZE } from 'utils/constants/styles';

interface ICardClassNames{
  card: string;
  cardTop: string;
  iconContainer: string;
  cardBottom: string;
  divider: string;
  description: string;
}

export const getClassNames = (): ICardClassNames => {
  const theme = getTheme();

    return mergeStyleSets({
      card: {
        width: '35rem',
        height: '40rem',
        borderBottom: `3px solid ${theme.palette.themePrimary}`,
        display: 'flex',
        flexDirection: 'column',
      },

      cardTop: {
        flex: '0 0 50%',
        background: getTheme().palette.white,
        borderTopLeftRadius: BORDER_RADIUS,
        borderTopRightRadius: BORDER_RADIUS,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      iconContainer: {
        width: '10rem',
        height: '10rem',
        padding: '2rem',
        borderRadius: '1rem',
        background: theme.palette.themeTertiary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      
      cardBottom: {
        background: theme.palette.neutralLight,
        flex: '0 0 50%',
        padding: '0 2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
      },

      divider: {
        borderTop: `1px solid ${theme.palette.themePrimary}`,
      },

      description: {
        minHeight: '6rem',
        fontSize: TEXT_FONT_SIZE,
      }
    });
};