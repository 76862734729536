import { mergeStyleSets, keyframes } from "@fluentui/react";

interface IGeneratingClassNames {
  'icon': string;
}

export const getClassNames = (): IGeneratingClassNames => {
  const rotating = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  })
  
  return mergeStyleSets({
    icon: {
      animation: `${rotating} 1.5s linear infinite`,
      transformOrigin: 'center center',
    }
  });
}