import React from 'react';
import { getClassNames } from './Image.classNames';

interface IImageProps {
  src: string;
}

const Image: React.FC<IImageProps> = ({ src }) => {
  const classes = getClassNames();

  return (
    <img src={src} alt="" className={classes.image} />
  );
};

export default Image;