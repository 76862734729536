import { mergeStyleSets, keyframes } from '@fluentui/react';
import { IFadeClassNames } from 'utils/constants/styles';
import { FADE } from 'utils/constants/styles';

interface ILearningViewPageClassNames extends IFadeClassNames {
  learningViewPage: string;
  courseContainer: string;
}

export const getClassNames = (): ILearningViewPageClassNames => {
  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    learningViewPage: {
      position: 'relative',
      overflow: 'hidden',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    courseContainer: {
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },

    ...FADE.classNames,
  });
};