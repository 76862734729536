import { IShimmerColors, Shimmer } from "@fluentui/react";
import { getClassNames } from "./QuizLesson.classNames";

interface IQuizLessonProps {
  shimmerColors: IShimmerColors;
}

const QuizLesson: React.FC<IQuizLessonProps> = ({ shimmerColors }) => {
  const classes = getClassNames();

  return (
    <div className={classes.quizLessonContainer}>
    {[...new Array(2)].map((_, index) => (
      <Shimmer
        key={index}
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `${(Math.random() * 10) + 90}%`,
            height: '1.5rem',
            borderRadius: 5,
            '.ms-ShimmerLine-root': {
              height: '1.5rem',
            }
          },
          shimmerWrapper: {
            height: '1.5rem',
            borderRadius: 5,
          },
        }}
      />
    ))}
    {[...new Array(4)].map((_, index) => (
      <Shimmer
        key={index}
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `100%`,
            height: '4rem',
            borderRadius: 5,
            '.ms-ShimmerLine-root': {
              height: '4rem',
            }
          },
          shimmerWrapper: {
            height: '4rem',
            borderRadius: 5,
          },
        }}
      />
    ))}
    {[...new Array(1)].map((_, index) => (
      <Shimmer
        key={index}
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `${(Math.random() * 10) + 90}%`,
            height: '1.5rem',
            borderRadius: 5,
            '.ms-ShimmerLine-root': {
              height: '1.5rem',
            }
          },
          shimmerWrapper: {
            height: '1.5rem',
            borderRadius: 5,
          },
        }}
      />
    ))}
  </div>
  );
};

export default QuizLesson;