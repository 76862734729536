import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS, TEXT_FONT_SIZE } from "utils/constants/styles";

interface IMLNoPermissionClassNames {
  noPermissionContainer: string;
  noPermissionMessageContainer: string;
  noPermissionMessage: string;
}

export const getClassNames = (): IMLNoPermissionClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    noPermissionContainer: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    noPermissionMessageContainer: {
      width: 350,
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.neutralLighterAlt,
      borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
      borderBottom: `2px solid ${theme.palette.themePrimary}`,
    },

    noPermissionMessage: {
      fontSize: TEXT_FONT_SIZE,
      margin: 0,
    }
  });
};