// import react
import * as React from 'react';
import { getClassNames } from './ChevronLeft.classNames';

interface IChevronLeftProps {
  onClick: () => void;
  disabled: boolean;
}

const ChevronLeft = (props: IChevronLeftProps) => {
  const classes = getClassNames(props.disabled);

  return (
    // <svg
    //   width="0.9rem"
    //   height="1.6rem"
    //   viewBox="0 0 9 16"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={classes.icon}
    //   onClick={props.onClick}
    // >
    //   <path d="M8.53033 0.21967C8.82322 0.512563 8.82322 0.987437 8.53033 1.28033L1.81066 8L8.53033 14.7197C8.82322 15.0126 8.82322 15.4874 8.53033 15.7803C8.23744 16.0732 7.76256 16.0732 7.46967 15.7803L0.21967 8.53033C-0.0732233 8.23744 -0.0732232 7.76256 0.21967 7.46967L7.46967 0.21967C7.76256 -0.0732233 8.23744 -0.0732233 8.53033 0.21967Z" fill="#242424"/>
    // </svg>

    <svg
      width="0.7rem"
      height="1.2rem"
      viewBox="0 0 7 12"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <path d="M6.35337 11.8537C6.15846 12.0493 5.84188 12.0499 5.64627 11.855L0.161785 6.39C-0.0539287 6.17505 -0.0539278 5.82574 0.161785 5.6108L5.64627 0.145817C5.84188 -0.0490969 6.15846 -0.0485323 6.35337 0.147077C6.54829 0.342687 6.54772 0.659269 6.35212 0.854182L1.18753 6.0004L6.35211 11.1466C6.54772 11.3415 6.54829 11.6581 6.35337 11.8537Z" fill="#242424"/>
    </svg>

  );
};

export default ChevronLeft;