import { CourseV2Dto } from "../../api-client";
import { ICourse } from "common/models/ICourse";
import { IUser } from "common/models/IUser";

export const CoursesMapper = (courses: CourseV2Dto[], users: IUser[]): ICourse[] => {
  const data: ICourse[] = [];
  courses.forEach((course) => {
    // console.log('course', course);
    const user = users.find(el => el.username === course.createdBy);
    // console.log('user', user);
    if (!user) return;

    data.push({
      ...course,
      createdBy: user
    });
  });
  return data;
};