import { ChangeEvent, useRef,  } from "react";
import { getClassNames } from "./Attachment.classNames";
import { useFormContext } from "react-hook-form";
import { Icon } from "@fluentui/react";
import { IAttachment } from "common/models/Learnings/attachment";
import { MIMETypes } from "utils/constants/file-types";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import { isFileTypeCorrect } from "utils/helpers/helpers";
import ImageC from "./components/Image/Image";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "utils/constants/styles";
import AddAttachment from "./components/AddAttachment/AddAttachment";

const Attachment: React.FC = () => {
  const { watch, setValue } = useFormContext();

  const attachment: IAttachment = watch('attachment');
  const attachmentRef = useRef<HTMLDivElement>(null);
  const addAttachmentRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (isFileTypeCorrect(e.target.files[0])) {
        const blob = new Blob([e.target.files[0]]);
        const blobUrl = URL.createObjectURL(blob);

        if (e.target.files[0].type.includes('image')) {
          const img = new Image();
          img.onload = () => {
            if (!e.target?.files) return;
            setValue('attachment', { data: e.target.files[0], src: blobUrl, contentType: e.target.files[0].type, }, { shouldDirty: true });
          };
          img.src = blobUrl;
        }

        if (e.target.files[0].type.includes('video')) {
          setValue('attachment', { data: e.target.files[0], src: blobUrl, contentType: e.target.files[0].type, }, { shouldDirty: true });
        }
      }
    }
  };

  const clearAttachment = () => {
    if (inputFileRef.current && inputFileRef.current.files) {
      inputFileRef.current.files = null;
      inputFileRef.current.value = '';
    }
    setValue('attachment', null, { shouldDirty: true });
  };

  const contentRef = attachment !== null ? attachmentRef : addAttachmentRef;

  const classes = getClassNames();

  return (
    <SwitchTransition>
      <CSSTransition
        key={attachment !== null ? "media" : "addAttachment"}
        nodeRef={contentRef}
        timeout={ANIMATION_DURATION}
        classNames={{
          enter: classes.contentEnter,
          enterActive: classes.contentEnterActive,
          exit: classes.contentExit,
          exitActive: classes.contentExitActive,
        }}
      >
        <div ref={contentRef} className={classes.attachmentContainer}>
          {attachment?.data && <Icon className={classes.closeIcon} iconName="ChromeClose" onClick={clearAttachment} />}
          {(attachment?.contentType === MIMETypes.JPEG ||
            attachment?.contentType === MIMETypes.PNG) && (
            <ImageC src={attachment?.src || ''}/>
            )
          }
          {attachment?.contentType === MIMETypes.MP4 && (
            <VideoPlayer attachment={attachment} />
          )}
          {attachment === null && (
            <AddAttachment
              inputFileRef={inputFileRef}
              onFileChange={onFileChange}
            />
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Attachment;