import { mergeStyleSets } from "@fluentui/react";
import { ANIMATION_DURATION, BORDER_RADIUS } from "utils/constants/styles";

interface IVideoPlayerClassNames {
  videoPlayerContainer: string;
  videoPlayer: string;

  videoEnter: string;
  videoEnterActive: string;
  videoExit: string;
  videoExitActive: string;
}

export const getClassNames = (isLandscape: boolean): IVideoPlayerClassNames => {
  return mergeStyleSets({
    videoPlayerContainer: {
      position: 'relative',
      overflow: 'hidden',
      width: isLandscape && '100%',
      height: !isLandscape && '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    videoPlayer: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      borderRadius: BORDER_RADIUS,
    },

    videoEnter: {
      opacity: 0,
      transform: 'scale(0.98)',
    },

    videoEnterActive: {
      opacity: 1,
      transform: 'scale(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    videoExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    videoExitActive: {
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};