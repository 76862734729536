import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS, MODAL_SECTION_TITLE_FONT_SIZE, MODAL_CLOSE_ICON, ANIMATION_DURATION, TEXT_FONT_SIZE } from "utils/constants/styles";

interface IDialogClassNames {
  background: string;
  dialog: string;
  closeIcon: string;
  title: string;
  text: string;
  error: string;

  dialogEnter: string;
  dialogEnterActive: string;
  dialogExit: string;
  dialogExitActive: string;

  backgroundEnter: string;
  backgroundEnterActive: string;
  backgroundExit: string;
  backgroundExitActive: string;
}

export const getClassNames = (): IDialogClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      background: 'rgba(0, 0, 0, 0.6)',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    dialog: {
      width: 'auto',
      maxWidth: '25%',
      maxHeight: '90%',
      overflow: 'hidden',
      padding: '2.5rem',
      background: theme.palette.white,
      borderRadius: BORDER_RADIUS,
      boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.4)',

      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },

    closeIcon: {
      cursor: 'pointer',
      fontSize: MODAL_CLOSE_ICON,
      marginTop: 6,
    },

    title: {
      fontSize: MODAL_SECTION_TITLE_FONT_SIZE,
    },

    text: {
      fontSize: TEXT_FONT_SIZE,
    },

    error: {
      fontSize: TEXT_FONT_SIZE,
      padding: '1rem',
      backgroundColor: theme.palette.neutralLighter,
      borderRadius: BORDER_RADIUS,
    },

    backgroundEnter: {
      opacity: 0,
    },

    backgroundEnterActive: {
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    backgroundExit: {
      opacity: 1,
    },

    backgroundExitActive: {
      opacity: 0,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    dialogEnter: {
      opacity: 0,
      transform: 'scale(0.9)',
    },

    dialogEnterActive: {
      transform: 'scale(1)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    dialogExit: {
      opacity: 1,
      transform: 'scale(1)',
    },

    dialogExitActive: {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: `all ${ANIMATION_DURATION}ms`,
    }
  });
}