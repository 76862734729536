export const BORDER_RADIUS = '5px';
export const MODAL_TITLE_FONT_SIZE = '2.2rem';
export const MODAL_CLOSE_ICON = '1.6rem';
export const MODAL_SECTION_TITLE_FONT_SIZE = '1.6rem';
export const BUTTON_FONT_SIZE = '1.4rem';
export const BUTTON_FONT_WEIGHT = 400;
export const TEXT_FONT_SIZE = '1.5rem';
export const PAGE_TITLE_FONT_SIZE = '2.4rem';

export interface IFadeClassNames {
  fadeEnter: string;
  fadeEnterActive: string;
  fadeExit: string;
  fadeExitActive: string;
}

export interface IFadeScaleClassNames {
  fadeScaleEnter: string;
  fadeScaleEnterActive: string;
  fadeScaleExit: string;
  fadeScaleExitActive: string;
}

export const ANIMATION_DURATION = 350;

class Fade {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames() {
    return {
      fadeEnter: {
        opacity: 0,
      },
  
      fadeEnterActive: {
        opacity: 1,
        transition: `opacity ${this._enterDurationMS}ms`,
      },
  
      fadeExit: {
        opacity: 1,
      },
  
      fadeExitActive: {
        opacity: 0,
        transition: `opacity ${this._exitDurationMS}ms`,
      }
    };
  }
}

class FadeScale {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      fadeScaleEnter: {
        opacity: 0,
        transform: 'scale(0.9)',
      },
  
      fadeScaleEnterActive: {
        transform: 'scale(1)',
        opacity: 1,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      fadeScaleExit: {
        opacity: 1,
        transform: 'scale(1)',
      },
  
      fadeScaleExitActive: {
        opacity: 0,
        transform: 'scale(0.9)',
        transition: `all ${this._exitDurationMS}ms`,
      }
    };
  }
}

class FadeScalePivot {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      fadeScaleEnter: {
        opacity: 0,
        transform: 'scale(0.95)',
      },
  
      fadeScaleEnterActive: {
        transform: 'scale(1)',
        opacity: 1,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      fadeScaleExit: {
        opacity: 1,
        transform: 'scale(1)',
      },
  
      fadeScaleExitActive: {
        opacity: 0,
        transform: 'scale(0.95)',
        transition: `all ${this._exitDurationMS}ms`,
      }
    };
  }
}

export const FADE = new Fade(ANIMATION_DURATION, ANIMATION_DURATION);
export const FADE_ITEMS = new Fade(500, 500);
export const FADE_PIVOT = new FadeScalePivot(ANIMATION_DURATION, ANIMATION_DURATION);
export const FADE_SCALE = new FadeScale(ANIMATION_DURATION, ANIMATION_DURATION);
export const SIDEBAR_ANIMATION = new Fade(750, 350);