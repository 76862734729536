import { mergeStyleSets } from "@fluentui/react"

interface INewLessonClassNames {
  newLessonContainer: string;
}

export const getClassNames = (): INewLessonClassNames => {
  return mergeStyleSets({
    newLessonContainer: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      gap: '2rem',

      height: '50rem'
    },
  });
};