import React from 'react';
import { getClassNames } from './MLVerticalDivider.classNames';

const MLVerticalDivider: React.FC = () => {
  const classes = getClassNames();
  
  return (
    <div className={classes.verticalDivider} />
  );
};

export default MLVerticalDivider;