import { Toggle, IToggleProps, IToggleStyles, getTheme } from "@fluentui/react";
import { useFormContext, Controller } from "react-hook-form";
import { BORDER_RADIUS } from "utils/constants/styles";

interface IMLToggleProps extends IToggleProps {
  name: string;
  uncontrolled?: boolean;
}

const MLToggle: React.FC<IMLToggleProps> = ({
  name,
  uncontrolled,
  ...props
}) => {
  const { control } = useFormContext();

  const theme = getTheme();
  const getToggleStyles = (): Partial<IToggleStyles> => {
    return {
      root: {
        height: '100%',
        marginBottom: 0,
      },
      label: {
        fontSize: '1.2rem',
        color: theme.palette.neutralSecondaryAlt,
        fontWeight: 600,
        padding: 0,
        marginBottom: '0.5rem',
      },
      container: {
        height: '3.4rem',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid transparent',
        backgroundColor: theme.palette.themeLight,
        padding: '0 1rem',
        borderRadius: BORDER_RADIUS,
        width: 'fit-content',
        margin: 'auto',
      }
    };
  };

  return (
    <>
      {uncontrolled ? (
        <Toggle
          {...props}
          styles={() => getToggleStyles()}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Toggle
              {...field}
              {...props}
              checked={field.value}
              onChange={(e, checked) => field.onChange(checked)}
              styles={() => getToggleStyles()}
            />
          )}
        />
      )}
    </>
  )
}

export default MLToggle;