import { getTheme } from "@fluentui/react";

interface IPauseProps {
  onPauseClick: () => void;
}

const PauseRounded = (props: IPauseProps) => {
  const theme = getTheme();

  return (
    // <svg width="18" height="18" viewBox="0 0 48 48" stroke={theme.palette.black} xmlns="http://www.w3.org/2000/svg" onClick={props.onPauseClick} cursor="pointer">
    //   <path d="M11.75 6C9.67893 6 8 7.67893 8 9.75V38.25C8 40.3211 9.67893 42 11.75 42H18.25C20.3211 42 22 40.3211 22 38.25V9.75C22 7.67893 20.3211 6 18.25 6H11.75ZM10.5 9.75C10.5 9.05964 11.0596 8.5 11.75 8.5H18.25C18.9404 8.5 19.5 9.05964 19.5 9.75V38.25C19.5 38.9404 18.9404 39.5 18.25 39.5H11.75C11.0596 39.5 10.5 38.9404 10.5 38.25V9.75ZM29.75 6C27.6789 6 26 7.67893 26 9.75V38.25C26 40.3211 27.6789 42 29.75 42H36.25C38.3211 42 40 40.3211 40 38.25V9.75C40 7.67893 38.3211 6 36.25 6H29.75ZM28.5 9.75C28.5 9.05964 29.0596 8.5 29.75 8.5H36.25C36.9404 8.5 37.5 9.05964 37.5 9.75V38.25C37.5 38.9404 36.9404 39.5 36.25 39.5H29.75C29.0596 39.5 28.5 38.9404 28.5 38.25V9.75Z" fill="#242424"/>
    // </svg>

    <svg width="80" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onPauseClick} cursor="pointer">
      <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.25 11.5C15.9404 11.5 16.5 12.0596 16.5 12.75V27.25C16.5 27.9404 15.9404 28.5 15.25 28.5C14.5596 28.5 14 27.9404 14 27.25V12.75C14 12.0596 14.5596 11.5 15.25 11.5ZM23.5 12.75C23.5 12.0596 24.0596 11.5 24.75 11.5C25.4404 11.5 26 12.0596 26 12.75V27.25C26 27.9404 25.4404 28.5 24.75 28.5C24.0596 28.5 23.5 27.9404 23.5 27.25V12.75Z" fill={theme.palette.themeTertiary}/>
    </svg>

  )
};

export default PauseRounded;