import { getTheme, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, Pivot, PivotItem } from "@fluentui/react";
import { getClassNames } from "./LessonData.classNames";
import Attachment from "./components/Attachment/Attachment"
import Quote from "./components/Quote/Quote";
import { ANIMATION_DURATION, MODAL_SECTION_TITLE_FONT_SIZE } from "utils/constants/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { createRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface ITab {
  key: string;
  label: string;
  component: React.ReactElement;
  ref: React.RefObject<HTMLDivElement>;
}

const LessonData: React.FC = () => {
  const quote = useSelector((state: RootState) => state.courses.lessonToEdit?.quote);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const theme = getTheme();

  const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    root: { 
      marginLeft: -8,
    },
    link: {
      height: '3rem',
      fontSize: MODAL_SECTION_TITLE_FONT_SIZE,
      color: theme.palette.black,

      ':hover': {
        backgroundColor: 'transparent',
      },
      ':active': {
        backgroundColor: 'transparent',
      }
    },
  };

  const tabs: ITab[] = useMemo(() => {
    if (quote) {
      return [
        {
          component: <Attachment />,
          key: 'attachment',
          label: 'Attachment',
          ref: createRef(),
        },
        {
          component: <Quote quote={quote} />,
          key: 'quote',
          label: 'Quote',
          ref: createRef(),
        },
      ]} else { 
      return [
        {
          component: <Attachment />,
          key: 'attachment',
          label: 'Attachment',
          ref: createRef(),
        },
    ]
  }}, [quote]);



  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setActiveItemIndex(item.props.tabIndex!);
    }
  };

  const classes = getClassNames();

  return (
    <div className={classes.lessonDataContainer}>
      <Pivot styles={pivotStyles} onLinkClick={handleLinkClick}>
        {tabs.map((tab, index) => (
          <PivotItem key={tab.key} itemKey={tab.key} headerText={tab.label} tabIndex={index} />
        ))}
      </Pivot>
      <div className={classes.tabContentContainer}>
        <SwitchTransition>
          <CSSTransition
            key={tabs[activeItemIndex]?.key}
            nodeRef={tabs[activeItemIndex]?.ref}
            timeout={ANIMATION_DURATION}
            classNames={{
              enter: classes.contentEnter,
              enterActive: classes.contentEnterActive,
              exit: classes.contentExit,
              exitActive: classes.contentExitActive,
            }}
          >
            <div
              ref={tabs[activeItemIndex]?.ref}
              className={classes.tabComponentContainer}
            >
              {tabs[activeItemIndex]?.component}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default LessonData;