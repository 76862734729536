export enum IconNames {
  CONFIGURE = 'CONFIGURE',
  ANALYTICS = 'ANALYTICS',
  SCHEDULE = 'SCHEDULE',
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
  DELETE = 'DELETE',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  ADD = 'ADD',
  REORDER = 'REORDER',
  ADD_SCHEDULE = 'ADD_SCHEDULE',
  EDIT = 'EDIT',
  FILTER = 'FILTER',
  SEND = 'SEND',
  ADD_LESSON = 'ADD_LESSON',
  FINAL_QUIZ = 'FINAL_QUIZ',
  CLONE = 'CLONE',
  DOWNLOAD = 'DOWNLOAD',
  SCORM = 'SCORM',
  SHAREPOINT = 'SHAREPOINT',
  STOP = 'STOP'
}