import { mergeStyleSets } from '@fluentui/react';

interface IAudioPlayerClassNames{
  audioPlayerContainer: string;
}

export const getClassNames = (): IAudioPlayerClassNames => {
  return mergeStyleSets({
    audioPlayerContainer: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      paddingTop: '1rem',
    },
  });
};