import React from 'react';
import { getClassNames } from './MLHorizontalDivider.classNames';

const MLHorizontalDivider: React.FC = () => {
  const classes = getClassNames();
  
  return (
    <div className={classes.horizontalDivider} />
  );
};

export default MLHorizontalDivider;