import { getClassNames } from "./QuestionList.classNames";
import { useFormContext } from "react-hook-form";
import { createRef, useState } from "react";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";
import { v4 } from "uuid";

interface IQuestionListProsp {
  selectedQuestionIndex: number;
  onChangeQuestion: (index: number) => void;
}

const QuestionList: React.FC<IQuestionListProsp> = ({
  selectedQuestionIndex,
  onChangeQuestion
}) => {
  const { getValues, setValue } = useFormContext();
  const fields = getValues('quizes');
  const [quizes, setQuizes] = 
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; question: string; options: { id: number; response: string; }[]; correctAnswerId: number; }) => ({
        id: field.id,
        value: field.question,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  // useEffect(() => {
  //   const oldQuizes = getValues('quizes');
  //   const newQuizes = [...quizes];

  //   // Create a map of id to full item from the old quizzes
  //   const oldQuizMap = new Map(oldQuizes.map((quiz: any) => [quiz.id, quiz]));

  //   // Create the updated array based on the new quizzes' order
  //   const updatedQuizes = newQuizes.map(newQuiz => {
  //     const oldQuiz = oldQuizMap.get(newQuiz.id);
  //     if (oldQuiz) {
  //       // Update existing quiz
  //       return {
  //         ...oldQuiz,
  //         question: newQuiz.value
  //       };
  //     } else {
  //       // Add new quiz
  //       return {
  //         id: newQuiz.id,
  //         question: newQuiz.value,
  //         options: [],
  //         correctAnswerId: 0
  //       };
  //     }
  //   });

  //   setValue('quizes', updatedQuizes, { shouldDirty: true });
  // }, [quizes, setValue, getValues]);

  // const onDragEnd = () => {
  //       const oldQuizes = getValues('quizes');
  //   const newQuizes = [...quizes];

  //   // Create a map of id to full item from the old quizzes
  //   const oldQuizMap = new Map(oldQuizes.map((quiz: any) => [quiz.id, quiz]));

  //   // Create the updated array based on the new quizzes' order
  //   const updatedQuizes = newQuizes.map(newQuiz => {
  //     const oldQuiz = oldQuizMap.get(newQuiz.id);
  //     if (oldQuiz) {
  //       // Update existing quiz
  //       return {
  //         ...oldQuiz,
  //         question: newQuiz.value
  //       };
  //     } else {
  //       // Add new quiz
  //       return {
  //         id: newQuiz.id,
  //         question: newQuiz.value,
  //         options: [],
  //         correctAnswerId: 0
  //       };
  //     }
  //   });

  //   setValue('quizes', updatedQuizes, { shouldDirty: true });
  //   console.log(quizes);
  //   console.log('onDragEnd');
  // };

  const onDragEnd = () => {
    const fields = getValues('quizes');
    const selectedId = fields[selectedQuestionIndex].id;
    console.log('selection before', selectedId, selectedQuestionIndex);
    const newQuizes = quizes.map(quiz => ({ 
      id: quiz.id,
      question: quiz.value,
      options: [...fields.find((field: any) => field.id === quiz.id)?.options],
      correctAnswerId: fields.find((field: any) => field.id === quiz.id)?.correctAnswerId,
    }));
    setValue(`quizes`, newQuizes, { shouldDirty: true });
    const newSelectedIndex = newQuizes.findIndex((quiz: any) => quiz.id === selectedId);
    onChangeQuestion(newSelectedIndex);
    console.log('selection after', newSelectedIndex);
  };

  const onAdd = (value: string) => {
    const fields = getValues('quizes');
    const newItem = { id: v4(), value, options: [], correctAnswerId: 0 };
    setQuizes([...quizes, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    const newQuizes = quizes.map(quiz => ({ 
      id: quiz.id,
      question: quiz.value,
      options: [...fields.find((field: any) => field.id === quiz.id)?.options],
      correctAnswerId: fields.find((field: any) => field.id === quiz.id)?.correctAnswerId,
    }));
    newQuizes.push({ id: newItem.id, question: newItem.value, options: [], correctAnswerId: 0, });
    setValue(`quizes`, newQuizes, { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...quizes];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setQuizes(newItems);
    const newQuizes = newItems.map(quiz => ({ 
      id: quiz.id,
      question: quiz.value,
      options: [...fields.find((field: any) => field.id === quiz.id)?.options],
      correctAnswerId: fields.find((field: any) => field.id === quiz.id)?.correctAnswerId,
    }));
    setValue(`quizes`, newQuizes, { shouldDirty: true });
  };

  const classes = getClassNames();

  return (
    <div className={classes.questionListContainer}>
      <MLCollection
        items={quizes}
        setItems={setQuizes}
        title=""
        draggable
        selectedIndex={selectedQuestionIndex}
        nonEditable
        onClick={onChangeQuestion}
        onDragEnd={onDragEnd}
        onDelete={onDelete}
      />

      <MLNewItem
        items={quizes}
        setItems={setQuizes}
        onAdd={onAdd}
      />
    </div>
  );
};

export default QuestionList;