import { mergeStyleSets } from "@fluentui/react";

interface IMLCollectionClassNames {
  newItemContainer: string;
  newItem: string;
}

export const getClassNames = (noMargin: boolean, paddingRight: boolean): IMLCollectionClassNames => {
  return mergeStyleSets({
    newItemContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginLeft: noMargin ? 0 : '3rem',
      paddingRight: paddingRight ? '1rem' : 0,
      gap: '1rem',
    },

    newItem: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      gap: 10,
    },
  });
};