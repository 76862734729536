import React, { ReactNode, useRef } from 'react';
import { getClassNames } from './MLModal.classNames';
import { CSSTransition } from 'react-transition-group';
import { Icon } from '@fluentui/react';
import { ANIMATION_DURATION } from 'utils/constants/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ApiStatus } from 'common/models/store/api-status';

interface IMLModalProps {
  show: boolean;
  onDismiss?: () => void;
  title?: string;
  width?: string;
  isTabForm?: boolean;
  children: ReactNode;
}

const MLModal: React.FC<IMLModalProps> = ({ 
  show,
  onDismiss,
  title,
  width,
  isTabForm,
  children 
}) => {
  const settingsApiStatus = useSelector((state: RootState) => state.settings.apiStatus);
  const coursesApiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  
  const backgroundRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const freeze = (settingsApiStatus === ApiStatus.SAVING || coursesApiStatus.status === ApiStatus.SAVING);

  const classes = getClassNames(width, !!isTabForm, freeze);

  return (
    <CSSTransition
      nodeRef={backgroundRef}
      in={show}
      timeout={ANIMATION_DURATION}
      classNames={{
        enter: classes.backgroundEnter,
        enterActive: classes.backgroundEnterActive,
        exit: classes.backgroundExit,
        exitActive: classes.backgroundExitActive,
      }}
      unmountOnExit
    >
      <div ref={backgroundRef} className={classes.background}>
        <CSSTransition
          nodeRef={modalRef}
          in={show}
          timeout={ANIMATION_DURATION}
          classNames={{
            appear: classes.modalEnter,
            appearActive: classes.modalEnterActive,
            exit: classes.modalExit,
            exitActive: classes.modalExitActive,
          }}
          appear
          unmountOnExit
        >
          <div ref={modalRef} className={classes.modal}>
          {title && (
            <div className={classes.header}>
              <h1 className={classes.title}>{title}</h1>
              <Icon className={classes.closeIcon} iconName="ChromeClose" onClick={onDismiss} />
            </div>
          )}
            {children}
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default MLModal;
