import MLHorizontalDivider from "common/ml-components/MLHorizontalDivider";
import { getClassNames } from "./TextLesson.classNames";
import { IShimmerColors, Shimmer } from "@fluentui/react";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

interface ITextLessonProps {
  shimmerColors: IShimmerColors;
}

const TextLesson: React.FC<ITextLessonProps> = ({ shimmerColors }) => {
  const classes = getClassNames();

  return (
    <div className={classes.textLessonContainer}>
      <div className={classes.body}>
        {[...new Array(8)].map((_, index) => (
          <Shimmer
            key={index}
            shimmerColors={shimmerColors}
            styles={{
              root: {
                width: `${(Math.random() * 10) + 90}%`,
                height: '1.5rem',
                borderRadius: 5,
                '.ms-ShimmerLine-root': {
                  height: '1.5rem',
                }
              },
              shimmerWrapper: {
                height: '1.5rem',
                borderRadius: 5,
              },
            }}
          />
        ))}
      </div>
      <div className={classes.audioContainer}>
        <MLHorizontalDivider />
        <AudioPlayer shimmerColors={shimmerColors} />
      </div>
    </div>
  );
};

export default TextLesson;