// import components
import MLTextField from "common/ml-components/inputs/MLTextField";

interface ITextLessonProps {}

const TextLesson: React.FC<ITextLessonProps> = () => {
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        formRequired
        multiline
        autoAdjustHeight
        resizable={false}
      />
      <MLTextField
        name="lesson.body"
        label="Body"
        formRequired
        multiline
        autoAdjustHeight
        resizable={false}
      />
    </>
  );
};

export default TextLesson;
