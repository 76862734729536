import { mergeStyleSets } from '@fluentui/react';

interface IScormLanguagesClassNames {
  scormLanguagesContainer: string
  optionContainer: string;
  optionTitle: string;
  optionTitleError: string;
}

export const getClassNames = (): IScormLanguagesClassNames => {
  return mergeStyleSets({
    scormLanguagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },

    optionContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '2rem'
    },

    optionTitle: {
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
      fontWeight: 400,
    },

    optionTitleError: {
      color: 'red',
    }
  });
};