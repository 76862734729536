import { mergeStyleSets, getTheme } from "@fluentui/react";
import { ANIMATION_DURATION } from "utils/constants/styles";

interface IMLCollectionClassNames {
  collection: string;
  collectionContainer: string;
  collectionTitle: string;

  rowContainer: string;
  itemContainer: string;

  itemEnter: string;
  itemEnterActive: string;
  itemExit: string;
  itemExitActive: string;
}

export const getClassNames = (disabled: boolean): IMLCollectionClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    collection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },

    collectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      overflowY: 'auto',
      scrollbarGutter: 'stable',
      paddingRight: '0.5rem',

      transition: `height ${ANIMATION_DURATION}ms`,
    },

    collectionTitle: {
      fontSize: '1.2rem',
      color: theme.palette.neutralSecondaryAlt,
      fontWeight: 600,
      padding: 0,
    },

    rowContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      ...(disabled && {
        pointerEvents: 'none'
      }),
    },

    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    itemEnter: {
      opacity: 0,
      maxHeight: 0,
      transform: 'scaleX(0)',
    },

    itemEnterActive: {
      opacity: 1,
      maxHeight: 75,
      transform: 'scaleX(1)',
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    itemExit: {
      opacity: 1,
      maxHeight: 75,
      marginBottom: 0,
      transform: 'scaleX(1)',
    },

    itemExitActive: {
      opacity: 0,
      transform: 'scaleX(0)',
      maxHeight: 0,
      marginBottom: '-0.5rem',
      transition: `all ${ANIMATION_DURATION}ms`,
    },
  });
};