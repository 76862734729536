import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS, MODAL_TITLE_FONT_SIZE, MODAL_CLOSE_ICON, ANIMATION_DURATION } from "utils/constants/styles";

interface IModalClassNames {
  background: string;
  modal: string;
  header: string;
  title: string;
  closeIcon: string;

  modalEnter: string;
  modalEnterActive: string;
  modalExit: string;
  modalExitActive: string;

  backgroundEnter: string;
  backgroundEnterActive: string;
  backgroundExit: string;
  backgroundExitActive: string;
}

export const getClassNames = (width: string | undefined, isTabForm: boolean, freeze: boolean): IModalClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    background: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      background: 'rgba(0, 0, 0, 0.6)',
      // backdropFilter: 'blur(5px)',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    modal: {
      position: 'relative',
      width: width || 'auto',
      maxWidth: '80%',
      maxHeight: '85%',
      overflow: 'visible',
      // padding: isTabForm ? '2.5rem 0.5rem 2.5rem 1rem' : '2.5rem 1.5rem 2.5rem 2rem',
      background: theme.palette.white,
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      flexDirection: 'column',
      gap: '4rem',

      padding: isTabForm ? '2rem 0.5rem 2rem 3rem' : '2rem 1.5rem 2rem 3rem',

      backfaceVisibility: 'hidden',
      '-webkit-font-smoothing': 'subpixel-antialiased',

      ...(freeze && {
        '*': {
          pointerEvents: 'none',
        },
      }),
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1.5rem 0 0',
    },

    title: {
      fontSize: MODAL_TITLE_FONT_SIZE,
    },

    closeIcon: {
      cursor: 'pointer',
      fontSize: MODAL_CLOSE_ICON,
      marginTop: 6,
    },

    backgroundEnter: {
      opacity: 0,
    },

    backgroundEnterActive: {
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    backgroundExit: {
      opacity: 1,
    },

    backgroundExitActive: {
      opacity: 0,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    modalEnter: {
      opacity: 0,
      // transform: 'scale(0.9)',
      transform: 'scale(0.9) translate3d( 0, 0, 0) perspective(1px)',
    },

    modalEnterActive: {
      // transform: 'scale(1)',
      transform: 'scale(1) translate3d( 0, 0, 0) perspective(1px)',
      opacity: 1,
      transition: `all ${ANIMATION_DURATION}ms`,
    },

    modalExit: {
      opacity: 1,
      // transform: 'scale(1)',
      transform: 'scale(1) translate3d( 0, 0, 0) perspective(1px)',
    },

    modalExitActive: {
      opacity: 0,
      // transform: 'scale(0.9)',
      transform: 'scale(0.9) translate3d( 0, 0, 0) perspective(1px)',
      transition: `all ${ANIMATION_DURATION}ms`,
    }
  });
}