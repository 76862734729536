import { getClassNames } from "./AudioPlayer.classNames";
import { IShimmerColors, Shimmer } from "@fluentui/react";

interface IAudioPlayerProps {
  shimmerColors: IShimmerColors;
}

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ shimmerColors }) => {
  const classes = getClassNames();

  return (
    <div className={classes.audioPlayerContainer}>
      <Shimmer
        shimmerColors={shimmerColors}
        styles={{
          root: {
            width: `100%`,
            height: '0.5rem',
            borderRadius: 5,
            '.ms-ShimmerLine-root': {
              height: '0.5rem',
            }
          },
          shimmerWrapper: {
            height: '0.5rem',
            borderRadius: 5,
          },
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 2rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {[...new Array(2)].map((_, index) => (
            <Shimmer
              key={index}
              shimmerColors={shimmerColors}
              styles={{
                root: {
                  width: `1.5rem`,
                  height: '1.5rem',
                  borderRadius: 2,
                  '.ms-ShimmerLine-root': {
                    height: '1.5rem',
                  }
                },
                shimmerWrapper: {
                  height: '1.5rem',
                  borderRadius: 2,
                },
              }}
            />
          ))}
        </div>
        <div>
          <Shimmer
            shimmerColors={shimmerColors}
            styles={{
              root: {
                width: `4rem`,
                height: '1.5rem',
                borderRadius: 2,
                '.ms-ShimmerLine-root': {
                  height: '1.5rem',
                }
              },
              shimmerWrapper: {
                height: '1.5rem',
                borderRadius: 2,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;