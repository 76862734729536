import React from 'react';
import { getClassNames } from './ScormAudio.classNames';
import MLCheckbox from 'common/ml-components/inputs/MLCheckbox';

interface IScormAudioProps {}

const ScormAudio: React.FC<IScormAudioProps> = () => {
  const classes = getClassNames();

  return (
    <div className={classes.optionContainer}>
      <MLCheckbox
        name={'includeAudio'}
        label='Include audio'
        boxSide='end'
      />
    </div>
  );
};

export default ScormAudio;
