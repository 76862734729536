import { mergeStyleSets, getTheme } from '@fluentui/react';

interface IShimmersClassNames{
  shimmersCardContainer: string;
  shimmersHeader: string;
  shimmersLesson: string;
  shimmersFooter: string;
}

export const getClassNames = (): IShimmersClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    shimmersCardContainer: {
      overflow: 'hidden',
      width: '100%',
      height: '46rem',
      background: theme.palette.white,
      borderTop: `3px solid ${theme.palette.themePrimary}`,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      cursor: 'move',
    },

    shimmersHeader: {
      position: 'relative',
      padding: '2rem 3rem 2rem 2rem',
      background: theme.palette.themeLighterAlt,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },

    shimmersLesson: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    shimmersFooter: {
      padding: '1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
    }
  });
};